import React from "react";
import WrapperPage from "../../components/WrapperPage";
import { Box, Typography, Card, CardContent } from "@mui/material";
import DashboardStyles from "./DashboardStyles";
import RenderPieChart from "./PieChart";
import { useState, useEffect } from "react";
import axiosFetch from "../../api/Axios";
import SnackBar from "../../components/SnackBar";
import { setItem } from "../../utils/LocalStorageUtils";
import { mapRoleToPermissions } from "../../utils/userUtils";
import { getCurrentUser } from "../../components/listUsers/getUsers";
import queryString from "query-string";
import LoadingComponent from "../../components/LoadingComponent";
import {
  getProjectsAttentionCounts,
  getProjectsRiskLevelsCounts,
} from "./getChartsData";
import { PrimaryGreen, PrimaryYellow, RedAlert } from "../../consts/colors";

function DashboardPage({ sidebarWidth }) {
  const [pieChartData, setPieChartData] = useState([]);
  const [mngAtentionPieChart, setMngAttentionPieChart] = useState([]);
  const [riskLevelPieChart, setRiskLevelPieChart] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (window.location.search) {
        setIsLoading(true);
        const params = queryString.parse(window.location.search);
        const jwtToken = params.token;

        const profile = params.profile
          ? JSON.parse(decodeURIComponent(params.profile))
          : null;

        if (jwtToken && profile) {
          setItem("jwtToken", jwtToken);
          setItem("profile", profile);

          try {
            const response = await getCurrentUser();
            const matchedUserRole = response.user_role;

            const currentUserData = {
              ...profile,
              role: matchedUserRole,
              permissions: mapRoleToPermissions(matchedUserRole),
              id: response.user_id,
            };
            setItem("currentUser", currentUserData);
          } catch (error) {
            setError({
              message: error.message,
              severity: "error",
            });
          } finally {
            setIsLoading(false);
            window.location.href = "/";
          }
        } else {
          setIsLoading(false);
        }
      } else {
        fetchPieChartData();
        getProjectsAttentionCounts()
          .then(async (response) => {
            setMngAttentionPieChart(response);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
        getProjectsRiskLevelsCounts()
          .then(async (response) => {
            setRiskLevelPieChart(response);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const fetchPieChartData = async () => {
    try {
      const response = await axiosFetch({
        method: "GET",
        url:
          process.env.REACT_APP_API_PORT +
          `projects/pie-graph?is_archived=false`,
      });
      if (response && response.length > 0) {
        setPieChartData(response);
      }
    } catch (error) {
      setError({
        message: error.message,
        severity: "error",
      });
    }

    return [];
  };

  const renderTitleCard = (title) => {
    return (
      <Box>
        <Typography sx={DashboardStyles.titleDescription}>{title}</Typography>
      </Box>
    );
  };

  return (
    <WrapperPage title="Dashboard" sidebarWidth={sidebarWidth}>
      <Box sx={DashboardStyles.main}>
        <Box sx={DashboardStyles.leftCard}>
          <Card elevation={3} sx={DashboardStyles.pieChart}>
            <CardContent>
              {renderTitleCard("Overall Status")}
              <Box sx={{ display: "flex" }}>
                <RenderPieChart
                  data={pieChartData}
                  COLORS={{
                    Red: RedAlert,
                    Yellow: PrimaryYellow,
                    Green: PrimaryGreen,
                  }}
                  legendText={{
                    Red: "Fix Now",
                    Yellow: "Warning",
                    Green: "On Track",
                  }}
                />
                <RenderPieChart
                  data={mngAtentionPieChart}
                  COLORS={{
                    Yes: RedAlert,
                    No: PrimaryGreen,
                  }}
                  legendText={{
                    Yes: "Needs Discussion",
                    No: "Doesn't Needs Discussion",
                  }}
                />
                <RenderPieChart
                  data={riskLevelPieChart}
                  COLORS={{
                    RiskLevel0: PrimaryGreen,
                    RiskLevel1: PrimaryYellow,
                    RiskLevel2: RedAlert,
                  }}
                  legendText={{
                    RiskLevel0: "Green",
                    RiskLevel1: "Yellow",
                    RiskLevel2: "Red",
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
      {isLoading && <LoadingComponent />}
      {error?.message && (
        <SnackBar
          open={true}
          message={error.message}
          severity={error.severity}
          handleClose={() => setError(null)}
        />
      )}
    </WrapperPage>
  );
}
export default DashboardPage;
