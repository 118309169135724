import React from 'react';
import { Checkbox } from '@mui/material';
import { Blue800, White100 } from '../../consts/colors';


const RoundCheckbox = ({ checked, onChange, CheckIcon }) => {
  return (
    <Checkbox
      checked={checked}
      onChange={onChange}
      icon={
        <span style={{ borderRadius: '50%', border: `2px solid ${Blue800}`, width: '18px', height: '18px', display: 'inline-block' }}></span>
      }
      checkedIcon={
        <span style={{ borderRadius: '50%', backgroundColor: White100, width: '18px', height: '18px', display: 'inline-block' }}>
          <img src={CheckIcon} alt="Check Icon" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
        </span>
      }
    />
  );
};

export default RoundCheckbox;
