import BackgroundImage from "../../assets/images/background_login.svg";

export const containerStyles={
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100vh',
  flexDirection: 'column',
  backgroundImage: `url(${BackgroundImage})`,
  margin: 0,
  padding: 0,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  }
