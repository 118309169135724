import axiosFetch from "../../api/Axios";

export async function fetchDataFromServer(setLoading) {
  try {
    setLoading(true);

    const response = await axiosFetch({
      method: "GET",
      url: process.env.REACT_APP_API_PORT + "users?limit=100",
    });

    setLoading(false);
    return response;
  } catch (error) {
    setLoading(false);
    console.log("Error: ", error);
    throw error;
  }
}

export const getCurrentUser = async () =>{
  try {
    const response = await axiosFetch({
      method: "GET",
      url: process.env.REACT_APP_API_PORT+"users/me",
    });

    return response;
  } catch (error) {
    console.log("Error: ", error);
    throw error;
  }
}
