import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import { logoSideBarStyle } from "./SideBarStyle";
import logo from "../../assets/logo/logo-vsp-1.png";
import SidebarButton from "./SidebarButtonList";
import {Green600, White100} from "../../consts/colors";
import "./Sidebar.css";
import {IconButton, Tooltip} from "@mui/material";
import ExpandIcon from '@mui/icons-material/Expand';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {SideBarWidth, SideBarWidthCollapsed} from "../../consts/constants";
import {getItem, setItem} from "../../utils/LocalStorageUtils";

export default function SideBar({ isResizing, handleMouseDown, sidebarWidth, setSidebarWidth }) {

    const [isOpen, setIsOpen] = useState( true)

    useEffect(() => {
        const isMenuCollapsed = getItem("menuCollapsed");
        setIsOpen(!isMenuCollapsed);
        if (isMenuCollapsed) {
            setSidebarWidth(SideBarWidthCollapsed);
        }
    }, []);

    useEffect(() => {
        if (!isOpen) {
            setIsOpen(true);
        }
    }, [isResizing])

    return (
        <Box
            sx={{
                display: "flex",
                position: "relative",
            }}
        >
            <CssBaseline />
            <Drawer
                variant="permanent"
                anchor="left"
                sx={{
                    width: !isOpen ? SideBarWidthCollapsed : sidebarWidth,
                    "& .MuiDrawer-paper": {
                        width: !isOpen ? SideBarWidthCollapsed : sidebarWidth,
                        boxSizing: "border-box",
                        backgroundColor: Green600,
                        overflow: "hidden"

                    },
                }}
            >
                {sidebarWidth >= 150 && isOpen ? (
                    <Box
                        sx={{
                            ...logoSideBarStyle.container,
                            "& img": { ...logoSideBarStyle.img },
                        }}
                    >
                        <img src={logo} alt="Logo of the company" />
                    </Box>
                ) : (
                    <Box sx={{ marginTop: "100px" }}></Box>
                )}

                <SidebarButton sidebarWidth={!isOpen ? SideBarWidthCollapsed : sidebarWidth} />
                <div
                    className={`custom-handle custom-handle-right ${
                        isResizing ? "resizing" : ""
                    }`}
                    onMouseDown={handleMouseDown}
                />
                {(sidebarWidth > 150 || !isOpen) &&
                    <IconButton
                        onClick={() => {
                            setIsOpen(!isOpen);
                            const storedWidth = getItem("sidebarWidth");
                            setSidebarWidth(isOpen ? SideBarWidthCollapsed : storedWidth || SideBarWidth);
                            setItem("menuCollapsed", isOpen)
                        }}
                        style={{ position: 'absolute', top: isOpen ? '50vh' : 16, left: isOpen ? sidebarWidth - 32 : 8 }}
                    >
                        {isOpen ?
                            <Tooltip title="Collapse menu">
                                <ExpandLessIcon style={{ color: White100, transform: 'rotate(-90deg)' }} />
                            </Tooltip>
                            :
                            <Tooltip title="Expand menu">
                                <ExpandIcon style={{ color: White100, transform: 'rotate(90deg)' }} />
                            </Tooltip>
                        }
                    </IconButton>
                }
            </Drawer>
        </Box>
    );
}
