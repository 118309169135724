import { White400 } from "../../consts/colors";

export const PromptUserStyles = {
  boxModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "642px",
    bgcolor: White400,
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
  },
  title: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "28.8px",
    letter: "-1.5%",
    marginBottom: "20px",
  },
  description: {
    mt: 2,
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "23.4px",
    letter: "-1%",
    marginBottom: "20px",
  },
};
