import { Edit, History, RemoveRedEye } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Tooltip, Typography, IconButton, Menu, MenuItem, ThemeProvider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArchiveProjectIcon } from '../../assets/icons';
import { getItem, setItem, useLocalStorage } from '../../utils/LocalStorageUtils';
import CustomTable from '../table/Table';
import PromptUser from '../promt/PromptUser';
import moment from 'moment';
import { ProjectTableStyles } from './styles';
import { PrimaryBlue } from '../../consts/colors';

const maxReviewTime = process.env.REACT_APP_REVIEW;

export default function TableProjectsComponent({ projects, isLoading, handleArchiveProject, currentUser }) {
    const [confirmArchive, setConfirmArchive] = useState(false);
    const [deleteProjectId, setDeleteProjectId] = useState();
    const [paginationModel, setPaginationModel] = useLocalStorage('projectsPaginationV2', {
        pageSize: 100,
        page: 0,
    });
    const [anchorEl, setAnchorEl] = useState(null);
    const [activeProject, setActiveProject] = useState(null);
    const navigate = useNavigate();

    const handleMenuOpen = (event, project) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setActiveProject(project);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setActiveProject(null);
    };

    const getActions = () => {
        const actions = [
            {
                label: 'Review project',
                icon: <RemoveRedEye />,
                onClick: () => navigate(`/review-project/${activeProject.project_id}`),
            },
            {
                label: 'View review history',
                icon: <History />,
                onClick: () => navigate(`/review-history/${activeProject.project_id}`),
            },
        ];

        if (currentUser?.role === 'Admin') {
            actions.push(
                {
                    label: 'Edit Project',
                    icon: <Edit />,
                    onClick: () => navigate(`/view-edit/${activeProject.project_id}`),
                },
                {
                    label: 'Archive',
                    icon: <DeleteIcon />,
                    onClick: () => {
                        setDeleteProjectId(activeProject.project_id);
                        setConfirmArchive(true);
                        handleMenuClose();
                    },
                }
            );
        }

        return actions;
    };

    const onRowClick = (project) => {
        navigate(`/see-details-project/${project.project_id}`);
    };

    const headers = [
        {
            label: 'Name',
            key: 'name',
            width: '15%',
            sortable: true,
            renderCell: (project) => (
                <Tooltip title={project.name} arrow>
                    <span>{project.name}</span>
                </Tooltip>
            ),
        },
        {
            label: 'Risk',
            key: 'risk_level',
            width: '5%',
            sortable: true,
            renderCell: (project) => {
                const style =
                    project.risk_level === 0
                        ? ProjectTableStyles.greenDot
                        : project.risk_level === 1
                        ? ProjectTableStyles.yellowDot
                        : ProjectTableStyles.redDot;
                return <Box sx={style} />;
            },
        },
        {
            label: 'Needs discussion',
            key: 'needs_attention',
            width: '15%',
            sortable: true,
            renderCell: (project) => (
                <Box display="flex" alignItems="center">
                    <Box sx={project.needs_attention ? ProjectTableStyles.redDot : ProjectTableStyles.greenDot} />
                    {project.needs_attention ? 'Yes' : 'No'}
                </Box>
            ),
        },
        {
            label: 'Last Review',
            key: 'created_date',
            width: '10%',
            sortable: true,
            renderCell: (project) => {
                if (!project.created_date) {
                    return 'No reviews';
                }
                const time = moment().diff(moment(project.created_date), 'days');
                return (
                    <Box display="flex" alignItems="center">
                        <Box sx={time > maxReviewTime ? ProjectTableStyles.redDot : ProjectTableStyles.greenDot} />
                        {time} days ago
                    </Box>
                );
            },
        },
        {
            label: 'Responsible',
            key: 'id',
            width: '8%',
            renderCell: (project) => (
                <Tooltip
                    title={
                        <table>
                            <tbody>
                                <tr>
                                    <td>MGR:</td>
                                    <td>
                                        {project.ro_first_name} {project.ro_last_name}
                                    </td>
                                </tr>
                                <tr>
                                    <td>CP:</td>
                                    <td>
                                        {project.cp_first_name} {project.cp_last_name}
                                    </td>
                                </tr>
                                <tr>
                                    <td>DoE:</td>
                                    <td>
                                        {project.doe_first_name} {project.doe_last_name}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    }
                    placement="right"
                >
                    <VisibilityIcon
                        sx={{
                            cursor: 'pointer',
                            '&:hover': {
                                color: PrimaryBlue,
                            },
                        }}
                    />
                </Tooltip>
            ),
        },
        {
            label: 'Manager Comment',
            key: 'manager_comment',
            width: '17%',
            renderCell: (project) => (
                <Tooltip title={project.manager_comment} arrow>
                    <span style={ProjectTableStyles.managerComment}>{project.manager_comment}</span>
                </Tooltip>
            ),
        },
        {
            label: 'Client Comment',
            key: 'client_partner_comment',
            width: '15%',
            renderCell: (project) => (
                <Tooltip title={project.client_partner_comment} arrow>
                    <span style={ProjectTableStyles.clientPartnerComment}>{project.client_partner_comment}</span>
                </Tooltip>
            ),
        },
        {
            label: 'Open Actions',
            key: 'has_open_actions',
            width: '10%',
            sortable: true,
            renderCell: (project) => (
                <Box display="flex" alignItems="center">
                    <Box sx={project.has_open_actions ? ProjectTableStyles.redDot : ProjectTableStyles.greenDot} />
                    {project.has_open_actions ? 'Yes' : 'No'}
                </Box>
            ),
        },
        {
            label: 'Actions',
            key: 'actions',
            width: '20%',
            renderCell: (project) => (
                <IconButton onClick={(event) => handleMenuOpen(event, project)}>
                    <MoreVertIcon />
                </IconButton>
            ),
        },
    ];

    return (
        <ThemeProvider theme={ProjectTableStyles}>
            <CustomTable
                headers={headers}
                rows={projects}
                isLoading={isLoading}
                paginationModel={paginationModel}
                onPaginationModelChanged={setPaginationModel}
                isMarginTopRequired
                onRowClick={onRowClick}
                defaultSort={'needs_attention'}
                defaultSortOrder={'desc'}
            />
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                {getActions().map((action, index) => (
                    <MenuItem key={index} onClick={action.onClick}>
                        <Box display="flex" alignItems="center" gap={1}>
                            {action.icon}
                            <Typography>{action.label}</Typography>
                        </Box>
                    </MenuItem>
                ))}
            </Menu>
            {confirmArchive && (
                <PromptUser
                    title="Confirm Archiving the Project"
                    actionName="Archive project"
                    action={handleArchiveProject}
                    actionId={deleteProjectId}
                    setPromptVisible={setConfirmArchive}
                    icon={<ArchiveProjectIcon width="20px" height="20px" />}
                />
            )}
        </ThemeProvider>
    );
}
