import axiosFetch from "../../api/Axios";

export async function fetchQuestionsDataFromServer(setLoading) {
  try {
    setLoading(true); // Set loading to true before making the request

    const response = await axiosFetch({
      method: "GET",
      url: process.env.REACT_APP_API_PORT+'questions?limit=100',
    });

    setLoading(false); // Set loading to false after the request completes
    return response;
  } catch (error) {
    setLoading(false); // Set loading to false on error as well
    console.log("Error: ", error);
    throw error;
  }
}
