import { Blue400, Blue800, Black100 } from "../../consts/colors";
export const NewProjectStyles= {
    text: {
        fontFamily: 'Poppins, sans-serif',
            fontWeight: 600,
            letterSpacing: '-0.005em',
            textAlign: 'left',
            fontSize: '24x',
            lineHeight: '29px',
            textTransform: 'none',
            color:Blue400,
            marginLeft:'20px',
    },
    textTitle:{
        fontFamily: 'Poppins, sans-serif',
            fontWeight: 600,
            letterSpacing: '-0.005em',
            textAlign: 'left',
            textTransform: 'none',
            color:Blue800,
            fontSize: '36x',
            lineHeight: '43px',
    },
    numbers: {
        fontFamily: 'Poppins, sans-serif',
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '23px',
        letterSpacing: '-0.01em',
        textAlign: 'left',
        color: Black100,
      },
};