import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton, TextField } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { Green300, RedAlert } from '../../consts/colors';
import EditableField from '../../pages/SeeProjectDetails/EditableField';
import DeleteActionItemDialog from './DeleteActionItemDialog';

export default function ActionItem({ item, users, updateItem, deleteItem }) {
    const [actionItemText, setActionItemText] = useState(item.description);
    const [assigneeText, setAssigneeText] = useState(item.responsible);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const saveStatus = (status) => updateItem({ ...item, status });
    const saveOwner = (userId) => updateItem({ ...item, user_id: userId });

    const updateAssignee = (ev) => setAssigneeText(ev.target.value);
    const saveAssignee = () => {
        if (item.responsible !== assigneeText) {
            updateItem({ ...item, responsible: assigneeText });
        }
    };
    const updateText = (ev) => setActionItemText(ev.target.value);
    const saveText = () => {
        if (item.description !== actionItemText) {
            updateItem({ ...item, description: actionItemText });
        }
    };

    const confirmDeleteActionItem = () => setShowDeleteDialog(true);

    return (
        <Box display="flex" flexDirection="column" margin={2} padding={1} gap={1} style={{ border: '1px solid #000', borderRadius: 5 }}>
            <Box display="flex" flexDirection="row" gap={2} alignItems="center" justifyContent="space-between">
                <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
                    Status:
                    <EditableField
                        value={item.status}
                        onSave={saveStatus}
                        fieldType="select"
                        options={[
                            { value: 'Open', label: 'Open', color: Green300 },
                            { value: 'Closed', label: 'Closed', color: RedAlert },
                        ]}
                        canEditProject={true}
                        canReviewProject={true}
                        width="120px"
                    />
                </Box>
                <Box display="flex" gap={1} alignItems="center">
                    <span>{moment(item.created_date).format('DD/MM/YYYY')}</span>
                    <IconButton onClick={confirmDeleteActionItem}>
                        <DeleteIcon sx={{ color: RedAlert }} />
                    </IconButton>
                </Box>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" gap={2}>
                <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
                    Owner:{' '}
                    <EditableField
                        value={item.user_id}
                        onSave={saveOwner}
                        fieldType="select"
                        options={users.map((user) => ({
                            value: user.user_id,
                            label: `${user.first_name} ${user.last_name}`,
                            color: 'transparent',
                        }))}
                        canEditProject={true}
                        canReviewProject={true}
                        width="170px"
                    />
                </Box>
                <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
                    Assignee:{' '}
                    <TextField variant="standard" value={assigneeText} onChange={updateAssignee} onBlur={saveAssignee} size="small" />
                </Box>
            </Box>
            <Box>
                <TextField
                    rows={3}
                    fullWidth
                    value={actionItemText}
                    multiline
                    color="success"
                    variant="filled"
                    onChange={updateText}
                    onBlur={saveText}
                    InputProps={{
                        readOnly: false, // !canEditProject && !canReviewProject,
                        style: { padding: 8 },
                    }}
                />
            </Box>
            {showDeleteDialog && (
                <DeleteActionItemDialog
                    actionItemId={item.action_item_id}
                    deleteActionItem={deleteItem}
                    close={() => setShowDeleteDialog(false)}
                />
            )}
        </Box>
    );
}
