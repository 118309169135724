import { Black100, Blue400, White100 } from "../../consts/colors";


export const ListProjectComponentStyle = {
    list: {
      margin: '1%',
      marginTop: '3%',
      marginRight: '2%',
      display: 'flex',
      borderRadius: '20px',
      fontFamily: 'Poppins',
      position: 'relative', 
      overflow: 'hidden', 
      border: '2px solid red',
      borderTop: '6px solid red',
      float: 'left',
      width: '30%',
      padding: '5px',
      flexDirection: 'column',
      backgroundColor: White100,
    },
    box:{
        display: 'flex', 
        alignItems: 'center', 
        padding: '10px',
        marginLeft: '1%',
    },
    typographyNameColumn:{
        fontFamily: 'Poppins',
        fontWeight: 600,
        fontSize: '24px',
        color: Blue400,
        marginLeft: '3%',
        lineHeight: '28.8px',
        letter: '-1.5%',
    },
    typographyNumberProjects:{
        fontFamily: 'Poppins',
        fontWeight: 700,
        fontSize: '12.5px',
        lineHeight: '15.6px',
        color: Black100,
        alignItems: 'center',
  },
  circleStyle:{
    marginLeft: '3%',
      width: "25px",
      height: "25px",
      borderRadius: "50%",
      backgroundColor: "transparent",
      border: '2px solid ',
      borderColor: Black100,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "24px",
      fontWeight: "bold",
  },
  
}