import React from 'react';
import { Autocomplete, TextField, Box } from '@mui/material';
import { autocompleteFilterStyles } from './styles';
import { ThemeProvider } from '@mui/system';

const AutocompleteFilter = ({ options, value, label, onChange }) => {
    return (
        <ThemeProvider theme={autocompleteFilterStyles}>
            <Box sx={autocompleteFilterStyles.autocomplete}>
                <Autocomplete
                    size="small"
                    value={value}
                    options={options}
                    onChange={onChange}
                    renderInput={(params) => <TextField {...params} label={label} />}
                    sx={{ width: '100%' }}
                />
            </Box>
        </ThemeProvider>
    );
};

export default AutocompleteFilter;
