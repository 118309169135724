import React, { useState, useEffect } from 'react';
import WrapperPage from '../../components/WrapperPage';
import { useParams } from 'react-router-dom';
import axiosFetch from '../../api/Axios';
import { NewProjectStyles } from '../NewProject/styles';
import { Link } from 'react-router-dom';
import SnackBar from '../../components/SnackBar';
import styles from '../ProjectReview/styles';
import { ProjectCardStyle } from '../../components/projectsCard/ProjectCardStyles';
import { Typography, Avatar } from '@mui/material';
import { IconButton } from '@mui/material';
import { ListUserStyles } from '../../components/listUsers/styles';
import ViewReviewIcon from '../../assets/icons/ViewReviewIcon.svg';
import { formatDate } from '../../utils/dateUtils';
import CustomTable from '../../components/table/Table';
import { renderStatusColumn } from '../../utils/TableUtils';

const renderReviewOwnerColumn = (user_id, reviewOwnerIDs) => {
    const owners = reviewOwnerIDs[user_id];

    if (owners && owners.length > 0) {
        return owners.map((user) => (
            <div key={user.user_id}>
                {user.first_name} {user.last_name}
            </div>
        ));
    } else {
        return 'N/A';
    }
};

const renderActionsColumn = (review_id) => (
    <Link to={`/view-review/${review_id}`} style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}>
        <IconButton>
            <img src={ViewReviewIcon} alt="Edit" height={16} width={16} />
            <span style={{ ...ListUserStyles.bodyText, marginLeft: '8px' }}>View Review</span>
        </IconButton>
    </Link>
);

const ReviewHistoryPage = ({ sidebarWidth }) => {
    const { itemId } = useParams();
    const [projectTitle, setProjectTitle] = useState('');
    const [reviewOwnerIDs, setReviewOwnerIDs] = useState([]);
    const [reviewOwner, setReviewOwner] = useState([]);
    const [lastReviewDate, setLastReviewDate] = useState(null);
    const [error, setError] = useState(null);
    const [status, setStatus] = useState([]);
    const [data, setData] = useState([]);

    // Pagination state
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });

    const headers = [
        { label: 'Review Number', key: 'review_id', width: '20%' },
        { label: 'Date completed', key: 'created_date', width: '20%' },
        { label: 'Project Status', key: 'status', width: '20%' },
        { label: 'Manager', key: 'user_id', width: '20%' },
        { label: 'Actions', key: 'actions', width: '20%' },
    ];

    const rows = data.map((data) => ({
        review_id: data.review_id,
        created_date: formatDate(data.created_date),
        status: renderStatusColumn(data.status),
        user_id: renderReviewOwnerColumn(data.user_id, reviewOwnerIDs),
        actions: renderActionsColumn(data.review_id),
    }));

    useEffect(() => {
        async function fetchReviewsData() {
            try {
                const response = await axiosFetch({
                    method: 'GET',
                    url: process.env.REACT_APP_API_PORT + 'project/' + itemId + '/reviews',
                });
                if (response && Object.keys(response).length > 0) {
                    setData(response);
                    setStatus(response[0].status || '');
                    setLastReviewDate(formatDate(response[0].created_date) || null);
                } else {
                    console.log('No project data found.');
                }
            } catch (error) {
                setError({
                    message: error.message,
                    severity: 'error',
                });
            }
        }

        fetchReviewsData();
    }, [itemId]);

    useEffect(() => {
        async function fetchTitle() {
            try {
                const response = await axiosFetch({
                    method: 'GET',
                    url: process.env.REACT_APP_API_PORT + 'project/' + itemId,
                });

                if (response && Object.keys(response).length > 0) {
                    setProjectTitle(response.name || '');
                    setReviewOwner(response.user_id || '');
                } else {
                    console.log('No project data found for title and manager.');
                }
            } catch (error) {
                console.log('Error: ', error);
            }
        }

        fetchTitle();
    }, [itemId]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const usersResponse = await axiosFetch({
                    method: 'GET',
                    url: process.env.REACT_APP_API_PORT + 'users?limit=100',
                });

                if (usersResponse && Array.isArray(usersResponse)) {
                    const usersByUserId = usersResponse.reduce((acc, user) => {
                        const { user_id } = user;

                        if (!acc[user_id]) {
                            acc[user_id] = [];
                        }

                        acc[user_id].push(user);
                        return acc;
                    }, {});

                    setReviewOwnerIDs(usersByUserId);
                } else {
                    console.error('No users data found or invalid format.');
                }
            } catch (error) {
                setError({
                    message: error.message,
                    severity: 'error',
                });
            }
        };

        fetchData();
    }, []);

    const handlePaginationModelChanged = (newPaginationModel) => {
        setPaginationModel(newPaginationModel);
    };

    return (
        <WrapperPage sidebarWidth={sidebarWidth}>
            <React.Fragment>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '36px' }}>
                    <Avatar variant="square" sx={ProjectCardStyle.avatarProject}>
                        <Typography variant="subtitle1" color="inherit" sx={ProjectCardStyle.typographyPr}>
                            {projectTitle
                                ?.split(' ')
                                .map(function (str) {
                                    return str ? str[0].toUpperCase() : '';
                                })
                                .slice(0, 2)
                                .join('')}
                        </Typography>
                    </Avatar>
                    <h1
                        style={{
                            ...NewProjectStyles.textTitle,
                            margin: 0,
                            marginLeft: '10px',
                        }}
                    >
                        {' '}
                        {projectTitle} Review
                    </h1>
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <h1 style={{ ...styles.header, marginRight: '10px' }}>
                        {reviewOwnerIDs[reviewOwner] && reviewOwnerIDs[reviewOwner].length > 0
                            ? reviewOwnerIDs[reviewOwner].map((user) => (
                                  <span key={user.user_id}>
                                      {user.first_name} {user.last_name}
                                  </span>
                              ))
                            : 'N/A'}{' '}
                    </h1>
                    <h1 style={{ ...styles.header, marginRight: '10px' }}>|</h1>
                    <h1 style={{ ...styles.header, marginRight: '10px' }}>Last Review: Date: {lastReviewDate}</h1>
                    <h1 style={{ ...styles.header }}>|</h1>
                    <h1 style={{ ...styles.header, marginLeft: '10px' }}>{renderStatusColumn(status)}</h1>
                </div>

                <CustomTable
                    headers={headers}
                    rows={rows}
                    paginationModel={paginationModel}
                    onPaginationModelChanged={handlePaginationModelChanged}
                    isMarginTopRequired={true}
                    goToReviewPageNeeded={false}
                />
                {error?.message && (
                    <SnackBar open={true} message={error.message} severity={error.severity} handleClose={() => setError(null)} />
                )}
            </React.Fragment>
        </WrapperPage>
    );
};
export default ReviewHistoryPage;
