import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  Typography,
  Tooltip,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { ProjectCardStyle } from "../../components/projectsCard/ProjectCardStyles";
import { FormsStyles } from "../../utils/FormsStyles";
import { EditProjectIcon } from "../../assets/icons";
import "./style.css";

const EditableField = ({
  value,
  onSave,
  maxLength,
  fieldType,
  options,
  fieldIdentifier,
  canEditProject,
  canReviewProject,
    width = "150%"
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [fieldValue, setFieldValue] = useState(value);
  const containerRef = useRef(null);

  useEffect(() => {
    setFieldValue(value);
  }, [value]);

  const handleClickOutside = React.useCallback((event) => {
    if (
        containerRef.current &&
        !containerRef.current.contains(event.target) &&
        !event.target.closest(".MuiMenu-root")
    ) {
      setIsEditMode(false);
    }
  }, [])

  useEffect(() => {
    if(isEditMode) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
      if (fieldValue !== value) {
        console.log('save ', fieldValue, value)
        onSave(fieldValue, fieldIdentifier);
      }
    }
  }, [isEditMode]);

  const handleEditClick = () => {
    setIsEditMode(true);
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    setFieldValue(newValue);
    if (fieldType === "select") {
      setIsEditMode(false)
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      setIsEditMode(false);
    }
  };

  const color = options?.find((o) => o.value === fieldValue)?.color ?? "none";

  const currentOption = options?.find((o) => o.value === fieldValue)
  const label  = (currentOption ? currentOption.label : `${fieldValue}`) ?? ""

  return (
    <div ref={containerRef}>
      {!isEditMode || !canEditProject || !canReviewProject ? (
        fieldType === "select" ? (
          <Typography
            onClick={handleEditClick}
            sx={{
              ...ProjectCardStyle.typographyDescription,
              cursor: "pointer",
              marginTop: "3px",
              height: "25px",
            }}
          >
            <div style={{ display: "flex" }}>
              <div
                style={{
                  width: "12px",
                  height: "12px",
                  borderRadius: "50%",
                  backgroundColor: color,
                  marginRight: "5px",
                  marginTop: "3px",
                }}
              ></div>
              {label.slice(0, 1).toUpperCase()}{label.slice(1)}
            </div>
          </Typography>
        ) : (
          <Tooltip
            title={
              <>
                <Button
                  sx={{ width: "20px" }}
                  onClick={() => {
                    handleEditClick();
                  }}
                  disableRipple
                >
                  <EditProjectIcon
                    width={"15px"}
                    height={"15px"}
                    color="white"
                  />
                </Button>{" "}
                {value}
              </>
            }
            arrow
          >
            <Typography
              sx={{
                ...ProjectCardStyle.typographyDescription,
                height: "20px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                cursor: "pointer",
                marginTop: "3px",
              }}
              onClick={handleEditClick}
            >
              {value?.length > maxLength
                ? `${value.slice(0, maxLength)}...`
                : value}
            </Typography>
          </Tooltip>
        )
      ) : fieldType === "select" ? (
        <Select
          value={currentOption.value}
          onChange={handleChange}
          className="customFieldsContainer"
          sx={{
            width,
            ...FormsStyles,
          }}
        >
          {options
            .map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                style={{ fontFamily: "poppins", fontSize: "12px" }}
                selected={option.value === fieldValue}
              >
                {option.label}
              </MenuItem>
            ))}
        </Select>
      ) : (
        <TextField
          value={fieldValue}
          onChange={handleChange}
          //onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          className="customFieldsContainer"
          sx={{
            ...FormsStyles,
            width,
            fontFamily: "poppins",
            fontSize: "12px",
          }}
        />
      )}
    </div>
  );
};

export default EditableField;
