import * as React from 'react';
import WrapperPage from '../../components/WrapperPage';
import GreenButton from '../../components/GreenButton';
import { useNavigate } from 'react-router-dom';
import ListQuestions from '../../components/listQuestions/ListQuestions';
import SearchBar from '../../components/SearchBar';
import FilterButton from '../../components/filter/FilterButton';
import { QuestionsIcon, Plus } from '../../assets/icons';
import FilterIcon from '../../assets/icons/filter.svg';
import { useState, useEffect, useRef } from 'react';
import { fetchQuestionsDataFromServer } from '../../components/listQuestions/getQuestions';
import { Green600, PrimaryGreen } from '../../consts/colors';

function QuestionsPage({ sidebarWidth }) {
    const nav = useNavigate();
    const [sortType, setSortType] = useState('');
    const [data, setData] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const [loading, setLoading] = useState(true); // Initialize loading state
    const [searchValue, setSearchValue] = useState('');
    const [debouncedSearchValue, setDebouncedSearchValue] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [filteredData, setFilteredData] = useState(data);

    const handleFilter = (value) => {
        setSortType(value);
    };
    useEffect(() => {
        fetchQuestionsDataFromServer(setLoading)
            .then((response) => {
                console.log('response', response);
                setData(response);
                setOriginalData(response);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    const handleSearchChange = (value) => {
        setSearchValue(value);
    };

    const timer = useRef(null);

    useEffect(() => {
        if (searchValue?.length >= 2) {
            clearTimeout(timer.current);
            timer.current = setTimeout(() => {
                setDebouncedSearchValue(searchValue);
            }, 500);
        } else {
            clearTimeout(timer.current);
            setDebouncedSearchValue('');
            setSearchResults([]);
        }
    }, [searchValue]);

    useEffect(() => {
        const filtered = data.filter((item) => {
            if (!debouncedSearchValue) {
                return true;
            } else {
                const searchableFields = ['value_question'];
                const containsSearchValue = searchableFields.some((field) => {
                    const fieldValue = item[field] || '';
                    return fieldValue.toLowerCase().includes(debouncedSearchValue.toLowerCase());
                });
                return containsSearchValue;
            }
        });

        setFilteredData(filtered);
    }, [data, debouncedSearchValue]);

    return (
        <WrapperPage title="Manage Questions" icon={<QuestionsIcon height={'30px'} width={'30px'} />} sidebarWidth={sidebarWidth}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: '36px',
                }}
            >
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '16px' }}>
                    <SearchBar onSearchChange={handleSearchChange} placeholder={'Search questions...'} />
                    {/* <div style={{ marginLeft: "25px" }}>
            <FilterButton
              title="Filter Questions"
              icon={FilterIcon}
              sortType={handleFilter}
              reviewOption={false}
            />
          </div> */}
                </div>
                <GreenButton
                    iconSrc={<Plus width={'20px'} height={'20px'} />}
                    buttonText="Create New Questions"
                    width="212px"
                    height="44px"
                    fontSize={14}
                    lineHeight={18}
                    backgroundColor={Green600}
                    backgroundColorHover={PrimaryGreen}
                    onClick={() => {
                        nav('/add-questions');
                    }}
                />
            </div>
            <ListQuestions data={filteredData} setData={setData} sortType={sortType} originalData={originalData}></ListQuestions>
        </WrapperPage>
    );
}

export default QuestionsPage;
