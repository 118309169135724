
export const logoSideBarStyle = {
  container: {
    padding: 2,
    marginLeft: '6%',
    marginTop: '10%'
  },
  img: {
    width: 180,
    height: 40,
    maxWidth: '100%',
  },
};
