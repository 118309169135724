import {Green300, RedAlert} from "../../consts/colors";
import moment from "moment";
import {Box} from "@mui/material";
import React from "react";

const RED_DOT = <div
    style={{
        width: "12px",
        height: "12px",
        borderRadius: "50%",
        backgroundColor: RedAlert,
        marginRight: "5px",
    }}
/>

const GREEN_DOT = <div
    style={{
        width: "12px",
        height: "12px",
        borderRadius: "50%",
        backgroundColor: Green300,
        marginRight: "5px",
    }}
/>

export const columns = [
    {
        headerName: 'Project',
        field: 'project_name',
        width: 200,
        sorting: true,
        headerClassName: 'tableHeader'
    },
    {
        headerName: 'Status',
        field: 'status',
        width: 100,
        sorting: true,
        headerClassName: 'tableHeader',
        renderCell: (params) => {
            return params.row.status === "Open" ?
                <>{GREEN_DOT} Open</>
                :
                <>{RED_DOT} Closed</>
        }
    },
    {
        headerName: 'Date',
        field: 'date',
        width: 150,
        sorting: true,
        headerClassName: 'tableHeader',
        renderCell: (params) => {
            return moment(params.row.date).format("DD/MM/YYYY")
        }
    },
    {
        headerName: 'Owner',
        field: 'owner',
        width: 200,
        sorting: true,
        headerClassName: 'tableHeader'
    },
    {
        headerName: 'Assignee',
        field: 'assignee',
        width: 200,
        sorting: true,
        headerClassName: 'tableHeader'
    },
    {
        headerName: 'Description',
        field: 'description',
        width: 300,
        sorting: true,
        headerClassName: 'tableHeader'
    },
]

export const emptyFilters = {
    searchValue: '',
    project: undefined,
    owner: undefined,
    status: undefined,
}

export const NoRowsText = <Box style={{ margin: "20px auto", width: 100 }}>No action items</Box>
