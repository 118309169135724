import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Menu from "@mui/material/Menu"; // Import Menu directly, no need for MenuProps
import MenuItem from "@mui/material/MenuItem";
import arrowDown from "../assets/icons/arrowDown.svg";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import axiosFetch from "../api/Axios";
import { Black400 } from "../consts/colors";
import SnackBar from "./SnackBar";

const StyledButton = styled(ButtonBase)(({  }) => ({
  justifyContent: "space-between",
  width: "100px",
  height: "40px",
  backgroundColor: "transparent",
}));

export default function UserRoleDropdown({ user, onRoleChange }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [success, setSuccess] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSuccessSnackbarClose = () => {
    setSuccess(false);
  };

  const handleMenuItemClick = async (newValue) => {
    try {
      await axiosFetch({
        method: "PUT",
        url: process.env.REACT_APP_API_PORT + `user/${user.user_id}`,
        data: { user_role: newValue },
      });
      setSuccess(true);
      onRoleChange(user.user_id, newValue); 
    } catch (error) {
      console.error("Error updating:", error);
    }
    handleClose();
  };

  return (
    <>
      <div>
        <StyledButton
          aria-controls={open ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          disableRipple
          onClick={handleClick}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="body1"
              fontFamily="Poppins"
              fontSize={14}
              fontWeight={400}
              lineHeight={1.5}
              letterSpacing="-0.005em"
              textAlign="left"
              color={Black400}
            >
              {user.user_role}
            </Typography>
            <img
              src={arrowDown}
              alt="Arrow Down"
              style={{ marginLeft: "7px" }}
              width={10}
              height={10}
            />
          </div>
        </StyledButton>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MenuItem
            onClick={() => handleMenuItemClick("Manager")}
            disableRipple
            value="Manager"
          >
            Manager
          </MenuItem>
          <MenuItem
            onClick={() => handleMenuItemClick("Admin")}
            disableRipple
            value="Admin"
          >
            Admin
          </MenuItem>
        </Menu>
      </div>
      {success && (
        <SnackBar
          open={true}
          message="User's role changed successfully!"
          severity="success"
          handleClose={handleSuccessSnackbarClose}
        />
      )}
    </>
  );
}
