import {differenceInDays, parse} from "date-fns";
import {EDIT_PERMISSION, REVIEW_PERMISSION, ReviewTimePeriod} from "../../consts/constants";

const filterProjectBySortType = (project, type, currentUser) => {
    switch (type) {
        case "My Projects":
            return project.user_id === currentUser.id || project.doe_id === currentUser.id || project.cp_id === currentUser.id;
        case "Needs Attention":
            return project.needs_attention;
        case "Risk Level G":
            return project.risk_level === 0;
        case "Risk Level Y":
            return project.risk_level === 1;
        case "Risk Level R":
            return project.risk_level === 2;
        case "Last review > 14d":
            let lastDate = project.created_date;
            if (lastDate !== null) {
                const today = new Date();
                const daysDifference = differenceInDays(
                    today,
                    parse(lastDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", new Date())
                );
                return daysDifference >= ReviewTimePeriod
            }
            return false;
        default:
            return true;
    }
}

export const filterProjects = ( projects, searchValue, sortType, currentUser) =>
{
    let filteredProjects = searchValue !== "" ?
        projects.filter((p) => p.name.toLowerCase().includes(searchValue.toLowerCase())) : projects;
    filteredProjects = sortType !== "" ? filteredProjects.filter((p) => filterProjectBySortType(p, sortType, currentUser)) : filteredProjects;
    return filteredProjects;
}

export const createCSVFile = (projects) => {
    const preparedCSVContent = projects.map(project => [project.project_id, project.name, project.gross_margin])
    const csvContent = "data:text/csv;charset=utf-8,Project Id, Project Name, Gross Margin\n" + preparedCSVContent.map(row => row.join(",")).join("\n");
    return csvContent;
}

export const getUserPermissions = (currentUser) => {
    const canReviewProject =
        currentUser?.permissions?.includes(REVIEW_PERMISSION);
    const canEditProject = currentUser?.permissions?.includes(EDIT_PERMISSION);
    return {
        canReviewProject, canEditProject
    }
}
