import React, { useEffect, useRef, useState } from "react";
import WrapperPage from "../../components/WrapperPage";
import { fetchProjectsData } from "../../components/listProjectCards/getProjects";
import { ArchiveProjectIcon } from "../../assets/icons";
import ArchivedProjectsList from "../../components/ArchivedProjectsList";
import SearchBar from "../../components/SearchBar";
import LoadingComponent from "../../components/LoadingComponent";
import axiosFetch from "../../api/Axios";

function ArchivedProjectsPage({ sidebarWidth }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [debouncedSearchValue, setDebouncedSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [filteredData, setFilteredData] = useState(data);
  useEffect(() => {
    async function fetchProjectsData() {
      try {
        setLoading(true);
        const response = await axiosFetch({
          method: "GET",
          url:
            process.env.REACT_APP_API_PORT +
            "projects?limit=all&is_archived=true",
        });

        if (response && Object.keys(response).length > 0) {
          setData(response);
        } else {
          console.log("No project data found.");
        }
      } catch (error) {
        console.log("Error: ", error);
      } finally {
        setLoading(false);
      }
    }

    fetchProjectsData();
  }, []);

  const handleSearchChange = (value) => {
    setSearchValue(value);
  };
  const timer = useRef(null);

  useEffect(() => {
    if (searchValue?.length >= 2) {
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        setDebouncedSearchValue(searchValue);
      }, 500);
    } else {
      clearTimeout(timer.current);
      setDebouncedSearchValue("");
      setSearchResults([]);
    }
  }, [searchValue]);

  useEffect(() => {
    const filtered = data.filter((item) => {
      if (!debouncedSearchValue) {
        return true;
      } else {
        const searchableFields = ["name"];
        const containsSearchValue = searchableFields.some((field) => {
          const fieldValue = item[field] || "";
          return fieldValue
            .toLowerCase()
            .includes(debouncedSearchValue.toLowerCase());
        });
        return containsSearchValue;
      }
    });

    setFilteredData(filtered);
  }, [data, debouncedSearchValue]);

  return (
    <WrapperPage
      icon={<ArchiveProjectIcon height={"30px"} width={"30px"} />}
      title="Archived Projects"
      sidebarWidth={sidebarWidth}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "36px",
        }}
      >
        <SearchBar onSearchChange={handleSearchChange} />
      </div>
      {loading ? (
        <LoadingComponent />
      ) : (
        <ArchivedProjectsList data={filteredData} setData={setData} />
      )}
    </WrapperPage>
  );
}

export default ArchivedProjectsPage;
