
import { Blue400, Black400, White100 } from '../../consts/colors';


export const  ListQuestionStyles = {
    question: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '18.2px',
      letterSpacing: '-0.5%',
      color: Blue400, 
    },
    questionRow:{
      maxWidth: '98%',
      backgroundColor: White100,
      boxShadow: '5px 8px 10px rgba(0, 0, 0, 0.05)',
      borderRadius: '10px 0 0 10px',
    },
    data: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '18.2px',
      letterSpacing: '-5%',
      color: Black400,
    },
    active: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '18.2px',
      letterSpacing: '-0.5%',
      color: Black400,
    },
    buttonEdit: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '18.2px',
      letterSpacing: '-0.5%',
      textTransform: 'capitalize',
       color: Black400,
      "&:hover": {backgroundColor: "transparent", }
    }
  }