import { createTheme } from '@mui/material/styles';
import { Black400 } from '../../consts/colors';

export const ProjectDetailsStyles = createTheme({
    bodyText: {
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '18px',
        letterSpacing: '-0.005em',
        textAlign: 'left',
        color: Black400,
    },
});
