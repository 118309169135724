import { MenuItem, Stack, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import axiosFetch from '../../api/Axios';
import { Plus } from '../../assets/icons';
import CustomDatePicker from '../../components/CustomDatePicker';
import GreenButton from '../../components/GreenButton';
import LoadingComponent from '../../components/LoadingComponent';
import SnackBar from '../../components/SnackBar';
import WrapperPage from '../../components/WrapperPage';
import { Green600, PrimaryGreen } from '../../consts/colors';
import { FormsStyles } from '../../utils/FormsStyles';
import { NewProjectStyles } from './styles';

const NewProjectPage = ({ sidebarWidth }) => {
    const [projectTitle, setProjectTitle] = useState('');
    const [projectDescription, setProjectDescription] = useState('');
    const [clientName, setClientName] = useState('');
    const [reviewOwner, setReviewOwner] = useState('');
    const [clientPartner, setClientPartner] = useState('');
    const [doE, setDoE] = useState('');
    const [category, setCategory] = useState('1');
    const [isFormComplete, setIsFormComplete] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [options, setOptions] = useState([]);
    const [clientPOptions, setclientPOptions] = useState([]);
    const [doEOptions, setdoeOptions] = useState([]);
    const [isLoadingManagerData, setIsLoadingManagerData] = useState(false);
    const [slackChannel, setSlackChannel] = useState('');

    useEffect(() => {
        setIsFormComplete(
            projectTitle !== '' &&
                projectDescription !== '' &&
                clientName !== '' &&
                startDate !== '' &&
                reviewOwner !== '' &&
                clientPartner !== '' &&
                doE !== '' &&
                category.length !== ''
        );
    }, [projectTitle, projectDescription, clientName, startDate, reviewOwner, category, clientPartner, doE]);

    useEffect(() => {
        async function fetchManagerData() {
            try {
                setIsLoadingManagerData(true);
                const response = await axiosFetch({
                    method: 'GET',
                    url: process.env.REACT_APP_API_PORT + 'users?user_role=Admin&&user_role=Manager&&limit=all',
                });
                setOptions(response);
                const response1 = await axiosFetch({
                    method: 'GET',
                    url: process.env.REACT_APP_API_PORT + 'users/cp',
                });
                setclientPOptions(response1);
                const response2 = await axiosFetch({
                    method: 'GET',
                    url: process.env.REACT_APP_API_PORT + 'users/doe',
                });
                setdoeOptions(response2);
            } catch (error) {
                throw error;
            } finally {
                setIsLoadingManagerData(false);
            }
        }
        fetchManagerData();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const dataToSend = {
            name: projectTitle,
            user_id: reviewOwner,
            cp_id: clientPartner,
            doe_id: doE,
            category: category,
            business_description: projectDescription,
            client_name: clientName,
            start_date: dayjs(startDate).format('YYYY-MM-DD'),
            end_date: endDate ? dayjs(endDate).format('YYYY-MM-DD') : null,
            slack_channel: slackChannel,
        };

        const options = {
            url: process.env.REACT_APP_API_PORT + 'project',
            method: 'POST',
            data: dataToSend,
        };

        try {
            const responseData = await axiosFetch(options);
            if (responseData) {
                setSuccess(true);
            } else setSuccess(false);
        } catch (error) {
            setError({
                message: error.message,
                severity: 'error',
            });
        }
    };

    const handleProjectTitleChange = (event) => {
        setProjectTitle(event.target.value);
    };

    const handleSlackChannelChnage = (event) => {
        setSlackChannel(event.target.value);
    };

    const handleProjectDescriptionChange = (event) => {
        setProjectDescription(event.target.value);
    };

    const handleClientNameChange = (event) => {
        setClientName(event.target.value);
    };

    const handleStartDateChange = (newDate) => {
        setStartDate(newDate);
        setError(null);
    };

    const handleEndDateChange = (newDate) => {
        if (newDate) {
            if (newDate > startDate) {
                setEndDate(newDate);
                setError(null);
            } else {
                setError({
                    message: 'End date should be after the start date!',
                    severity: 'error',
                });
                setSuccess(false);
            }
        } else {
            setError(null);
        }
    };

    const handleReviewOwnerChange = (event) => {
        const selectedUserId = parseInt(event.target.value, 10);
        setReviewOwner(selectedUserId);
    };
    const handleCPChange = (event) => {
        const selectedUserId = parseInt(event.target.value, 10);
        setClientPartner(selectedUserId);
    };
    const handleDoEChange = (event) => {
        const selectedUserId = parseInt(event.target.value, 10);
        setDoE(selectedUserId);
    };

    const handleSuccessSnackbarClose = () => {
        setSuccess(false);
        window.location.reload();
    };

    return (
        <WrapperPage sidebarWidth={sidebarWidth}>
            {isLoadingManagerData ? (
                <LoadingComponent />
            ) : (
                <React.Fragment>
                    <h1 style={{ ...NewProjectStyles.textTitle }}>Create new project</h1>
                    <form onSubmit={handleSubmit}>
                        <div style={{ marginBottom: '20px' }}>
                            <span style={{ ...NewProjectStyles.numbers }}>1</span>
                            <span style={{ ...NewProjectStyles.text }}>Project Title</span>
                        </div>
                        <TextField
                            type="text"
                            variant="outlined"
                            color="secondary"
                            label="Type project title"
                            onChange={handleProjectTitleChange}
                            value={projectTitle}
                            fullWidth
                            required
                            sx={{ mb: 4, marginLeft: '20px', ...FormsStyles.field }}
                        />

                        <div style={{ marginBottom: '20px' }}>
                            <span style={{ ...NewProjectStyles.numbers }}>2</span>
                            <span style={{ ...NewProjectStyles.text }}>Project Description</span>
                        </div>
                        <TextField
                            type="text"
                            variant="outlined"
                            color="secondary"
                            label="Type project description"
                            onChange={handleProjectDescriptionChange}
                            value={projectDescription}
                            fullWidth
                            required
                            sx={{ mb: 4, marginLeft: '20px', ...FormsStyles.field }}
                        />

                        <div style={{ marginBottom: '20px' }}>
                            <span style={{ ...NewProjectStyles.numbers }}>3</span>
                            <span style={{ ...NewProjectStyles.text }}>Client Name</span>
                        </div>
                        <TextField
                            type="text"
                            variant="outlined"
                            color="secondary"
                            label="Type client name"
                            onChange={handleClientNameChange}
                            value={clientName}
                            fullWidth
                            required
                            sx={{ mb: 4, marginLeft: '20px', ...FormsStyles.field }}
                        />

                        <div style={{ marginBottom: '20px' }}>
                            <span style={{ ...NewProjectStyles.numbers }}>4</span>
                            <span style={{ ...NewProjectStyles.text }}>Start-End Date</span>
                        </div>
                        <Stack spacing={2} direction="row" sx={{ marginBottom: 4, marginLeft: '20px' }}>
                            <CustomDatePicker label="Start Date" value={startDate} onChange={handleStartDateChange} />

                            <CustomDatePicker label="End Date" value={endDate} onChange={handleEndDateChange} />
                        </Stack>

                        <div style={{ marginBottom: '20px' }}>
                            <span style={{ ...NewProjectStyles.numbers }}>5</span>
                            <span style={{ ...NewProjectStyles.text }}>Manager</span>
                        </div>
                        <FormControl fullWidth sx={{ mb: 4, marginLeft: '20px', ...FormsStyles.field }}>
                            <InputLabel id="review-owner-label">Pick Manager</InputLabel>
                            <Select
                                labelId="review-owner-label"
                                id="review-owner-select"
                                value={reviewOwner}
                                onChange={handleReviewOwnerChange}
                                label="Pick Manager"
                            >
                                {options.map((option) => (
                                    <MenuItem key={option.user_id} value={option.user_id}>
                                        {option.first_name} {option.last_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <div style={{ marginBottom: '20px' }}>
                            <span style={{ ...NewProjectStyles.numbers }}>6</span>
                            <span style={{ ...NewProjectStyles.text }}>Client Partner</span>
                        </div>
                        <FormControl fullWidth sx={{ mb: 4, marginLeft: '20px', ...FormsStyles.field }}>
                            <InputLabel id="client-partner-label">PicK Client Partner</InputLabel>
                            <Select
                                labelId="client-partner-label"
                                id="client-partner-select"
                                value={clientPartner}
                                onChange={handleCPChange}
                                label="Pick Client Partner"
                            >
                                {clientPOptions.map((clientPOptions) => (
                                    <MenuItem key={clientPOptions.user_id} value={clientPOptions.user_id}>
                                        {clientPOptions.first_name} {clientPOptions.last_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <div style={{ marginBottom: '20px' }}>
                            <span style={{ ...NewProjectStyles.numbers }}>7</span>
                            <span style={{ ...NewProjectStyles.text }}>Director of Engineering</span>
                        </div>
                        <FormControl fullWidth sx={{ mb: 4, marginLeft: '20px', ...FormsStyles.field }}>
                            <InputLabel id="doe-label">Pick Director of Engineering</InputLabel>
                            <Select
                                labelId="doe-label"
                                id="doe-select"
                                value={doE}
                                onChange={handleDoEChange}
                                label="Pick Director of Engineering"
                            >
                                {doEOptions.map((doEOptions) => (
                                    <MenuItem key={doEOptions.user_id} value={doEOptions.user_id}>
                                        {doEOptions.first_name} {doEOptions.last_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <div style={{ marginBottom: '20px' }}>
                            <span style={{ ...NewProjectStyles.numbers }}>8</span>
                            <span style={{ ...NewProjectStyles.text }}>Slack Channel</span>
                        </div>
                        <TextField
                            type="text"
                            variant="outlined"
                            color="secondary"
                            label="Type slack channel"
                            onChange={handleSlackChannelChnage}
                            value={slackChannel}
                            fullWidth
                            required
                            sx={{ mb: 4, marginLeft: '20px', ...FormsStyles.field }}
                        />

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '30px',
                            }}
                        >
                            <GreenButton
                                iconSrc={<Plus width={'20px'} height={'20px'} />}
                                buttonText="Create Now"
                                width="322px"
                                height="44px"
                                fontSize={14}
                                lineHeight={18}
                                type="submit"
                                onClick={handleSubmit}
                                backgroundColor={Green600}
                                backgroundColorHover={PrimaryGreen}
                                disabled={!isFormComplete}
                            />
                        </div>
                    </form>

                    {error?.message && (
                        <SnackBar open={true} message={error.message} severity={error.severity} handleClose={() => setError(null)} />
                    )}

                    {success && (
                        <SnackBar
                            open={true}
                            message="Form submitted successfully!"
                            severity="success"
                            handleClose={handleSuccessSnackbarClose}
                        />
                    )}
                </React.Fragment>
            )}
        </WrapperPage>
    );
};

export default NewProjectPage;
