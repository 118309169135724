import { Black400, White100, Blue400 } from "../../consts/colors";
import styled from "@emotion/styled";
import { TableCell } from "@mui/material";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  "&:first-of-type": {
    borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px",
  },
  "&:last-child": {
    borderTopRightRadius: "5px",
    borderBottomRightRadius: "5px",
  },
}));

const styles = {
  header: {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "18px",
    letterSpacing: "-0.5%",
    color: Blue400,
    padding: "5px",
  },
  row: {
    backgroundColor: White100,
    borderRadius: "5px",
    boxShadow: "5px 8px 10px rgba(0, 0, 0, 0.05)",
  },
  footer: {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "18px",
    letterSpacing: "-0.005em",
    textAlign: "left",
    color: Black400,
  },
  data: {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "18px",
    letterSpacing: "-5%",
    color: Black400,
    padding: "4.5px",
  },
  button: {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "18.2px",
    letterSpacing: "-0.5%",
    textTransform: "capitalize",
    color: Black400,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
};

export default styles;
