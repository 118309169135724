import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import axiosFetch from '../../api/Axios';
import { AddIcon, InputNumber, InputNumber2, InputNumber3, InputNumber4, Plus } from '../../assets/icons';
import GreenButton from '../../components/GreenButton';
import LoadingComponent from '../../components/LoadingComponent';
import SnackBar from '../../components/SnackBar';
import WrapperPage from '../../components/WrapperPage';
import { Green600, PrimaryGreen } from '../../consts/colors';
import AddQuestionsStyles from './AddQuestionsStyles';

const AddQuestions = ({ sidebarWidth }) => {
    const [error, setError] = useState(null);
    const [newQuestion, setNewQuestion] = useState('');
    const [description, setDescription] = useState('');
    const [responses, setResponses] = useState(Array(5).fill(''));
    const [responseInput, setResponseInput] = useState('');
    const [showHiddenField, setShowHiddenField] = useState(false);
    const [categories, setCategories] = useState(['1']);
    const [subCategorie, setSubCategorie] = useState('');
    const [selectedSubCategoryName, setSelectedSubCategoryName] = useState('');
    const [subcats, setSubcats] = useState(['']);
    const [success, setSuccess] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState(['1']);
    const [loadingSubcats, setLoadingSubcats] = useState(false);

    useEffect(() => {
        getSubcats()
            .then(() => setLoadingSubcats(false))
            .catch((error) => {
                console.log('Error fetching subcategories:', error);
                setLoadingSubcats(false);
            });
    }, []);

    useEffect(() => {
        getSubcats();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const trimmedResponses = responses.filter((response) => response.trim() !== '');

        const dataToSend = {
            value_question: newQuestion,
            is_active: true,
            categories: selectedCategories,
            sub_category: subCategorie,
            responses: trimmedResponses,
            description: description,
        };

        const options = {
            url: process.env.REACT_APP_API_PORT + 'question',
            method: 'POST',
            data: dataToSend,
        };
        try {
            const responseData = await axiosFetch(options);
            setNewQuestion('');
            setResponses(Array(5).fill(''));
            setSubCategorie('');
            setShowHiddenField(false);
            setDescription('');

            setSuccess(true);
        } catch (error) {
            setError({
                message: error.message,
                severity: 'error',
            });
        }
    };

    const handleNewQuestionChange = (event) => {
        setNewQuestion(event.target.value);
    };

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

    const handleAddDropdownOption = () => {
        if (responseInput !== '') {
            setResponses((prevResponses) => [...prevResponses, responseInput]);
            setResponseInput('');
        }
        setShowHiddenField(true);
    };

    const getSubcats = async () => {
        try {
            const response = await axiosFetch({
                methid: 'GET',
                url: process.env.REACT_APP_API_PORT + 'subcats',
            });
            setSubcats(response);
        } catch (error) {
            console.log('Error: ', error);
            throw error;
        }
    };

    const handleSuccessSnackbarClose = () => {
        setSuccess(false);
        window.location.reload();
    };

    function renderHeaderQ(icon, title) {
        return (
            <div style={{ marginBottom: '20px', display: 'flex' }}>
                <span>{icon}</span>
                <span>
                    <Typography sx={AddQuestionsStyles.text}>{title}</Typography>
                </span>
            </div>
        );
    }

    return (
        <WrapperPage sidebarWidth={sidebarWidth}>
            {loadingSubcats ? (
                <LoadingComponent />
            ) : (
                <React.Fragment>
                    <h1 style={{ ...AddQuestionsStyles.textTitle }}>Create new question</h1>
                    <form onSubmit={handleSubmit}>
                        {renderHeaderQ(<InputNumber width={'25px'} height={'25px'} />, 'Question Text')}
                        <TextField
                            type="text"
                            variant="outlined"
                            color="secondary"
                            label="The question text"
                            onChange={handleNewQuestionChange}
                            value={newQuestion}
                            fullWidth
                            required
                            sx={{
                                mb: 2,
                                marginLeft: '20px',
                                width: '98%',
                                ...AddQuestionsStyles.field,
                            }}
                        />
                        {renderHeaderQ(<InputNumber2 width={'25px'} height={'25px'} />, 'Question Description')}
                        <TextField
                            type="text"
                            variant="outlined"
                            color="secondary"
                            label="The question description"
                            onChange={handleDescriptionChange}
                            value={description}
                            fullWidth
                            required
                            sx={{
                                mb: 2,
                                marginLeft: '20px',
                                width: '98%',
                                ...AddQuestionsStyles.field,
                            }}
                        />
                        {renderHeaderQ(<InputNumber3 width={'25px'} height={'25px'} />, 'Categories')}
                        <FormControl
                            sx={{
                                width: '98%',
                                marginLeft: '20px',
                                ...AddQuestionsStyles.field,
                            }}
                        >
                            <InputLabel>Categories *</InputLabel>
                            <Select
                                label="Categories *"
                                value={subCategorie}
                                required
                                onChange={(event) => {
                                    const selectedSubCategorie = event.target.value;
                                    setSubCategorie(selectedSubCategorie);
                                    const selectedSubCategoryObj = subcats.find(
                                        (subCategory) => subCategory.sub_category_id === selectedSubCategorie
                                    );
                                    setSelectedSubCategoryName(selectedSubCategoryObj ? selectedSubCategoryObj.name : '');
                                }}
                                displayEmpty
                                renderValue={() => {
                                    return selectedSubCategoryName || '';
                                }}
                                sx={{ width: '100%' }}
                            >
                                {subcats.map((subCategory, index) => (
                                    <MenuItem key={index + 1} value={subCategory.sub_category_id}>
                                        {subCategory.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        {renderHeaderQ(<InputNumber4 width={'25px'} height={'25px'} />, 'Questions dropdown options')}
                        {responses.map((option, index) => (
                            <Box sx={AddQuestionsStyles.responseBox} key={index}>
                                <TextField
                                    type="text"
                                    variant="outlined"
                                    color="secondary"
                                    label={`Type question dropdown option text `}
                                    onChange={(event) => {
                                        const newResponses = [...responses];
                                        newResponses[index] = event.target.value;
                                        setResponses(newResponses);
                                    }}
                                    value={option}
                                    fullWidth
                                    sx={{
                                        mb: 2,
                                        marginLeft: '20px',
                                        ...AddQuestionsStyles.field,
                                    }}
                                />
                            </Box>
                        ))}
                        {showHiddenField && (
                            <TextField
                                type="text"
                                variant="outlined"
                                color="secondary"
                                label="Type question dropdown option text"
                                onChange={(event) => setResponseInput(event.target.value)}
                                value={responseInput}
                                fullWidth
                                sx={{
                                    mb: 2,
                                    marginLeft: '20px',
                                    width: '98%',
                                    ...AddQuestionsStyles.field,
                                }}
                            />
                        )}

                        <Button sx={AddQuestionsStyles.addButton} onClick={handleAddDropdownOption}>
                            <AddIcon />
                            <Typography sx={AddQuestionsStyles.addButtonText}>Add Dropdown Option</Typography>
                        </Button>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '30px',
                            }}
                        >
                            <GreenButton
                                iconSrc={<Plus width={'20px'} height={'20px'} />}
                                buttonText="Create Now"
                                width="322px"
                                height="44px"
                                fontSize={14}
                                lineHeight={18}
                                type="submit"
                                onClick={handleSubmit}
                                backgroundColor={Green600}
                                backgroundColorHover={PrimaryGreen}
                            />
                        </div>
                    </form>

                    {error?.message && (
                        <SnackBar open={true} message={error.message} severity={error.severity} handleClose={() => setError(null)} />
                    )}
                    {success && (
                        <SnackBar
                            open={true}
                            message="Question created successfully!"
                            severity="success"
                            handleClose={handleSuccessSnackbarClose}
                        />
                    )}
                </React.Fragment>
            )}
        </WrapperPage>
    );
};

export default AddQuestions;
