import { PERMISSIONS, USER_ROLES } from "../consts/constants";
import { removeItem, setItem } from "./LocalStorageUtils";

export const mapRoleToPermissions = (role) => {
  switch (role) {
    case USER_ROLES.ADMIN:
      return PERMISSIONS.ADMIN;
    case USER_ROLES.MANAGER:
      return PERMISSIONS.MANAGER;
    default:
      return [];
  }
};

export const logOut = () => {
  removeItem("jwtToken");
  removeItem("profile");
  removeItem("currentUser");
  setItem("loggedIn", false);
};
