import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Box, Button, CardContent, IconButton, Tooltip, Typography } from '@mui/material';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import axiosFetch from '../../api/Axios';
import { CurrentUserContext } from '../../App';
import SeeProjectDetailsStyles from '../../pages/SeeProjectDetails/SeeProjectDetailsStyles';
import { FilterButtonStyle } from '../filter/FilterButtonStyle';
import LoadingComponent from '../LoadingComponent';
import SnackBar from '../SnackBar';
import ActionItem from './ActionItem';

export default function ActionItems({ users, projectId }) {
    const [actionItems, setActionItems] = useState([]);
    const [closedActionItems, setClosedActionItems] = useState([]);
    const [openActionItems, setOpenActionItems] = useState([]);
    const [showClosedActionItems, setShowClosedActionItems] = useState(false);
    const [loading, setLoading] = useState(false);
    const [snackbarText, setSnackbarText] = useState();
    const [snackbarSeverity, setSnackbarSeverity] = useState();
    const [showSnackbar, setShowSnackbar] = useState(false);
    const { currentUser } = useContext(CurrentUserContext);

    useEffect(() => {
        fetchActionItemsByProjectId();
    }, []);

    const displaySnackbar = (text, severity) => {
        setSnackbarText(text);
        setSnackbarSeverity(severity);
        setShowSnackbar(true);
    };

    const defaultValues = {
        action_items: 'No Action Items Available',
    };

    const fetchActionItemsByProjectId = () => {
        setLoading(true);
        axiosFetch({
            method: 'GET',
            url: process.env.REACT_APP_API_PORT + `action_items/project/${projectId}`,
        })
            .then((res) => {
                const openItems = res.filter((item) => item.status !== 'Closed');
                const closedItems = res.filter((item) => item.status === 'Closed');
                setActionItems(res);
                setOpenActionItems(openItems);
                setClosedActionItems(closedItems);
                setLoading(false);
            })
            .catch(() => displaySnackbar('Error fetching action items', 'error'));
    };

    const updateItem = (item) => {
        axiosFetch({
            method: 'PUT',
            data: item,
            url: process.env.REACT_APP_API_PORT + `action_item/${item.action_item_id}`,
        })
            .then((res) => {
                const updatedItems = actionItems.map((a) => (a.action_item_id === item.action_item_id ? item : a));
                setActionItems(updatedItems);
                setOpenActionItems(updatedItems.filter((a) => a.status !== 'Closed'));
                setClosedActionItems(updatedItems.filter((a) => a.status === 'Closed'));

                displaySnackbar('Updated action item', 'success');
            })
            .catch(() => {
                displaySnackbar('Error updating action item', 'error');
            });
    };

    const addActionItem = () => {
        const newItem = {
            project_id: projectId,
            user_id: currentUser.id,
            status: 'Open',
            description: '',
            responsible: '',
        };
        axiosFetch({
            method: 'POST',
            data: newItem,
            url: process.env.REACT_APP_API_PORT + 'action_item',
        })
            .then((res) => {
                setActionItems([res, ...actionItems]);
                setOpenActionItems([res, ...openActionItems]);
                displaySnackbar('Added action item', 'success');
            })
            .catch(() => displaySnackbar('Error creating action item', 'error'));
    };

    const deleteActionItem = (actionItemId) => {
        axiosFetch({
            method: 'DELETE',
            url: process.env.REACT_APP_API_PORT + `action_item/${actionItemId}`,
        })
            .then(() => {
                setActionItems(actionItems.filter((a) => a.action_item_id !== actionItemId));
                setOpenActionItems(openActionItems.filter((a) => a.action_item_id !== actionItemId));
                setClosedActionItems(closedActionItems.filter((a) => a.action_item_id !== actionItemId));
                displaySnackbar('Deleted action item', 'success');
            })
            .catch(() => displaySnackbar('Error deleting action item', 'error'));
    };

    return (
        <CardContent style={{ height: 450 }}>
            <SnackBar open={showSnackbar} message={snackbarText} severity={snackbarSeverity} handleClose={() => setShowSnackbar(false)} />
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography sx={SeeProjectDetailsStyles.titleDescription}>Action Items</Typography>
                <Tooltip title="Add Action Item">
                    <IconButton sx={{ marginTop: '-10px', padding: '0' }} onClick={addActionItem}>
                        <AddCircleIcon
                            sx={{
                                ...FilterButtonStyle.button,
                                width: 48,
                            }}
                        />
                    </IconButton>
                </Tooltip>
            </Box>

            <Box style={{ height: '100%', overflowY: 'auto', paddingBottom: 32 }}>
                {loading && <LoadingComponent style={{ marginTop: 'auto' }} />}
                {!loading && actionItems.length === 0 ? (
                    <Typography sx={SeeProjectDetailsStyles.graphText}> {defaultValues.action_items} </Typography>
                ) : null}
                {!loading && (
                    <>
                        {closedActionItems.length > 0 && !showClosedActionItems && (
                            <p>
                                {closedActionItems.length} closed action item(s) are not visible{' '}
                                <Button variant="text" onClick={() => setShowClosedActionItems(true)}>
                                    Show
                                </Button>
                            </p>
                        )}
                        {closedActionItems.length > 0 && showClosedActionItems && (
                            <p>
                                Also showing {closedActionItems.length} closed action item(s).{' '}
                                <Button variant="text" onClick={() => setShowClosedActionItems(false)}>
                                    Hide
                                </Button>
                            </p>
                        )}
                        {showClosedActionItems &&
                            actionItems.map((item) => (
                                <ActionItem
                                    key={item.action_item_id}
                                    item={item}
                                    users={users}
                                    updateItem={updateItem}
                                    deleteItem={deleteActionItem}
                                />
                            ))}
                        {!showClosedActionItems &&
                            openActionItems.map((item) => (
                                <ActionItem
                                    key={item.action_item_id}
                                    item={item}
                                    users={users}
                                    updateItem={updateItem}
                                    deleteItem={deleteActionItem}
                                />
                            ))}
                    </>
                )}
            </Box>
        </CardContent>
    );
}
