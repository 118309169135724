export const White100="#F9F9F9";
export const White400="#ECECEC";
export const White800= "#D4D4D4";
export const Black100="#818181";
export const Black400="#585858";
export const Black800="#353535";
export const PrimaryBlue="#46597C";
export const Blue100="#59709C";
export const Blue400="#2D3F62";
export const Blue800="#283348";
export const Green600="#477c34";
export const PrimaryGreen="#65A74E";
export const Green100="#A7FD8A";
export const Green300="#7CDA5C";
export const Green900="#315224";
export const PrimaryYellow="#FFE35E";
export const Yellow100="#FFF5C2";
export const Yellow400="#FFED95";
export const RedAlert="#CE3131";


