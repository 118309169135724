import { Box } from '@mui/material';
import { GreenColor, YellowColor, RedColor } from '../assets/icons';
import { UtilsStyles } from './utilsStyles';


 export const renderStatusColumn = (status) => {
    let statusText;
    let statusIcon;
  
    if (status === 'Green') {
      statusText = 'On Track';
      statusIcon = <GreenColor sx={UtilsStyles.tableColumnStatus} />
    } else if (status === 'Yellow') {
      statusText = 'Warning';
      statusIcon = <YellowColor sx={UtilsStyles.tableColumnStatus} />
    } else if (status === 'Red') {
      statusText = 'Fix Now';
      statusIcon = <RedColor sx={UtilsStyles.tableColumnStatus} />
    } else {
      statusText = status;
      statusIcon = null;
    }

    return (
        <Box>
            {statusIcon} {statusText}
        </Box>
    );
  };