import React, {useEffect, useState} from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import { FilterButtonStyle } from "./FilterButtonStyle";
import { RemoveIcon } from "../../assets/icons";
import { Green300, RedAlert, Yellow400 } from "../../consts/colors";
import {getItem, setItem} from "../../utils/LocalStorageUtils";

const FilterButton = ({ title, icon, sortType, reviewOption }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [activeFilter, setActiveFilter] = useState(null);

    useEffect(() => {
        const projectsFilter = getItem("projectsFilter");
        if (projectsFilter && projectsFilter !== "") {
            setActiveFilter(projectsFilter)
            sortType(projectsFilter)
        }
    }, []);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleButtonRemove = () => {
        sortType("");
        setActiveFilter(null);
        setAnchorEl(null);
        setItem("projectsFilter", "")
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (value) => {
        sortType(value);
        setActiveFilter(value);
        setAnchorEl(null);
        setItem("projectsFilter", value)
    };

    return (
        <div>
            <Button
                onClick={handleClick}
                startIcon={
                    <img
                        src={icon}
                        alt="Icon"
                        style={{
                            width: "20px",
                            height: "20px",
                        }}
                    />
                }
                sx={FilterButtonStyle.button}
            >
                {activeFilter ? <span>{activeFilter}</span> : <span>{title}</span>}
            </Button>
            {activeFilter && (
                <Button
                    onClick={handleButtonRemove}
                    sx={FilterButtonStyle.menuItem}
                    disableRipple
                >
                    <RemoveIcon />
                </Button>
            )}
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                slotProps={{
                    style: {
                        marginTop: "8px",
                        width: "250px",
                    },
                }}
            >
                {reviewOption ? (
                    [
                        <MenuItem
                            key="My Projects"
                            onClick={() => handleMenuItemClick("My Projects")}
                            sx={FilterButtonStyle.menuItem}
                        >
                            My Projects
                        </MenuItem>,
                        <MenuItem
                            key="Last review > 14d"
                            onClick={() => handleMenuItemClick("Last review > 14d")}
                            sx={FilterButtonStyle.menuItem}
                        >
                            Last review more 2 weeks
                        </MenuItem>,
                        <MenuItem
                            key="Needs Attention"
                            onClick={() => handleMenuItemClick("Needs Attention")}
                            sx={FilterButtonStyle.menuItem}
                        >
                            Needs Management Discussions
                        </MenuItem>,
                        <MenuItem
                            key="Risk Level G"
                            onClick={() => handleMenuItemClick("Risk Level G")}
                            sx={FilterButtonStyle.menuItem}
                        >
                            <div style={{ display: "flex" }}>
                                Risk Level:
                                <div
                                    style={{
                                        width: "12px",
                                        height: "12px",
                                        borderRadius: "50%",
                                        backgroundColor: Green300,
                                        marginLeft: "5px",
                                        marginTop: "3px",
                                    }}
                                ></div>
                            </div>
                        </MenuItem>,
                        <MenuItem
                            key="Risk Level Y"
                            onClick={() => handleMenuItemClick("Risk Level Y")}
                            sx={FilterButtonStyle.menuItem}
                        >
                            <div style={{ display: "flex" }}>
                                Risk Level:
                                <div
                                    style={{
                                        width: "12px",
                                        height: "12px",
                                        borderRadius: "50%",
                                        backgroundColor: Yellow400,
                                        marginLeft: "5px",
                                        marginTop: "3px",
                                    }}
                                ></div>
                            </div>
                        </MenuItem>,
                        <MenuItem
                            key="Risk Level R"
                            onClick={() => handleMenuItemClick("Risk Level R")}
                            sx={FilterButtonStyle.menuItem}
                        >
                            <div style={{ display: "flex" }}>
                                Risk Level:
                                <div
                                    style={{
                                        width: "12px",
                                        height: "12px",
                                        borderRadius: "50%",
                                        backgroundColor: RedAlert,
                                        marginLeft: "5px",
                                        marginTop: "3px",
                                    }}
                                ></div>
                            </div>
                        </MenuItem>
                    ]
                ) : null}
            </Menu>
        </div>
    );
};
export default FilterButton;
