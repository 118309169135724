import { Box } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import CustomTable from '../table/Table';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { ListActionItemsStyles } from './styles';

export default function ListActionItems({ data, paginationModel, setPaginationModel, isLoading, totalItems }) {
    const theme = useTheme();
    const classes = ListActionItemsStyles;
    const navigate = useNavigate();

    const headers = [
        { label: 'Project', key: 'project', width: '20%' },
        {
            label: 'Status',
            key: 'status',
            width: '10%',
            renderCell: (item) => (
                <Box display="flex" alignItems="center">
                    <Box
                        component="span"
                        width={8}
                        height={8}
                        borderRadius="50%"
                        marginRight={1}
                        bgcolor={item.status === 'Open' ? 'green' : 'red'}
                    />
                    {item.status}
                </Box>
            ),
        },
        { label: 'Date', key: 'date', width: '15%' },
        { label: 'Owner', key: 'owner', width: '20%' },
        { label: 'Assignee', key: 'assignee', width: '15%' },
        { label: 'Description', key: 'description', width: '20%' },
    ];

    const rows = data.map((item) => ({
        key: item.id,
        project: item.project_name,
        status: item.status,
        date: new Intl.DateTimeFormat('en-GB').format(new Date(item.date)),
        owner: item.owner,
        assignee: item.assignee,
        description: item.description,
        project_id: item.project_id,
    }));

    const handleRowClick = (row) => {
        navigate(`/see-details-project/${row.project_id}`);
    };

    return (
        <ThemeProvider theme={theme}>
            <Box className={classes.container}>
                <CustomTable
                    paginationModel={paginationModel}
                    onPaginationModelChanged={setPaginationModel}
                    headers={headers}
                    rows={rows}
                    isLoading={isLoading}
                    totalItems={totalItems}
                    isMarginTopRequired={true}
                    onRowClick={handleRowClick}
                />
            </Box>
        </ThemeProvider>
    );
}
