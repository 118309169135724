import React from 'react';
import { Box, Typography } from '@mui/material'; 
import styles from './reviewBox/styles';
import { RedAlert, PrimaryGreen, PrimaryYellow, Blue400, White100 } from '../consts/colors';

const Status = ({ data, status }) => {
    const halfLength = Math.ceil(data.length / 2);
    const column1Data = data.slice(0, halfLength);
    const column2Data = data.slice(halfLength);
    
    const COLORS = {
      Red: RedAlert,
      Yellow: PrimaryYellow,
      Green: PrimaryGreen,
    }
  
    return (
      <div style={styles.customBox}>
        <Typography style={{ ...styles.question, marginTop: '10px' }}>
          Review Scores
        </Typography>
        <Box sx={{ display: 'flex' , marginTop:'20px'}}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
            }}
          >
            {column1Data.map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography
                sx={
                  item.label === 'Overall'
                    ? [
                        styles.overallValueStyles,{backgroundColor: 'red',}
                      ]
                    : styles.valueBoxStyles
                }
              >
                {Number(item.value).toFixed(2)}
              </Typography>
                <Typography sx={styles.labelStyles}>{item.label}</Typography>
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
              marginLeft: '200px', 
            }}
          >
            {column2Data.map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography
                  sx={
                    item.label === 'Overall'
                      ? [
                        styles.overallValueStyles,
                        {backgroundColor: COLORS[status],
                        color: status === 'Yellow'? Blue400 : White100}
                      ]
                      : styles.valueBoxStyles
                  }
                >
                  {Number(item.value).toFixed(2)}
                </Typography>
                <Typography sx={styles.labelStyles}>{item.label}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </div>
    );
  };
  
  export default Status;