import { Yellow400, Black400, Green300, RedAlert, Blue400 } from '../../consts/colors';
import { createTheme } from '@mui/material/styles';

export const ProjectTableStyles = createTheme({
    bodyText: {
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '18px',
        letterSpacing: '-0.005em',
        textAlign: 'left',
        color: Black400,
    },
    headerText: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '20px',
        color: Black400,
    },
    greenDot: {
        width: '12px',
        height: '12px',
        borderRadius: '50%',
        backgroundColor: Green300,
        marginRight: '5px',
    },
    redDot: {
        width: '12px',
        height: '12px',
        borderRadius: '50%',
        backgroundColor: RedAlert,
        marginRight: '5px',
    },
    yellowDot: {
        width: '12px',
        height: '12px',
        borderRadius: '50%',
        backgroundColor: Yellow400,
        marginRight: '5px',
    },
    scoreBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Blue400,
        borderRadius: '5px',
        width: '50px',
        height: '30px',
        fontSize: '14px',
        fontWeight: 500,
        color: 'white',
    },
    managerComment: {
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        WebkitLineClamp: 2,
        lineClamp: 2,
    },
    clientPartnerComment: {
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        WebkitLineClamp: 2,
        lineClamp: 2,
    },
});
