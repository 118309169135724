import { Blue800 } from "../../consts/colors";

export const FilterButtonStyle = {
    button: {
        width: '173px',
        height: '44px',
        borderRadius: '8px',
        border: '2px solid ' + Blue800,
        backgroundColor: 'none',
        fontFamily: 'Poppins, sans-serif',
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '18px',
        letterSpacing: '-0.005em',
        textAlign: 'left',
        color: Blue800,
        '&:hover': {
          backgroundColor: 'White',
        },
        textTransform: 'capitalize',
    },
    menuItem: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '18px',
        letterSpacing: '-0.005em',
        textAlign: 'left',
        color: Blue800,
    },
}