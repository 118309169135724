import { Black100, Black400, Black800, Blue100, Blue400, White100} from "../../consts/colors";

export const ProjectCardStyle = {
    container: {
      margin: '1rem',
      display: 'flex',
      borderRadius: '20px',
      fontFamily: 'Poppins',
      width: '90%',
      marginRight: '1px',
      position: 'relative',
    },
    avatarProject: {
      width: 40,
      height: 40,
      backgroundColor: Blue100,
      borderRadius: "5px",
    },
    buttonMoreSettings: {
        padding: '0.5px', 
        lineHeight: '1', 
        justifyContent: 'right',
        minWidth:'3px',
        color: Blue100,
        position: 'absolute', 
        top: 10, 
        right: 10, 
        backgroundColor: 'transparent',
        '&:hover':{
            color: Blue400,
            backgroundColor: 'transparent',
        },
        '&:active':{
            color: Blue400,
        },
    },
    cardContent: {
      display: 'flex',
    },
    contentRoles: {
      display: 'flex', 
      flexDirection: 'column', 
      marginTop: '15px' 
    },
    profileContainer: {
      alignItems: 'center',
      marginTop: '1.0rem',
    },
    typographyPr:{
        fontFamily: 'Poppins',
        fontWeight:"bold",
        fontSize: '16px',   
    },
    typographyNamePerson:{
      alignItems: 'left',
        fontFamily: 'Poppins',
        fontWeight:"medium",
        fontSize: '14px', 
        color: Black100,
    },
    typographyDescription:{
        fontFamily: 'Poppins',
        fontWeight:"regular",
        fontSize: '14px',
        color: Black100,
        display: '-webkit-box',
        WebkitLineClamp: 2, 
        lineClamp: 2,      
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
    },
    buttonProjectName:{
        alignItems: 'center',
        fontWeight:"bold",
        color: Black800,
        padding: '8px',
        backgroundColor: 'none',
        fontFamily: 'Poppins, sans-serif',
        fontSize: '18px',
        lineHeight: '18px',
        letterSpacing: '-0.005em',
        textAlign: 'left',
        '&:hover': {
          backgroundColor: 'White',
        },
        textTransform: 'capitalize',
    },
    iconMenuSettings:{
      width:'15px',
      height:'15px',
    },
    typographyMenuOptions:{
      fontFamily: 'Poppins',
      fontWeight:"regular",
      fontSize: '14px',
      color: Blue400,
      marginLeft: '10px',
    },
    fixReview: {
      marginBottom: '3px',
      marginRight: '5px',
      width: '15px',
      height: '15px',
    },
    typographyPersonRole: {
      fontFamily: 'Poppins',
      fontWeight:"bold",
      fontSize: '14px',
      color: Black400,
      marginRight: '5px',
    },
    tooltipTitle: {
      fontFamily: 'Poppins',
      fontWeight: 'regular',
      fontSize: '14px',
      color: White100,
      transition: 'background-color 0.3s, color 0.3s', 
    },
    overallScoreBox: {
      position: 'absolute',
      bottom: '20px', 
      right: '20px',  
      backgroundColor: Blue100,
      color: 'white',        
      padding: '4px 8px',   
      borderRadius: '4px',    
      fontSize: '14px',     
      fontWeight: 'bold',  
      cursor: 'pointer', 
    },
    overallScore: {
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontWeight: 'medium',
    }
    
  };
  