import React, { useState, useRef } from 'react';
import GreenButton from "./GreenButton";
import { Green600, PrimaryGreen } from "../consts/colors";
import { Plus } from "../assets/icons";

const UploadFileComponent = ({ onFileUpload  }) => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setUploadedFile(file);
    onFileUpload(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    setUploadedFile(file);
    onFileUpload(file);
  };

  return (
    <div>
      <div
        onClick={handleClick}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        style={{
          border: '2px dashed #ccc',
          borderRadius: '5px',
          padding: '20px',
          marginTop: '20px',
          textAlign: 'center',
          marginBottom: "5px",
          cursor: 'pointer',
        }}
      >
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileInputChange}
        />
        <p>Click or drop your file here</p>
      </div>
      {uploadedFile && <p>Uploaded file: {uploadedFile.name}</p>}
    </div>
  );
};

export default UploadFileComponent;