import React from "react";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { ReactComponent as ArrowBackIcon } from "../assets/icons/goBackArrow.svg";
import { Blue100 } from "../consts/colors";
import { useNavigate } from "react-router-dom";

const GoBackButton = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <IconButton color="primary" onClick={handleGoBack} sx={{ height: "43px" }}>
      <ArrowBackIcon />
      <Typography
        sx={{
          padding: "5px",
          fontFamily: "Poppins",
          fontSize: 14,
          fontWeight: 700,
          lineHeight: "18px",
          letterSpacing: "-0.005em",
          textAlign: "left",
          color: Blue100,
        }}
      >
        Go Back
      </Typography>
    </IconButton>
  );
};

export default GoBackButton;
