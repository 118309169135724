import { Green600, White100, White800, White400 } from "../../consts/colors.js";


export const sidebarButtonStyle = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '2px',
    marginTop: '3%',
    marginLeft: '11%',
  },
  button: {
    backgroundColor: 'transparent', 
    fontFamily: 'Poppins, sans-serif',
    fontSize: '18px', 
    fontWeight: 'regular',
    lineHeight: '100%',
    letterSpacing: '-1%', 
    textAlign: 'left',
    textTransform: 'capitalize',
    padding: 1.5,
    color: White800,
    justifyContent: 'left',
    '&:hover': {
      color: Green600,
      backgroundColor: White400,
    },
    '& .MuiButton-startIcon img': {
        fill: White800,
        color: White800,
        '&:hover': {
          color: White100,
          backgroundColor: 'transparent', 
          fontWeight: 300,
        },
      },
  },
 
};
