import axios from "axios";
import { getItem, setItem } from "../utils/LocalStorageUtils";
import { logOut } from "../utils/userUtils";

export default function axiosFetch(options) {
  const token = getItem("jwtToken");
  
  if (!options.headers) {
    options.headers = {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      authorization: `Bearer ${token}`,
    };
    if (options.contentType) {
      options.headers["Content-Type"] = options.contentType;
    }
  }

  if (!options.method) {
    options.method = "get";
  }
  return axios(options)
    .then((response) => {
      if (response.headers && response.headers.authorization) {
        const newToken = response.headers.authorization.split(" ")[1];
        setItem("jwtToken", newToken);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("Error:", error);
      if (
        (error.response && error.response.status === 401) ||
        (error.response && error.response.status === 403)
      ) {
        logOut();
        window.location.href = "/login";
      }
      throw error;
    });
}
