import React from "react";
import { CircularProgress, Typography } from "@mui/material";
import { Green600 } from "../consts/colors";

export default function LoadingComponent({ style }) {
  const { marginTop } = style || {};
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: marginTop || "20vh",
      }}
    >
      <Typography
        variant="body2"
        sx={{
          fontFamily: "Poppins",
          fontSize: "14px",
          fontWeight: 700,
          lineHeight: "18px",
          letterSpacing: "-0.005em",
          textAlign: "left",
          color: Green600,
        }}
      >
        Loading...
      </Typography>
      <CircularProgress sx={{ color: Green600, marginTop: "16px" }} />
    </div>
  );
}
