import React from "react";
import { useNavigate } from "react-router-dom";
import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from "recharts";
import { Box } from "@mui/material";

const RADIAN = Math.PI / 180;

export default function RenderPieChart({ data, COLORS, legendText }) {
  const navigate = useNavigate();

  const handleDotClick = (param, status) => {
    switch (status) {
      case "Yes":
        navigate(`/projects?needsAttention=${param}`);
        break;
      case "RiskLevel0":
        navigate(`/projects?risk_level_0=${param}`);
        break;
      case "RiskLevel1":
        navigate(`/projects?risk_level_1=${param}`);
        break;
      case "RiskLevel2":
        navigate(`/projects?risk_level_2=${param}`);
        break;
      default:
        navigate("/projects");
        break;
    }
  };

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    data,
  }) => {
    if (percent === 0){
      return null
    }
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    const onClick = () => {
      handleDotClick(true, data.status);
    };

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        onClick={onClick}
        style={{ cursor: "pointer" }}
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          cursor: "pointer",
          width: "100%",
          height: "100%",
        }}
      >
        <ResponsiveContainer width="100%" height={250}>
          <PieChart width={800} height={300}>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={(props) =>
                renderCustomizedLabel({
                  ...props,
                  data: props,
                })
              }
              outerRadius={80}
              fill="#8884d8"
              dataKey="count"
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[entry.status]}
                  cursor="pointer"
                />
              ))}
            </Pie>
            <Legend
              cx="20%"
              verticalAlign="bottom"
              height={36}
              formatter={(value, entry, index) => (
                <span style={{ color: COLORS[data[index].status] }}>
                  {legendText[data[index].status]}
                </span>
              )}
            />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </Box>
  );
}
