import React, { useState, useEffect } from 'react';
import { TextField, Typography, Button, FormControl, InputLabel, Select, MenuItem, Box, Checkbox } from '@mui/material';
import { useParams } from 'react-router-dom';
import WrapperPage from '../../components/WrapperPage';
import { AddIcon, InputNumber, InputNumber2, InputNumber3, InputNumber4 } from '../../assets/icons';
import AddQuestionsStyles from '../AddQuestions/AddQuestionsStyles';
import GreenButtonED from '../../components/GreenButtonED';
import SnackBar from '../../components/SnackBar';
import axiosFetch from '../../api/Axios';
import Save from '../../assets/icons/save.svg';
import LoadingComponent from '../../components/LoadingComponent';
import { Green600, PrimaryGreen } from '../../consts/colors';

function EditQuestionsPage({ sidebarWidth }) {
    const { questionId } = useParams();
    const [questionData, setQuestionData] = useState({
        value_question: '',
        category_id: ['1'],
        sub_category: '',
        description: '',
    });
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [responses, setResponses] = useState([]);
    const [responseInput, setResponseInput] = useState('');
    const [showHiddenField, setShowHiddenField] = useState(false);
    const [categories, setCategories] = useState(['1']);
    const [subCategory, setSubCategory] = useState('');
    const [selectedSubCategoryName, setSelectedSubCategoryName] = useState('');
    const [subcategories, setSubcategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState(['1']);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchData(questionId);
    }, [questionId]);

    useEffect(() => {
        getSubcategories();
    }, []);

    const fetchData = async (questionId) => {
        try {
            setIsLoading(true);
            const questionResponse = await axiosFetch({
                method: 'GET',
                url: process.env.REACT_APP_API_PORT + `question/${questionId}?resp`,
            });
            console.log(questionResponse);
            setQuestionData(questionResponse);

            if (questionResponse && questionResponse.responses) {
                const updatedResponses = questionResponse.responses.map((response) => response.value_response);
                setResponses(updatedResponses);
            }

            if (questionResponse && questionResponse.categories) {
                const updatedCategories = questionResponse.categories.map((category) => category);
                setSelectedCategories(updatedCategories);
            }

            if (questionResponse && questionResponse.sub_category) {
                setSubCategory(questionResponse.sub_category);
                setSelectedSubCategoryName(questionResponse.sub_category_name);
            }
        } catch (error) {
            console.log('Error: ', error);
        } finally {
            setIsLoading(false);
        }
    };

    const getSubcategories = async () => {
        try {
            const response = await axiosFetch({
                method: 'GET',
                url: process.env.REACT_APP_API_PORT + 'subcats',
            });
            setSubcategories(response);
        } catch (error) {
            console.log('Error: ', error);
            throw error;
        }
    };

    const handleQuestionChange = (event) => {
        setQuestionData({
            ...questionData,
            value_question: event.target.value,
        });
        setIsFormDirty(true);
    };

    const handleQuestionDescriptionChange = (event) => {
        setQuestionData({
            ...questionData,
            description: event.target.value,
        });
        setIsFormDirty(true);
    };

    const handleSubmit = async () => {
        try {
            const response = await axiosFetch({
                method: 'PUT',
                url: process.env.REACT_APP_API_PORT + `question/${questionId}`,
                data: {
                    value_question: questionData.value_question,
                    categories: selectedCategories,
                    sub_category: subCategory,
                    responses: responses,
                    description: questionData.description,
                },
            });
            if (response) {
                setSuccess(true);
                fetchData(questionId);
            }

            setIsFormDirty(false);
        } catch (error) {
            console.log('Error: ', error);
            setError({
                message: error.message,
                severity: 'error',
            });
        }
    };

    const handleSubcategoryChange = (event) => {
        const selectedSubCategoryId = event.target.value;
        const selectedSubCategory = subcategories.find((sub) => sub.sub_category_id === selectedSubCategoryId);

        setSelectedSubCategoryName(selectedSubCategory ? selectedSubCategory.name : '');
        setSubCategory(selectedSubCategoryId);
        setIsFormDirty(true);
        setQuestionData({
            ...questionData,
            sub_category: selectedSubCategoryId,
        });
    };

    const handleSuccessSnackbarClose = () => {
        setSuccess(false);
    };

    function renderHeaderQ(icon, title) {
        return (
            <div style={{ marginBottom: '20px', display: 'flex' }}>
                <span>{icon}</span>
                <span>
                    <Typography sx={AddQuestionsStyles.text}>{title}</Typography>
                </span>
            </div>
        );
    }

    const handleResponseInputChange = (event) => {
        setIsFormDirty(true);
        const newResponseInput = event.target.value;
        setResponseInput(newResponseInput);
    };

    const handleAddDropdownOption = () => {
        if (responseInput.trim() !== '') {
            setResponses((prevResponses) => [...prevResponses, responseInput]);
            setResponseInput('');
            setIsFormDirty(true);
        }

        setShowHiddenField(true);
    };

    return (
        <WrapperPage sidebarWidth={sidebarWidth}>
            {isLoading ? (
                <LoadingComponent />
            ) : (
                <React.Fragment>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginTop: '36px',
                        }}
                    >
                        <h1 style={{ ...AddQuestionsStyles.textTitle, margin: 0 }}>Edit Question</h1>
                        <GreenButtonED
                            iconSrc={Save}
                            buttonText="Save Changes"
                            width="206px"
                            height="44px"
                            fontSize={14}
                            lineHeight={18}
                            type="button"
                            onClick={handleSubmit}
                            disabled={!isFormDirty}
                            style={{ flex: '0 0 auto' }}
                            backgroundColor={Green600}
                            backgroundColorHover={PrimaryGreen}
                        />
                    </div>
                    <form onSubmit={renderHeaderQ}>
                        {renderHeaderQ(<InputNumber width={'25px'} height={'25px'} />, 'Question Text')}
                        <TextField
                            type="text"
                            variant="outlined"
                            color="secondary"
                            label="The question text"
                            onChange={handleQuestionChange}
                            value={questionData.value_question || ''}
                            fullWidth
                            required
                            sx={{
                                mb: 2,
                                marginLeft: '20px',
                                width: '98%',
                                ...AddQuestionsStyles.field,
                            }}
                        />
                        {renderHeaderQ(<InputNumber2 width={'25px'} height={'25px'} />, 'Question Description')}
                        <TextField
                            type="text"
                            variant="outlined"
                            color="secondary"
                            label="The question description"
                            onChange={handleQuestionDescriptionChange}
                            value={questionData.description || ''}
                            fullWidth
                            required
                            sx={{
                                mb: 2,
                                marginLeft: '20px',
                                width: '98%',
                                ...AddQuestionsStyles.field,
                            }}
                        />
                        {renderHeaderQ(<InputNumber3 width={'25px'} height={'25px'} />, 'Categories')}
                        <Box sx={AddQuestionsStyles.responseBox}>
                            {subcategories.length > 0 && (
                                <FormControl
                                    fullWidth
                                    sx={{
                                        marginBottom: 2,
                                        marginLeft: '20px',
                                        width: '100%',
                                        ...AddQuestionsStyles.field,
                                    }}
                                >
                                    <InputLabel>Categories</InputLabel>
                                    <Select
                                        label="Categories"
                                        value={selectedSubCategoryName}
                                        onChange={handleSubcategoryChange}
                                        displayEmpty
                                        renderValue={() => selectedSubCategoryName || ''}
                                    >
                                        {subcategories.map((subCategory) => (
                                            <MenuItem key={subCategory.sub_category_id} value={subCategory.sub_category_id}>
                                                {subCategory.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        </Box>
                        {renderHeaderQ(<InputNumber4 width={'25px'} height={'25px'} />, 'Questions dropdown options')}
                        {responses.map((option, index) => (
                            <Box sx={AddQuestionsStyles.responseBox} key={index}>
                                <TextField
                                    type="text"
                                    variant="outlined"
                                    color="secondary"
                                    label={`Type question dropdown option text `}
                                    onChange={(event) => {
                                        const newResponses = [...responses];
                                        newResponses[index] = event.target.value;
                                        setResponses(newResponses);
                                        setIsFormDirty(true);
                                    }}
                                    value={option}
                                    fullWidth
                                    sx={{
                                        mb: 2,
                                        marginLeft: '20px',
                                        ...AddQuestionsStyles.field,
                                    }}
                                />
                            </Box>
                        ))}
                        {showHiddenField && (
                            <TextField
                                type="text"
                                variant="outlined"
                                color="secondary"
                                label="Type question dropdown option text"
                                onChange={handleResponseInputChange}
                                value={responseInput}
                                fullWidth
                                sx={{
                                    mb: 2,
                                    marginLeft: '20px',
                                    width: '98%',
                                    ...AddQuestionsStyles.field,
                                }}
                            />
                        )}
                        <Button sx={AddQuestionsStyles.addButton} onClick={handleAddDropdownOption}>
                            <AddIcon />
                            <Typography sx={AddQuestionsStyles.addButtonText}>Add Dropdown Option</Typography>
                        </Button>
                    </form>
                    {error?.message && (
                        <SnackBar open={true} message={error.message} severity={error.severity} handleClose={() => setError(null)} />
                    )}
                    {success && (
                        <SnackBar
                            open={true}
                            message="Question edited successfully!"
                            severity="success"
                            handleClose={handleSuccessSnackbarClose}
                        />
                    )}
                </React.Fragment>
            )}
        </WrapperPage>
    );
}

export default EditQuestionsPage;
