import axiosFetch from "../../api/Axios";
import { getCurrentUser } from "../listUsers/getUsers";

export async function fetchProjectsData() {
  try {
    const response = await axiosFetch({
      method: "GET",
      url:
        process.env.REACT_APP_API_PORT + "projects?limit=all&is_archived=false",
    });
    return response;
  } catch (error) {
    console.log("Error: ", error);
    throw error;
  }
}

export async function getNeedsManagemtAttentionProjects() {
  try {
    const response = await axiosFetch({
      method: "GET",
      url:
        process.env.REACT_APP_API_PORT +
        "projects?limit=all&needs_attention=true",
    });
    return response;
  } catch (error) {
    console.log("Error: ", error);
    throw error;
  }
}

export async function getRiskLevel0() {
  try {
    const response = await axiosFetch({
      method: "GET",
      url: process.env.REACT_APP_API_PORT + "projects?limit=all&risk_level=0",
    });
    return response;
  } catch (error) {
    console.log("Error: ", error);
    throw error;
  }
}

export async function getRiskLevel1() {
  try {
    const response = await axiosFetch({
      method: "GET",
      url: process.env.REACT_APP_API_PORT + "projects?limit=all&risk_level=1",
    });
    return response;
  } catch (error) {
    console.log("Error: ", error);
    throw error;
  }
}

export async function getRiskLevel2() {
  try {
    const response = await axiosFetch({
      method: "GET",
      url: process.env.REACT_APP_API_PORT + "projects?limit=all&risk_level=2",
    });
    return response;
  } catch (error) {
    console.log("Error: ", error);
    throw error;
  }
}

export const fetchMyProjects = async () => {
  try {
    const response = await getCurrentUser();
    const userId = response.user_id;
    const projectsResponse = await axiosFetch({
      method: "GET",
      url:
        process.env.REACT_APP_API_PORT +
        `projects?limit=all&is_archived=false&user_id=${userId}`,
    });
    // console.log("My projects: ", projectsResponse);
    return projectsResponse;
  } catch (error) {
    console.log("Error: ", error);
    return []; // or handle error appropriately
  }
};