import { White100, Blue400, Blue800, Black400 } from "../../consts/colors";
const DashboardStyles = {
  main: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "30px",
    marginLeft: "10px",
    marginRight: "30px",
  },
  leftCard: {
    width: "97%",
  },
  barChart: {
    height: "800px",
    backgroundColor: White100,
    marginBottom: "2%",
    borderRadius: "10px",
  },
  lineChart: {
    height: "390px",
    backgroundColor: White100,
    marginBottom: "2%",
    borderRadius: "10px",
  },
  pieChart: {
    height: "390px",
    backgroundColor: White100,
    marginBottom: "2%",
    borderRadius: "10px",
  },
  titleDescription: {
    padding: "25px",
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "28.8px",
    color: Blue400,
    letter: "-1.5%",
  },
  periodButton: {
    width: "120px",
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "17px",
    color: Blue400,
    backgroundColor: White100,
    textTransform: "capitalize",
  },
  menuItem: {
    fontFamily: "Poppins, sans-serif",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "18px",
    letterSpacing: "-0.005em",
    textAlign: "left",
    color: Blue800,
  },
  customTool: {
    backgroundColor: "white",
    border: "1px solid #ccc",
    padding: "10px",
    borderRadius: "5px",
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
  },
  customRow: {
    display: "flex",
    alignItems: "center",
    marginBottom: "5px",
  },
  customValues: {
    display: "flex",
    flexDirection: "column",
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "18.2px",
    color: Black400,
  },
  customValue: {
    marginTop: "2px",
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "18.2px",
    color: Black400,
  },
};
export default DashboardStyles;
