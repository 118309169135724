import { Paper, Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { AttentionIcon, FixNowIcon, OnTrackIcon } from "../../assets/icons";
import { Green600, PrimaryYellow, RedAlert } from "../../consts/colors";
import ProjectCard from "../projectsCard/ProjectCard";
import { ListProjectComponentStyle } from "./ListProjectComponentStyle";
import SnackBar from "../SnackBar";

export default function ListProjectComponent({
  projects,
  mostRecentStatus,
  handleArchiveProject,
  handleFlagClick,
}) {
  const borderColors = [Green600, PrimaryYellow, RedAlert];
  const [error, setError] = useState(null);
  const [categorizedProjects, setCategorizedProjects] = useState({
    onTrack: [],
    needsAttention: [],
    fixNow: [],
  });

  const categorizeProjects = (sortedProjects) => {
    const categProjects = {
      onTrack: [],
      needsAttention: [],
      fixNow: [],
    };

    for (const project of sortedProjects) {
      const lastStatus = project.status;
      if (lastStatus) {
        switch (lastStatus) {
          case "Green":
            categProjects.onTrack.push(project);
            break;
          case "Yellow":
            categProjects.needsAttention.push(project);
            break;
          case "Red":
            categProjects.fixNow.push(project);
            break;
          default:
            break;
        }
      } else {
        categProjects.onTrack.push(project);
      }
    }
    return categProjects;
  };

  useEffect(() => {
     setCategorizedProjects(categorizeProjects(projects));
  }, [
    projects
  ]);

  const renderList = (projects, title, icon, borderColor) =>
    (
      <Paper sx={{ ...ListProjectComponentStyle.list, borderColor }}>
        <Box sx={ListProjectComponentStyle.box}>
          {icon}
          <Typography sx={ListProjectComponentStyle.typographyNameColumn}>
            {title}
          </Typography>
          <Box sx={ListProjectComponentStyle.circleStyle}>
            <Typography sx={ListProjectComponentStyle.typographyNumberProjects}>
              {projects.length}
            </Typography>
          </Box>
        </Box>
        {projects.map((project) => (
          <ProjectCard
            key={project.project_id}
            projects={project}
            mostRecentStatus={mostRecentStatus}
            handleArchiveProject={handleArchiveProject}
            handleFlagClick={handleFlagClick}
          />
        ))}
      </Paper>
    );

  return (
    <Box sx={{ marginLeft: "5px" }}>
      {renderList(
        categorizedProjects.onTrack,
        "On Track",
        <OnTrackIcon />,
        borderColors[0]
      )}
      {renderList(
        categorizedProjects.needsAttention,
        "Warning",
        <AttentionIcon />,
        borderColors[1]
      )}
      {renderList(
        categorizedProjects.fixNow,
        "Fix Now",
        <FixNowIcon />,
        borderColors[2]
      )}
      {error?.message && (
        <SnackBar
          open={true}
          message={error.message}
          severity={error.severity}
          handleClose={() => setError(null)}
        />
      )}
    </Box>
  );
}
