import { Avatar, Box, Button, Card, CardContent, Grid, Icon, Tooltip, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CurrentUserContext } from '../../App';
import {
    ArchiveIcon,
    ArchiveProjectIcon,
    EditProjectIcon,
    MoreSettingsProjectIcon,
    ReviewProjectIcon,
    SeeProjectDetailsIcon,
    ViewReviewHistoryIcon,
} from '../../assets/icons';
import { Green300, RedAlert, White100 } from '../../consts/colors';
import { EDIT_PERMISSION, MaxCharactersDescriptionProjectCard, REVIEW_PERMISSION } from '../../consts/constants';
import PromptUser from '../promt/PromptUser';
import { getRiskColorStyle } from '../reviewBox/RiskLevelBox';
import { ProjectCardStyle } from './ProjectCardStyles';

const StyledMenu = styled((props) => (
    <Menu
        elevation={1}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(0.5),
        minWidth: 180,

        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0px',
        },
    },
}));

const ProjectCard = ({ projects, handleArchiveProject }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isPromptVisible, setPromptVisible] = useState(false);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const { currentUser } = useContext(CurrentUserContext);
    let managerName = projects.ro_first_name + ' ' + projects.ro_last_name;
    let cp = projects.cp_first_name + ' ' + projects.cp_last_name;
    let doe = projects.doe_first_name + ' ' + projects.doe_last_name;
    let lastDate = projects.created_date;
    let isFlagActive = projects.needs_attention;
    const maxReviewTime = process.env.REACT_APP_REVIEW;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const showPrompt = () => {
        setPromptVisible(true);
    };
    const renderSettingsMenu = (icon, title, nav) => {
        return (
            <MenuItem
                key={title}
                onClick={() => {
                    handleClose();
                    if (title === 'Archive project...') {
                        showPrompt();
                    } else {
                        navigate(nav);
                    }
                }}
                disableRipple
                sx={ProjectCardStyle.menuItem}
            >
                {icon}
                <Typography sx={ProjectCardStyle.typographyMenuOptions}>{title}</Typography>
            </MenuItem>
        );
    };

    const canReviewProject = currentUser?.permissions?.includes(REVIEW_PERMISSION);
    const canEditProject = currentUser?.permissions?.includes(EDIT_PERMISSION);

    return (
        <>
            <Card
                elevation={3}
                sx={
                    isFlagActive
                        ? [ProjectCardStyle.container, { border: `5px solid red` }]
                        : [ProjectCardStyle.container, { backgroundColor: White100 }]
                }
            >
                <CardContent>
                    <div className="cardContent" style={ProjectCardStyle.cardContent}>
                        <Avatar variant="square" sx={ProjectCardStyle.avatarProject}>
                            <Typography variant="subtitle1" color="inherit" sx={ProjectCardStyle.typographyPr}>
                                {projects?.name
                                    ?.split(' ')
                                    .map(function (str) {
                                        return str ? str[0].toUpperCase() : '';
                                    })
                                    .slice(0, 2)
                                    .join('')}
                            </Typography>
                        </Avatar>
                        <Button
                            disableRipple
                            sx={ProjectCardStyle.buttonMoreSettings}
                            id="demo-customized-button"
                            aria-controls={open ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClick}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Icon>
                                    <MoreSettingsProjectIcon />
                                </Icon>
                            </div>
                        </Button>

                        <StyledMenu
                            id="demo-customized-menu"
                            MenuListProps={{
                                'aria-labelledby': 'demo-customized-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                        >
                            {canReviewProject
                                ? [
                                      renderSettingsMenu(
                                          <ReviewProjectIcon width={'18px'} height={'18px'} />,
                                          'Review project',
                                          `/review-project/${projects.project_id}`
                                      ),
                                  ]
                                : null}
                            {renderSettingsMenu(
                                <SeeProjectDetailsIcon width={'18px'} height={'18px'} />,
                                'See project details',
                                `/see-details-project/${projects.project_id}`
                            )}
                            {renderSettingsMenu(
                                <ViewReviewHistoryIcon width={'18px'} height={'18px'} />,
                                'View review history',
                                `/review-history/${projects.project_id}`
                            )}
                            {canEditProject
                                ? [
                                      renderSettingsMenu(
                                          <EditProjectIcon width={'18px'} height={'18px'} />,
                                          'Edit project',
                                          `/view-edit/${projects.project_id}`
                                      ),
                                      renderSettingsMenu(<ArchiveIcon width={'18px'} height={'18px'} />, 'Archive project...', '/'),
                                  ]
                                : null}
                        </StyledMenu>
                        <Button
                            onClick={() => {
                                handleClose();
                                navigate(`/see-details-project/${projects.project_id}`);
                            }}
                            sx={ProjectCardStyle.buttonProjectName}
                        >
                            {projects.name}
                        </Button>
                    </div>
                    <Box style={ProjectCardStyle.contentRoles}>
                        <Box sx={{ display: 'flex', maxWidth: '800px' }}>
                            <Box sx={{ width: '140px', paddingRight: '16px' }}>
                                <Typography sx={ProjectCardStyle.typographyPersonRole}>Mgr: </Typography>
                                <Typography sx={ProjectCardStyle.typographyPersonRole}>CP: </Typography>
                                <Typography sx={ProjectCardStyle.typographyPersonRole}>Risk: </Typography>
                                <Typography sx={ProjectCardStyle.typographyPersonRole}>Needs disc.: </Typography>
                                <Typography sx={ProjectCardStyle.typographyPersonRole}>Last review: </Typography>
                                <Typography sx={ProjectCardStyle.typographyPersonRole}>Responsibles:</Typography>
                            </Box>
                            <Box style={{ flex: '1' }}>
                                <Tooltip
                                    title={<Typography sx={ProjectCardStyle.tooltipTitle}>{projects.manager_comment}</Typography>}
                                    arrow
                                >
                                    <Typography
                                        sx={ProjectCardStyle.typographyDescription}
                                        style={{
                                            height: '20px',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}
                                    >
                                        {projects.manager_comment?.length > MaxCharactersDescriptionProjectCard
                                            ? `${projects.manager_comment.slice(0, MaxCharactersDescriptionProjectCard)}...`
                                            : projects.manager_comment}
                                    </Typography>
                                </Tooltip>
                                <Tooltip
                                    title={<Typography sx={ProjectCardStyle.tooltipTitle}>{projects.client_partner_comment}</Typography>}
                                    arrow
                                >
                                    <Typography
                                        sx={ProjectCardStyle.typographyDescription}
                                        style={{
                                            height: '20px',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}
                                    >
                                        {projects.client_partner_comment?.length > MaxCharactersDescriptionProjectCard
                                            ? `${projects.client_partner_comment.slice(0, MaxCharactersDescriptionProjectCard)}...`
                                            : projects.client_partner_comment}
                                    </Typography>
                                </Tooltip>
                                <Typography sx={ProjectCardStyle.typographyDescription} component="div">
                                    <div style={{ display: 'flex' }}>
                                        <div
                                            style={{
                                                width: '12px',
                                                height: '12px',
                                                borderRadius: '50%',
                                                backgroundColor: getRiskColorStyle(projects.risk_level).bulletColor,
                                                marginRight: '5px',
                                                marginTop: '3px',
                                            }}
                                        ></div>
                                        {getRiskColorStyle(projects.risk_level).label}
                                    </div>
                                </Typography>
                                <Typography sx={ProjectCardStyle.typographyDescription} component="div">
                                    {projects.needs_attention ? (
                                        <div style={{ display: 'flex' }}>
                                            <div
                                                style={{
                                                    width: '12px',
                                                    height: '12px',
                                                    borderRadius: '50%',
                                                    backgroundColor: RedAlert,
                                                    marginRight: '5px',
                                                    marginTop: '3px',
                                                }}
                                            ></div>
                                            Yes
                                        </div>
                                    ) : (
                                        <div style={{ display: 'flex' }}>
                                            <div
                                                style={{
                                                    width: '12px',
                                                    height: '12px',
                                                    borderRadius: '50%',
                                                    backgroundColor: Green300,
                                                    marginRight: '5px',
                                                    marginTop: '3px',
                                                }}
                                            ></div>
                                            No
                                        </div>
                                    )}
                                </Typography>
                                <Typography sx={ProjectCardStyle.typographyDescription} component="div">
                                    {moment(new Date()).diff(moment(lastDate), 'days') > maxReviewTime ? (
                                        <div style={{ display: 'flex' }}>
                                            <div
                                                style={{
                                                    width: '12px',
                                                    height: '12px',
                                                    borderRadius: '50%',
                                                    backgroundColor: RedAlert,
                                                    marginRight: '5px',
                                                    marginTop: '3px',
                                                }}
                                            ></div>
                                            {moment(new Date()).diff(moment(lastDate), 'days')}d ago
                                        </div>
                                    ) : (
                                        <>{moment(new Date()).diff(moment(lastDate), 'days') || 0}d ago</>
                                    )}
                                </Typography>
                                <Tooltip
                                    title={
                                        <Typography sx={ProjectCardStyle.tooltipTitle} component="div">
                                            <Grid container spacing={1} sx={{ minWidth: '200px', maxWidth: '500px' }}>
                                                <Grid item xs={4}>
                                                    <Typography
                                                        sx={{
                                                            display: 'block',
                                                            textAlign: 'left',
                                                            paddingRight: '3px',
                                                        }}
                                                    >
                                                        <strong>Mgr:</strong>
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            display: 'block',
                                                            textAlign: 'left',
                                                            paddingRight: '3px',
                                                        }}
                                                    >
                                                        <strong>Cp:</strong>
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            display: 'block',
                                                            textAlign: 'left',
                                                            paddingRight: '3px',
                                                        }}
                                                    >
                                                        <strong>DoE:</strong>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography sx={ProjectCardStyle.tooltipTitle}>{managerName}</Typography>
                                                    <Typography>{cp}</Typography>
                                                    <Typography>{doe}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Typography>
                                    }
                                    arrow
                                >
                                    <Typography sx={ProjectCardStyle.typographyDescription}>see</Typography>
                                </Tooltip>
                            </Box>
                        </Box>

                        <Box
                            onClick={() => {
                                handleClose();
                                navigate(`/view-review/${projects.review_id}`);
                            }}
                            sx={ProjectCardStyle.overallScoreBox}
                        >
                            <Typography sx={ProjectCardStyle.overallScore}>{projects.overall_score}</Typography>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
            {isPromptVisible ? (
                <PromptUser
                    title="Confirm Archiveing the Project"
                    actionName="Archive project"
                    action={handleArchiveProject}
                    actionId={projects.project_id}
                    setPromptVisible={setPromptVisible}
                    icon={<ArchiveProjectIcon width={'20px'} height={'20px'} color={White100} />}
                />
            ) : null}
        </>
    );
};

export default ProjectCard;
