import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import React from "react";

export default function ProtectedRoute({ children, sidebarWidth }) {
  const nav = useNavigate();

  useEffect(() => {
    const loggedIn = localStorage.getItem("loggedIn");

    if (loggedIn === "false" || !loggedIn) {
      // navigate to login page
      nav("/login");
    }
  }, [nav]);

  return children && React.cloneElement(children, { sidebarWidth });
}
