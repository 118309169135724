export const UtilsStyles = {
  tableColumnStatus: {
    marginRight: "5px",
    marginTop: "10px",
    width: "16px",
    height: "16px",
  },
  tableColumnStatusBox: {
    display: "flex",
    alignItems: "center",
  },
};
