import React, { useState, useEffect, useCallback, useRef } from 'react';
import WrapperPage from '../../components/WrapperPage';
import { useParams, useNavigation, useNavigate } from 'react-router-dom';
import axiosFetch from '../../api/Axios';
import { NewProjectStyles } from '../NewProject/styles';
import ReviewBox from '../../components/reviewBox/ReviewBox';
import SnackBar from '../../components/SnackBar';
import { Box, Typography, Avatar } from '@mui/material';
import { ProjectCardStyle } from '../../components/projectsCard/ProjectCardStyles';
import ManagerComment from '../../components/reviewBox/ManagerComment';
import styles from '../ProjectReview/styles';
import { formatDate } from '../../utils/dateUtils';
import Status from '../../components/Status';
import GreenButtonED from '../../components/GreenButtonED';
import submitArrow from '../../assets/icons/submitArrow.svg';
import { NeedsAttentionBox } from '../../components/reviewBox/NeedsAttentionBox';
import { RiskLevelBox } from '../../components/reviewBox/RiskLevelBox';
import { renderStatusColumn } from '../../utils/TableUtils';
import CircularProgress from '@mui/material/CircularProgress';
import { Green600 } from '../../consts/colors';
import { useNavBlocker } from '../../utils/history';

const ViewReviewPage = ({ sidebarWidth }) => {
    const { itemId } = useParams();
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [questions, setQuestions] = useState([]);
    const [data, setData] = useState([]);
    const [reviewOwnerID, setReviewOwnerID] = useState([]);
    const [reviewOwner, setReviewOwner] = useState([]);
    const [isFormDirty, _setIsFormDirty] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const isDirtyRef = useRef(isFormDirty);
    function setIsFormDirty(value) {
        _setIsFormDirty(value);
        isDirtyRef.current = value;
    }
    useNavBlocker(isDirtyRef);
    const fetchData = useCallback(async () => {
        try {
            const reviewResponse = await axiosFetch({
                method: 'GET',
                url: process.env.REACT_APP_API_PORT + 'review/' + itemId,
            });

            if (reviewResponse && Object.keys(reviewResponse).length > 0) {
                setData(reviewResponse);
                setReviewOwnerID(reviewResponse.user_id || '');
                setQuestions(reviewResponse.question_answer);

                if (reviewResponse.user_id) {
                    const usersResponse = await axiosFetch({
                        method: 'GET',
                        url: process.env.REACT_APP_API_PORT + `user/${reviewResponse.user_id}`,
                    });
                    setReviewOwner(usersResponse);
                } else {
                    console.log('No valid user ID found in the review response.');
                }
            } else {
                console.log('No project data found.');
            }
        } catch (error) {
            console.error(error);
        }
    }, [itemId]);

    useEffect(() => {
        fetchData();
    }, [itemId, reviewOwnerID, fetchData]);

    const handleNoteChange = (event) => {
        setData({ ...data, manager_comment: event.target.value });
        setIsFormDirty(true);
    };
    const handleClientChange = (event) => {
        setData({ ...data, client_partner_comment: event.target.value });
        setIsFormDirty(true);
    };

    const handleNeedManagementChange = (event) => {
        setData({ ...data, needs_attention: event.target.value });
        setIsFormDirty(true);
    };

    const handleRiskLevelChange = (event) => {
        setData({ ...data, risk_level: event.target.value });
        setIsFormDirty(true);
    };

    const handleSuccessSnackbarClose = () => {
        setSuccess(false);
    };

    const handleSaveClick = async () => {
        const apiPayload = data.question_answer.map((answer) => ({
            responseId: answer.response_id,
            quickNote: answer.quick_note,
        }));

        const dataToSend = {
            status: data.status,
            userId: reviewOwnerID,
            managerComment: data.manager_comment,
            updatedResponses: apiPayload,
            clientPartnerComment: data.client_partner_comment,
            risk_level: data.risk_level,
            needs_attention: data.needs_attention,
        };

        const options = {
            url: process.env.REACT_APP_API_PORT + `review/${itemId}`,
            method: 'put',
            data: dataToSend,
        };

        try {
            setIsSaving(true);
            const responseData = await axiosFetch(options);
            if (responseData) setSuccess(true);
            else setSuccess(false);
            await fetchData();
        } catch (error) {
            setError({
                message: error.message,
                severity: 'error',
            });
        }

        setIsSaving(false);
        setIsFormDirty(false);
    };

    const updateAnswer = (questionId, quickNote, responseId) => {
        const existingAnswerIndex = data.question_answer.findIndex((answer) => answer.question_id === questionId);

        if (existingAnswerIndex !== -1) {
            data.question_answer[existingAnswerIndex].quick_note = quickNote;
            data.question_answer[existingAnswerIndex].response_id = responseId;
        } else {
            data.question_answer.push({
                question_id: questionId,
                quick_note: quickNote,
                response_id: responseId,
            });
        }

        setIsFormDirty(true);
    };

    return (
        <WrapperPage sidebarWidth={sidebarWidth}>
            <React.Fragment>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '36px',
                        justifyContent: 'space-between',
                    }}
                >
                    <div style={{ display: 'flex' }}>
                        <Avatar variant="square" sx={ProjectCardStyle.avatarProject}>
                            <Typography variant="subtitle1" color="inherit" sx={ProjectCardStyle.typographyPr}>
                                {data?.project_name
                                    ?.split(' ')
                                    .map(function (str) {
                                        return str ? str[0].toUpperCase() : '';
                                    })
                                    .slice(0, 2)
                                    .join('')}
                            </Typography>
                        </Avatar>
                        <h1
                            style={{
                                ...NewProjectStyles.textTitle,
                                margin: 0,
                                marginLeft: '10px',
                            }}
                        >
                            {' '}
                            {data.project_name} Review
                        </h1>
                    </div>
                    <div
                        style={{
                            position: 'fixed',
                            top: 110,
                            right: 20,
                        }}
                    >
                        <GreenButtonED
                            iconSrc={submitArrow}
                            disabled={!isFormDirty || isSaving}
                            buttonText="Save"
                            width="206px"
                            height="44px"
                            fontSize={14}
                            lineHeight={18}
                            type="button"
                            onClick={handleSaveClick}
                            style={{ flex: '0 0 auto' }}
                        />
                        {isSaving && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: Green600,
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-60px',
                                }}
                            />
                        )}
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {reviewOwner.first_name && (
                        <>
                            <h1 style={{ ...styles.header, marginRight: '10px' }}>
                                {reviewOwner.first_name} {reviewOwner.last_name}
                            </h1>
                            <h1 style={{ ...styles.header, marginRight: '10px' }}>|</h1>
                        </>
                    )}
                    {data.created_date && (
                        <>
                            <h1 style={{ ...styles.header, marginRight: '10px' }}>{formatDate(data.created_date)}</h1>

                            <h1 style={{ ...styles.header }}>|</h1>
                        </>
                    )}
                    {data.status && <h1 style={{ ...styles.header, marginLeft: '10px' }}>{renderStatusColumn(data.status)}</h1>}
                </div>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        minWidth: '820px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <div id="section-status">
                            <Status
                                data={[
                                    {
                                        label: 'Client Relationship',
                                        value: data['Client Relationship'] || '',
                                    },
                                    { label: 'Delivery', value: data.Delivery || '' },
                                    { label: 'Process', value: data.Process || '' },
                                    { label: 'Team', value: data.Team || '' },
                                    { label: 'Staffing', value: data.Staffing || '' },
                                    {
                                        label: 'Client Business',
                                        value: data['Client Business'] || '',
                                    },
                                    { label: 'Overall', value: data.average || '' },
                                ]}
                                status={data.status}
                            />
                        </div>
                        <div id="section-manager-comment">
                            <ManagerComment title={'Manager Comment'} note={data.manager_comment || ''} onNoteChange={handleNoteChange} />
                            <ManagerComment
                                title={'Client Partner Comment'}
                                note={data.client_partner_comment || ''}
                                onNoteChange={handleClientChange}
                            />
                            <NeedsAttentionBox
                                title={'Needs Management Discussions'}
                                need_attention={data.needs_attention || false}
                                onNeedManagementChange={handleNeedManagementChange}
                            />
                            <RiskLevelBox title={'Risk Level'} colorCode={data.risk_level || 0} onRiskLevelChange={handleRiskLevelChange} />
                        </div>
                        {questions.map((question, index) => {
                            const sectionId = `section-${index + 1}`;
                            const tag = `Q ${index + 1}`;
                            const data = { tag, question };

                            return (
                                <div key={index} id={sectionId}>
                                    <ReviewBox question={data} onUpdateAnswer={updateAnswer} />
                                </div>
                            );
                        })}
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginLeft: '800px',
                                textAlign: 'center',
                                marginTop: '20px',
                            }}
                        ></Box>
                    </Box>
                </Box>
                {error?.message && (
                    <SnackBar open={true} message={error.message} severity={error.severity} handleClose={() => setError(null)} />
                )}
                {success && (
                    <SnackBar
                        open={true}
                        message="Form submitted successfully!"
                        severity="success"
                        handleClose={handleSuccessSnackbarClose}
                    />
                )}
            </React.Fragment>
        </WrapperPage>
    );
};

export default ViewReviewPage;
