import { Typography } from "@mui/material";
import styles from "./styles";
function ManagerComment({ title, note, onNoteChange }) {
  return (
    <div style={styles.customBox}>
      <Typography style={{ ...styles.tag }}>{title}</Typography>
      <Typography style={{ ...styles.question, marginTop: '10px' }}>
        Do you have any comments regarding this project since last review?
      </Typography>
      <textarea
        type="text"
        placeholder="Write your answer here"
        value={note}
        onChange={onNoteChange}
        style={{ ...styles.noteContainer, ...styles.input, border: 'none', width: '100%' }}
      />
    </div>
  );
}

export default ManagerComment;