import React from 'react';
import { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosFetch from '../../api/Axios';
import WrapperPage from '../../components/WrapperPage';
import { Box, Avatar, Typography, Card, CardContent, Button, TextField } from '@mui/material';
import SeeProjectDetailsStyles from './SeeProjectDetailsStyles';
import EditIcon from '../../assets/icons/editIcon.svg';
import EditableField from './EditableField';
import { Plus } from '../../assets/icons';
import { Blue400, Green300, PrimaryBlue, RedAlert, Yellow400 } from '../../consts/colors';
import { CurrentUserContext } from '../../App';
import { getItem, setItem, useLocalStorage } from '../../utils/LocalStorageUtils';
import { EDIT_PERMISSION, MaxCharactersDescriptionProjectDetails, REVIEW_PERMISSION } from '../../consts/constants';
import { formatDate } from '../../utils/dateUtils';
import GreenButton from '../../components/GreenButton';
import moment from 'moment';
import ActionItems from '../../components/actionItems/ActionItems';
import ListDetailsTable from '../../components/projectDetailsTable/projectDetailsTable';

const SeeProjectDetails = ({ sidebarWidth }) => {
    const { itemId } = useParams();
    const [dataChart, setDataChart] = useState(null);
    const [responseData, setResponseData] = useState();
    const [lastReviewId, setLastReviewId] = useState(null);
    const nav = useNavigate();
    const [projectDetails, setProjectDetails] = useState([]);
    const navigate = useNavigate();
    const { currentUser } = useContext(CurrentUserContext);
    const [freeText, setFreeText] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [clientPartnerCommentText, setClientPartnerCommentText] = useState();
    const [managerCommentText, setManagerCommentText] = useState();
    const maxReviewTime = process.env.REACT_APP_REVIEW;
    const [users, setUsers] = useState([]);
    const [paginationModel, setPaginationModel] = useLocalStorage('ProjectDetailsPaginationV2', {
        page: 0,
        pageSize: 100,
    });
    const hasData = dataChart?.length > 0;

    useEffect(() => {
        fetchProjectsDetailsData();
        getReviews();
        fetchUsers();
    }, [itemId]);

    const fetchUsers = async () => {
        axiosFetch({
            method: 'GET',
            url: process.env.REACT_APP_API_PORT + 'users?limit=all',
        }).then((res) => setUsers(res));
    };

    const defaultValues = {
        risk: 'N/A',
        needs_discussion: 'N/A',
        last_review: 'Never',
        chart: 'No Data Available',
        action_items: 'No Action Items Available',
        mgr: 'Needs Review',
        cp: 'Needs Review',
    };
    const fetchProjectsDetailsData = async () => {
        setIsLoading(true);
        try {
            const response = await axiosFetch({
                method: 'GET',
                url: process.env.REACT_APP_API_PORT + 'project/' + itemId,
            });
            if (response && Object.keys(response).length > 0) {
                setProjectDetails(response);
                setFreeText(response.free_comment);
                setManagerCommentText(response.manager_comment);
                setClientPartnerCommentText(response.client_partner_comment);
            } else {
                console.log('No project data found.');
            }
        } catch (error) {
            throw error;
        } finally {
            setIsLoading(false);
        }
    };

    const updateReview = async (updatedReview) =>
        axiosFetch({
            url: process.env.REACT_APP_API_PORT + 'review/' + projectDetails.review_id,
            method: 'put',
            data: updatedReview,
        });

    const getReviews = async () => {
        try {
            const reviewsResponse = await axiosFetch({
                method: 'GET',
                url: `${process.env.REACT_APP_API_PORT}project/${itemId}/reviews`,
            });

            if (!reviewsResponse || reviewsResponse.length === 0) {
                console.warn('No reviews found for the project');
                return;
            }

            const { review_id } = reviewsResponse[0];
            setLastReviewId(review_id);

            const lastReviewResponse = await axiosFetch({
                method: 'GET',
                url: `${process.env.REACT_APP_API_PORT}review/${review_id}`,
            });

            if (lastReviewResponse?.question_answer) {
                setResponseData(lastReviewResponse.question_answer);
            } else {
                console.warn('Last review data is incomplete or missing');
            }

            setDataChart(reviewsResponse);
        } catch (error) {
            console.error('Error fetching reviews:', error.message);
        }
    };

    const handleFieldSave = async (newValue, fieldIdentifier) => {
        updateReview({
            [fieldIdentifier]: newValue,
        })
            .then(() => {
                let key = fieldIdentifier;
                switch (fieldIdentifier) {
                    case 'managerComment':
                        key = 'manager_comment';
                        break;
                    case 'clientPartnerComment':
                        key = 'client_partner_comment';
                        break;
                    default:
                        break;
                }
                setProjectDetails({ ...projectDetails, [key]: newValue });
            })
            .catch((err) => console.log(err));
    };
    const handleFreeTextChange = (event) => {
        setFreeText(event.target.value);
    };

    const updateProject = (columnValue, columnName) => {
        if (columnName === 'gross_margin') {
            columnValue = parseFloat(columnValue).toFixed(2);
        }

        setProjectDetails({ ...projectDetails, [columnName]: columnValue });

        axiosFetch({
            url: process.env.REACT_APP_API_PORT + 'project/' + projectDetails.project_id,
            method: 'put',
            data: {
                [columnName]: columnValue,
                name: projectDetails.name,
                user_id: projectDetails.user_id,
            },
        });
    };

    const saveFreeText = () => {
        axiosFetch({
            url: process.env.REACT_APP_API_PORT + 'project/' + projectDetails.project_id,
            method: 'put',
            data: {
                free_comment: freeText,
                name: projectDetails.name,
                user_id: projectDetails.user_id,
            },
        });
    };

    const renderTitleCard = (title) => {
        return <Typography sx={SeeProjectDetailsStyles.titleDescription}>{title}</Typography>;
    };

    const renderStatusCard = (title, color) => {
        return (
            <Box sx={SeeProjectDetailsStyles.sBox}>
                <Box sx={{ ...SeeProjectDetailsStyles.statusBox, backgroundColor: color }}></Box>
                <Typography sx={SeeProjectDetailsStyles.statusText}>{title}</Typography>
            </Box>
        );
    };

    const renderStatusCardByScore = (status) => {
        if (status === 'Red') {
            return renderStatusCard('Fix Now', 'red');
        } else if (status === 'Yellow') {
            return renderStatusCard('Warning', 'yellow');
        } else if (status === 'Green') {
            return renderStatusCard('On Track', 'green');
        }
    };

    const handleEditClick = () => {
        navigate(`/view-edit/${itemId}`);
    };

    const canEditProject = currentUser?.permissions?.includes(EDIT_PERMISSION);
    const canReviewProject = currentUser?.permissions?.includes(REVIEW_PERMISSION);

    return (
        <WrapperPage sidebarWidth={sidebarWidth}>
            <Box sx={SeeProjectDetailsStyles.headerBox}>
                <Box sx={SeeProjectDetailsStyles.headerLeftBox}>
                    <Avatar variant="square" sx={SeeProjectDetailsStyles.avatarProject}>
                        <Typography variant="subtitle1" color="inherit" sx={SeeProjectDetailsStyles.typographyPr}>
                            {projectDetails?.name
                                ?.split(' ')
                                .map(function (str) {
                                    return str ? str[0].toUpperCase() : '';
                                })
                                .slice(0, 2)
                                .join('')}
                        </Typography>
                    </Avatar>
                    <Typography sx={SeeProjectDetailsStyles.projectName}>{projectDetails.name} Details</Typography>
                </Box>
                <div>
                    {canReviewProject && (
                        <GreenButton
                            iconSrc={<Plus width={'20px'} height={'20px'} />}
                            buttonText="Review Project"
                            width="212px"
                            height="44px"
                            fontSize={14}
                            lineHeight={18}
                            backgroundColor={Blue400}
                            margin={'0px 15px 0px 0px'}
                            backgroundColorHover={PrimaryBlue}
                            onClick={() => {
                                nav(`/review-project/${itemId}`);
                            }}
                        />
                    )}
                    {canEditProject && (
                        <Button
                            onClick={handleEditClick}
                            startIcon={
                                <img
                                    src={EditIcon}
                                    alt="Icon"
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                    }}
                                />
                            }
                            sx={SeeProjectDetailsStyles.button}
                        >
                            {'Edit Project'}
                        </Button>
                    )}
                </div>
            </Box>
            <Box sx={SeeProjectDetailsStyles.projectDetailsBox}>
                <Typography sx={SeeProjectDetailsStyles.details}>
                    {projectDetails.client_name} | {formatDate(projectDetails.start_date)} - {formatDate(projectDetails.end_date)} |{' '}
                    <b>Description </b>: {projectDetails.business_description} | <b>Manager</b>:{' '}
                    {projectDetails.ro_first_name + ' ' + projectDetails.ro_last_name} |<b>CP</b>:{' '}
                    {projectDetails.cp_first_name + ' ' + projectDetails.cp_last_name} | <b>DoE</b>:{' '}
                    {projectDetails.doe_first_name + ' ' + projectDetails.doe_last_name}
                </Typography>
            </Box>
            <Box>
                <Box sx={SeeProjectDetailsStyles.main}>
                    <Box sx={SeeProjectDetailsStyles.cardWidth}>
                        <Card elevation={3} sx={SeeProjectDetailsStyles.status}>
                            <CardContent>
                                <Box sx={SeeProjectDetailsStyles.titleBox}>
                                    {renderTitleCard('Project Status')} {renderStatusCardByScore(projectDetails.status)}
                                </Box>
                                <Box sx={SeeProjectDetailsStyles.inlineStatusFields}>
                                    <Box sx={SeeProjectDetailsStyles.projectStatusInlineBoxes}>
                                        <Typography sx={SeeProjectDetailsStyles.mainStatusField}>Risk:</Typography>
                                        {hasData ? (
                                            <EditableField
                                                value={projectDetails.risk_level}
                                                onSave={(newValue) => handleFieldSave(newValue, 'risk_level')}
                                                fieldType="select"
                                                options={[
                                                    { value: 0, label: 'Green', color: Green300 },
                                                    { value: 1, label: 'Yellow', color: Yellow400 },
                                                    { value: 2, label: 'Red', color: RedAlert },
                                                ]}
                                                canEditProject={canEditProject}
                                                canReviewProject={canReviewProject}
                                                width="5"
                                            />
                                        ) : (
                                            <Typography sx={SeeProjectDetailsStyles.projectStatusDefaultText}>
                                                {defaultValues.risk}
                                            </Typography>
                                        )}
                                    </Box>
                                    <Box sx={SeeProjectDetailsStyles.projectStatusInlineBoxes}>
                                        <Typography sx={SeeProjectDetailsStyles.mainStatusField}>Needs discussion:</Typography>
                                        {hasData ? (
                                            <EditableField
                                                value={projectDetails.needs_attention ? 1 : 0}
                                                onSave={(newValue) => handleFieldSave(!!newValue, 'needs_attention')}
                                                fieldType="select"
                                                options={[
                                                    { value: 1, label: 'Yes', color: RedAlert },
                                                    { value: 0, label: 'No', color: Green300 },
                                                ]}
                                                canEditProject={canEditProject}
                                                canReviewProject={canReviewProject}
                                                width="5"
                                            />
                                        ) : (
                                            <Typography sx={SeeProjectDetailsStyles.projectStatusDefaultText}>
                                                {defaultValues.needs_discussion}
                                            </Typography>
                                        )}
                                    </Box>
                                    <Box sx={SeeProjectDetailsStyles.projectStatusInlineBoxes}>
                                        <Typography sx={SeeProjectDetailsStyles.mainStatusField}>Last review:</Typography>
                                        {hasData ? (
                                            <Typography sx={SeeProjectDetailsStyles.statusFields}>
                                                {moment(new Date()).diff(moment(projectDetails.created_date), 'days') > maxReviewTime ? (
                                                    <div style={{ display: 'flex' }}>
                                                        <div
                                                            style={{
                                                                width: '12px',
                                                                height: '12px',
                                                                borderRadius: '50%',
                                                                backgroundColor: 'red',
                                                                marginRight: '5px',
                                                                marginTop: '3px',
                                                            }}
                                                        ></div>
                                                        {moment(new Date()).diff(moment(projectDetails.created_date), 'days')}d ago
                                                    </div>
                                                ) : (
                                                    <>{moment(new Date()).diff(moment(projectDetails.created_date), 'days') || 0}d ago</>
                                                )}
                                            </Typography>
                                        ) : (
                                            <Typography sx={SeeProjectDetailsStyles.projectStatusDefaultText}>
                                                {defaultValues.last_review}
                                            </Typography>
                                        )}
                                    </Box>
                                    {currentUser?.role?.toLowerCase() === 'admin' ? (
                                        <Box sx={SeeProjectDetailsStyles.projectStatusInlineBoxes}>
                                            <Typography sx={SeeProjectDetailsStyles.mainStatusField}>Gross Margin:</Typography>
                                            <EditableField
                                                value={projectDetails.gross_margin || 0}
                                                onSave={(newValue) => updateProject(newValue, 'gross_margin')}
                                                canEditProject={canEditProject}
                                                canReviewProject={canReviewProject}
                                                width="5"
                                            />
                                        </Box>
                                    ) : null}
                                </Box>

                                <Box sx={SeeProjectDetailsStyles.titleBox}>
                                    <Typography
                                        sx={{
                                            ...SeeProjectDetailsStyles.mainStatusField,
                                            marginLeft: '25px',
                                        }}
                                    >
                                        Mgr:{' '}
                                    </Typography>
                                </Box>
                                <Box
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': {
                                            m: 1,
                                            width: '95%',
                                        },
                                        '& .MuiFilledInput-root': {
                                            paddingTop: '2px',
                                        },
                                        marginLeft: '25px',
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <TextField
                                        id="filled-required"
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                resize: 'auto',
                                            },
                                        }}
                                        multiline
                                        color="success"
                                        variant="filled"
                                        value={managerCommentText || ''}
                                        disabled={!hasData}
                                        onChange={(newValue) => setManagerCommentText(newValue.target.value)}
                                        onBlur={(newValue) => handleFieldSave(newValue.target.value, 'managerComment')}
                                        InputProps={{
                                            readOnly: !canEditProject && !canReviewProject,
                                        }}
                                    />
                                </Box>

                                <Box sx={SeeProjectDetailsStyles.titleBox}>
                                    <Typography
                                        sx={{
                                            ...SeeProjectDetailsStyles.mainStatusField,
                                            marginLeft: '25px',
                                        }}
                                    >
                                        CP:{' '}
                                    </Typography>
                                </Box>
                                <Box
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': {
                                            m: 1,
                                            width: '95%',
                                        },
                                        '& .MuiFilledInput-root': {
                                            paddingTop: '2px',
                                        },
                                        marginLeft: '25px',
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <TextField
                                        id="filled-required"
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                resize: 'auto',
                                            },
                                        }}
                                        value={clientPartnerCommentText || ''}
                                        disabled={!hasData}
                                        multiline
                                        color="success"
                                        variant="filled"
                                        onChange={(event) => setClientPartnerCommentText(event.target.value)}
                                        onBlur={(event) => handleFieldSave(event.target.value, 'clientPartnerComment')}
                                        InputProps={{
                                            readOnly: !canEditProject && !canReviewProject,
                                        }}
                                        maxLength={MaxCharactersDescriptionProjectDetails}
                                    />
                                </Box>
                                <Box sx={SeeProjectDetailsStyles.titleBox}>
                                    <Typography
                                        sx={{
                                            ...SeeProjectDetailsStyles.mainStatusField,
                                            marginLeft: '25px',
                                        }}
                                    >
                                        Comment:{' '}
                                    </Typography>
                                </Box>
                                <Box
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': {
                                            m: 1,
                                            width: '95%',
                                            // height: "500px",
                                        },
                                        '& .MuiFilledInput-root': {
                                            paddingTop: '2px',
                                        },
                                        marginLeft: '25px',
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <TextField
                                        id="filled-required"
                                        // rows={2}
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                resize: 'auto',
                                            },
                                        }}
                                        value={freeText || ''}
                                        multiline
                                        color="success"
                                        variant="filled"
                                        onChange={handleFreeTextChange}
                                        onBlur={saveFreeText}
                                        InputProps={{
                                            readOnly: !canEditProject && !canReviewProject,
                                        }}
                                    />
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                    <Box sx={SeeProjectDetailsStyles.cardWidth}>
                        <Card elevation={3} sx={SeeProjectDetailsStyles.rhistory}>
                            <ActionItems users={users} projectId={itemId} />
                        </Card>
                    </Box>
                </Box>
                <Box sx={SeeProjectDetailsStyles.main}>
                    <Box sx={SeeProjectDetailsStyles.lastReviewWidth}>
                        <Card elevation={3} sx={SeeProjectDetailsStyles.lastReviewCard}>
                            <CardContent>
                                <Box
                                    sx={SeeProjectDetailsStyles.titleBox}
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    {renderTitleCard('Last Review')}
                                    {responseData && responseData.length > 0 && (
                                        <Box>
                                            <Button
                                                onClick={() => navigate(`/view-review/${lastReviewId}`)}
                                                startIcon={
                                                    <img
                                                        src={EditIcon}
                                                        alt="Icon"
                                                        style={{
                                                            width: '20px',
                                                            height: '20px',
                                                        }}
                                                    />
                                                }
                                                sx={SeeProjectDetailsStyles.button}
                                                style={{ marginRight: '10px' }}
                                            >
                                                Edit Last Review
                                            </Button>
                                            <Button
                                                onClick={() => navigate(`/review-history/${itemId}`)}
                                                sx={SeeProjectDetailsStyles.button}
                                            >
                                                Review History
                                            </Button>
                                        </Box>
                                    )}
                                </Box>
                                {responseData && responseData.length > 0 ? (
                                    <ListDetailsTable
                                        data={responseData}
                                        setData={setResponseData}
                                        paginationModel={paginationModel}
                                        setPaginationModel={setPaginationModel}
                                        isLoading={isLoading}
                                        totalItems={responseData.length}
                                    />
                                ) : (
                                    <Typography sx={SeeProjectDetailsStyles.titleDescription}>No reviews</Typography>
                                )}
                            </CardContent>
                        </Card>
                    </Box>
                </Box>
            </Box>
        </WrapperPage>
    );
};

export default SeeProjectDetails;
