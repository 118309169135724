import { Green600, White100 } from "../consts/colors";

export const FormsStyles= {
    field: {
      backgroundColor: White100,
        '& .MuiOutlinedInput-root': {
          '&:hover fieldset': {
            borderColor: Green600,
          },
          '&.Mui-focused fieldset': {
            borderColor: Green600, 
          },
        },
        '& label.Mui-focused': {
          color: Green600,
        },
      },
};

