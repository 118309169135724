import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const SnackBar = ({ open, message, severity, handleClose }) => {
  const handleSnackbarClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }

    if (handleClose) {
      handleClose();
    }
  };

  return (
    <Snackbar
      open={open} // Use the prop directly instead of using internal state
      onClose={handleSnackbarClose}
      autoHideDuration={5000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} // Bottom-center position
    >
      <Alert severity={severity} onClose={handleSnackbarClose}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackBar;
