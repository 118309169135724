import {
  Black100,
  Blue100,
  Blue400,
  Blue800,
  White100,
  White400,
} from "../../consts/colors";
const styles = {
  customBox: {
    marginTop: "15px",
    marginLeft: "auto",
    width: "800px",
    padding: "20px",
    borderRadius: "16px",
    display: "flex",
    flexDirection: "column",
    backgroundColor: White100,
  },
  tag: {
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "23px",
    letterSpacing: "-0.01em",
    textAlign: "left",
    color: Black100,
    margin: 0,
  },
  addNote: {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "18px",
    letterSpacing: "-0.005em",
    textAlign: "left",
    color: Blue100,
  },
  question: {
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "23px",
    letterSpacing: "-0.01em",
    textAlign: "left",
    color: Blue400,
    margin: 0,
  },
  removeNote: {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "18px",
    letterSpacing: "-0.005em",
    textAlign: "left",
    color: Blue800,
  },
  noteContainer: {
    marginTop: "20px",
    minHeight: "20px",
    backgroundColor: White400,
    borderRadius: "8px",
    padding: "10px",
  },
  input: {
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "-0.01em",
    textAlign: "left",
    color: Black100,
    padding: "10px",
    borderRadius: "8px",
  },
  placeholder: {
    position: "absolute",
    top: "0",
    left: "8px",
    pointerEvents: "none",
    color: "#999",
    transition: "transform 0.3s ease-out",
    transform: "translateY(12px)",
  },
  valueBoxStyles: {
    width: "60px",
    height: "34px",
    padding: "8px",
    borderRadius: "8px",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "18px",
    letterSpacing: "-0.005em",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: Blue800,
    backgroundColor: White400,
  },
  labelStyles: {
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "23px",
    letterSpacing: "-0.01em",
    textAlign: "left",
    justifyContent: "flex-start",
    color: Blue400,
    marginLeft: "10px",
  },
  scoretext: {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "18px",
    letterSpacing: "-0.005em",
    textAlign: "left",
    color: "Blue400",
  },

  overallValueStyles: {
    width: "60px",
    height: "34px",
    padding: "8px",
    borderRadius: "8px",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "18px",
    letterSpacing: "-0.005em",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default styles;
