import React from "react";
import Avatar from "@mui/material/Avatar";
import GoBackButton from "../GoBackButton";
import { useLocation } from "react-router-dom";

const Header = ({ profilePicture, surname, title, icon }) => {
    const location = useLocation();
    const excludedRoutes = [
        "/",
        "/dashboard",
        "/projects",
        "/users",
        "/actionItems",
        "/questions",
        "/settings",
        "/archived-projects",
        "/logout",
    ];

    const showGoBackButton = !excludedRoutes.includes(location.pathname);
    const showTitle = !showGoBackButton;
    const showIcon = !showGoBackButton;

    return (
        <header
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginRight: "36px",
            }}
        >
            <div style={{ display: "flex", alignItems: "center" }}>
                {showGoBackButton && <GoBackButton />}
                {showIcon && (
                    <div style={{ marginLeft: "15px", marginTop: "9px" }}>{icon}</div>
                )}
                {showTitle && (
                    <div
                        style={{
                            fontFamily: "Poppins, sans-serif",
                            fontSize: "36px",
                            fontWeight: 600,
                            lineHeight: "43px",
                            letterSpacing: "-0.01em",
                            textAlign: "left",
                            color: "Blue800",
                            marginLeft: "16px",
                        }}
                    >
                        {title}
                    </div>
                )}
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
                <Avatar
                    alt="User Avatar"
                    src={profilePicture}
                    style={{ width: "36px", height: "36px", marginRight: "16px" }}
                />

                <div
                    style={{
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "18px",
                        fontWeight: 400,
                        lineHeight: "23px",
                        letterSpacing: "-0.01em",
                        textAlign: "left",
                        color: "Blue800",
                    }}
                >
                    {surname}
                </div>
            </div>
        </header>
    );
};

export default Header;
