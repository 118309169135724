import React, { useState, useEffect, useRef } from 'react';
import styles from './styles';
import AddIcon from '../../assets/icons/add.svg';
import RemoveIcon from '../../assets/icons/remove.svg';
import { IconButton, Typography } from '@mui/material';
import { MenuItem } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { FormsStyles } from '../../utils/FormsStyles';
import axiosFetch from '../../api/Axios';

function ReviewBox({ question, onUpdateAnswer }) {
    const inputRef = useRef();
    const [showNote, setShowNote] = useState(question.question.quick_note ? true : false);
    const [note, setNote] = useState(question.question.quick_note || '');
    const [responseId, setResponse] = useState(question.question.response_id || 0);
    const [options, setOptions] = useState([]);
    const id = question.question.question_id;

    const handleAddNoteClick = () => {
        setShowNote(!showNote);
    };

    const handleNoteChange = (event) => {
        setNote(event.target.value);
    };

    const handleBlur = (event) => {
        event.preventDefault();
        onUpdateAnswer(question.question.question_id, note, responseId);
    };

    const handleRemoveNoteClick = () => {
        setShowNote(false);
        setNote('');
    };

    const handleResponseChange = (event) => {
        setResponse(event.target.value);
        onUpdateAnswer(question.question.question_id, note, event.target.value);

        const errorTextDiv = document.querySelector('.error-text');
        if (errorTextDiv) {
            errorTextDiv.remove();
        }
    };

    useEffect(() => {
        async function fetchResponses() {
            if (id) {
                try {
                    const response = await axiosFetch({
                        method: 'GET',
                        url: process.env.REACT_APP_API_PORT + 'question/' + id + '/responses',
                    });
                    setOptions(response);
                } catch (error) {
                    throw error;
                }
            }
        }
        fetchResponses();
    }, [id]);

    return (
        <div style={styles.customBox}>
            <div style={styles.tag}>{question.tag}</div>
            <div style={{ ...styles.question, marginTop: '10px' }}>{question.question.value || question.question.question}</div>
            <FormControl fullWidth sx={{ mb: 1, ...FormsStyles.field, marginTop: '10px' }}>
                <InputLabel id="review-owner-label">Pick your response</InputLabel>
                <Select
                    labelId="select-response"
                    id="response-select"
                    value={responseId}
                    onChange={handleResponseChange}
                    label="Pick your response"
                >
                    {options.map((option) => (
                        <MenuItem key={option.response_id} value={option.response_id}>
                            {option.value_response}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <div>
                <IconButton
                    onClick={showNote ? handleRemoveNoteClick : handleAddNoteClick}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        minWidth: '136px',
                    }}
                >
                    {showNote ? (
                        <>
                            <img src={RemoveIcon} alt="Remove note" height={20} width={20} />
                            <span style={{ ...styles.removeNote, marginLeft: '8px' }}>Remove quick note</span>
                        </>
                    ) : (
                        <>
                            <img src={AddIcon} alt="Add note" height={20} width={20} />
                            <span style={{ ...styles.addNote, marginLeft: '8px' }}>Add quick note</span>
                        </>
                    )}
                </IconButton>
                {showNote && (
                    <div>
                        <Typography style={{ ...styles.tag, marginTop: '15px' }}>Quick Note</Typography>
                        <Typography style={{ ...styles.question, marginTop: '10px' }}>
                            Do you have any comments regarding this question?
                        </Typography>
                        <textarea
                            type="text"
                            placeholder="Write your answer here"
                            ref={inputRef}
                            value={note}
                            onChange={handleNoteChange}
                            onBlur={handleBlur}
                            style={{
                                ...styles.noteContainer,
                                ...styles.input,
                                border: 'none',
                                width: '100%',
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

export default ReviewBox;
