import React, { useState, useEffect } from "react";
import WrapperPage from "../../components/WrapperPage";
import { TextField, MenuItem } from "@mui/material";
import axiosFetch from "../../api/Axios";
import { Plus } from "../../assets/icons";
import GreenButton from "../../components/GreenButton";
import SnackBar from "../../components/SnackBar";
import { NewProjectStyles } from "../NewProject/styles";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Green600, PrimaryGreen } from "../../consts/colors";
import Typography from "@mui/material/Typography";
import { FormsStyles } from "../../utils/FormsStyles";
import LoadingComponent from "../../components/LoadingComponent";

function NewUserPage({ sidebarWidth }) {
  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [directManager, setDirectManager] = useState("");
  const [notification, setNotification] = useState(true);
  const [error, setError] = useState(null);
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [success, setSuccess] = useState(false);
  const [options, setOptions] = useState([]);
  const [isLoadingManagerData, setIsLoadingManagerData] = useState(false);

  useEffect(() => {
    setIsFormComplete(
      firstName !== "" &&
        secondName !== "" &&
        email !== "" &&
        role !== "" &&
        directManager !== ""
    );
  }, [firstName, secondName, email, role, directManager, notification]);
  useEffect(() => {
    async function fetchManagerData() {
      try {
        setIsLoadingManagerData(true);
        const response = await axiosFetch({
          method: "GET",
          url: process.env.REACT_APP_API_PORT + "users?user_role=Manager",
        });
        setOptions(response);
      } catch (error) {
        console.log("Error: ", error);
        setError({
          message: error.message,
          severity: "error",
        });
      } finally {
        setIsLoadingManagerData(false);
      }
    }
    fetchManagerData();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const dataToSend = {
      email: email,
      first_name: firstName,
      last_name: secondName,
      user_role: role,
      notification_active: notification,
      manager_key: directManager,
      deactivate_user: true,
    };

    const options = {
      url: process.env.REACT_APP_API_PORT + "user",
      method: "POST",
      data: dataToSend,
    };
    try {
      const responseData = await axiosFetch(options);
      if (responseData) setSuccess(true);
    } catch (error) {
      setError({
        message: error.message,
        severity: "error",
      });
    }
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };
  const handleSecondNameChange = (event) => {
    setSecondName(event.target.value);
  };
  const handleEmailChange = (event) => {
    const inputEmail = event.target.value;
    setEmail(inputEmail);
  };

  const validateEmail = () => {
    const emailPattern = /@domain\.ceva$/i;
    if (emailPattern.test(email)) {
      setError(null);
      setSuccess(false);
    } else {
      setError({
        message: "The email should belong to the domain.ceva domain!",
        severity: "error",
      });
      setSuccess(false);
    }
  };

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };
  const handleDirectManagerChange = (event) => {
    setDirectManager(event.target.value);
  };
  const handleNotificationChange = (event) => {
    setNotification(event.target.checked);
  };

  const handleSuccessSnackbarClose = () => {
    setSuccess(false);
    window.location.reload();
  };

  return (
    <WrapperPage sidebarWidth={sidebarWidth}>
      {isLoadingManagerData ? (
        <LoadingComponent />
      ) : (
        <React.Fragment>
          <h1 style={{ ...NewProjectStyles.textTitle }}>Create new user</h1>

          <form onSubmit={handleSubmit}>
            <div style={{ marginBottom: "20px" }}>
              <span style={{ ...NewProjectStyles.numbers }}>1</span>
              <span style={{ ...NewProjectStyles.text }}>First Name</span>
            </div>
            <TextField
              type="text"
              variant="outlined"
              color="secondary"
              label="Type user's first name"
              onChange={handleFirstNameChange}
              value={firstName}
              fullWidth
              required
              sx={{ mb: 4, marginLeft: "20px", ...FormsStyles.field }}
            />

            <div style={{ marginBottom: "20px" }}>
              <span style={{ ...NewProjectStyles.numbers }}>2</span>
              <span style={{ ...NewProjectStyles.text }}>Last Name</span>
            </div>
            <TextField
              type="text"
              variant="outlined"
              color="secondary"
              label="Type user's last name"
              onChange={handleSecondNameChange}
              value={secondName}
              fullWidth
              required
              sx={{ mb: 4, marginLeft: "20px", ...FormsStyles.field }}
            />

            <div style={{ marginBottom: "20px" }}>
              <span style={{ ...NewProjectStyles.numbers }}>3</span>
              <span style={{ ...NewProjectStyles.text }}>Email</span>
            </div>
            <TextField
              type="text"
              variant="outlined"
              color="secondary"
              label="Type user's email"
              onChange={handleEmailChange}
              onBlur={validateEmail}
              value={email}
              fullWidth
              required
              sx={{ mb: 4, marginLeft: "20px", ...FormsStyles.field }}
            />

            <div style={{ marginBottom: "20px" }}>
              <span style={{ ...NewProjectStyles.numbers }}>4</span>
              <span style={{ ...NewProjectStyles.text }}>Permissions</span>
            </div>
            <FormControl
              sx={{
                width: "100%",
                mb: 4,
                marginLeft: "20px",
                ...FormsStyles.field,
              }}
            >
              <InputLabel id="role-label">Pick Permissions</InputLabel>
              <Select
                labelId="role-label"
                id="role-select"
                value={role}
                onChange={handleRoleChange}
                label="Pick Role"
              >
                <MenuItem value={"Manager"}>Manager</MenuItem>
                <MenuItem value={"Admin"}>Admin</MenuItem>
                <MenuItem value={"Viewer"}>Viewer</MenuItem>
              </Select>
            </FormControl>

            <div style={{ marginBottom: "20px" }}>
              <span style={{ ...NewProjectStyles.numbers }}>5</span>
              <span style={{ ...NewProjectStyles.text }}>Manager</span>
            </div>
            <FormControl
              sx={{
                width: "100%",
                mb: 4,
                marginLeft: "20px",
                ...FormsStyles.field,
              }}
            >
              <InputLabel id="manager-label">Pick Manager</InputLabel>
              <Select
                labelId="manager-label"
                id="manager-select"
                value={directManager}
                onChange={handleDirectManagerChange}
                label="Pick Manager"
              >
                {options.map((option) => (
                  <MenuItem key={option.user_id} value={option.user_id}>
                    {option.first_name} {option.last_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <div style={{ marginBottom: "20px" }}>
              <span style={{ ...NewProjectStyles.numbers }}>6</span>
              <span style={{ ...NewProjectStyles.text }}>
                Notifications Status
              </span>
            </div>
            <FormControlLabel
              sx={{ width: "100%", mb: 4, marginLeft: "20px" }}
              control={
                <Checkbox
                  sx={{
                    "&.Mui-checked": {
                      color: Green600,
                    },
                  }}
                  checked={notification}
                  onChange={handleNotificationChange}
                />
              }
              label={
                <Typography
                  variant="body1"
                  style={{ ...NewProjectStyles.text }}
                >
                  {notification ? "Enabled" : "Disabled"}
                </Typography>
              }
            />
            {/*               
              <div style={{ marginBottom: '20px' }}>
                <span style={{ ...NewProjectStyles.numbers }}>7</span>
                <span style={{ ...NewProjectStyles.text }}>Deactivate user</span>
              </div>
              <FormControlLabel sx={{ width: '100%', mb: 4 , marginLeft: '20px'}}
              control={
                <Checkbox
                  sx={{
                    '&.Mui-checked': {
                      color: Green600
                    },
                  }}
                  checked={deactivate}
                  onChange={handleDeactivateChange}
                  
                />
              }
              label={
                <Typography variant="body1" style={{...NewProjectStyles.text}}>
                  {deactivate ? 'User deactivated' : 'User activated'}
                </Typography>
              }
                  /> */}

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "30px",
              }}
            >
              <GreenButton
                iconSrc={<Plus width={"20px"} height={"20px"} />}
                buttonText="Create Now"
                width="322px"
                height="44px"
                fontSize={14}
                lineHeight={18}
                type="submit"
                onClick={handleSubmit}
                backgroundColor={Green600}
                backgroundColorHover={PrimaryGreen}
                disabled={!isFormComplete}
              />
            </div>
          </form>

          {error?.message && (
            <SnackBar
              open={true}
              message={error.message}
              severity={error.severity}
              handleClose={() => setError(null)}
            />
          )}

          {success && (
            <SnackBar
              open={true}
              message="Form submitted successfully!"
              severity="success"
              handleClose={handleSuccessSnackbarClose}
            />
          )}
        </React.Fragment>
      )}
    </WrapperPage>
  );
}

export default NewUserPage;
