import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import axiosFetch from '../../api/Axios';
import GreenButton from '../../components/GreenButton';
import SnackBar from '../../components/SnackBar';
import WrapperPage from '../../components/WrapperPage';
import { Green600, PrimaryGreen } from '../../consts/colors';
import { FormsStyles } from '../../utils/FormsStyles';

function SettingsPage({ sidebarWidth }) {
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosFetch({
                    method: 'GET',
                    url: process.env.REACT_APP_API_PORT + 'settings',
                });
                return response;
            } catch (error) {
                console.log('Error: ', error);
                throw error;
            }
        };
        fetchData().then((response) => {
            setSettings(response);
        });
    }, []);

    const updateData = async () => {
        try {
            const response = await axiosFetch({
                method: 'PUT',
                url: process.env.REACT_APP_API_PORT + 'settings',
                data: { project_fill_slack_notification_days: settings.project_fill_slack_notification_days },
            });
            setSuccess(true);
            return response;
        } catch (error) {
            console.log('Error: ', error);
            throw error;
        }
    };

    const [settings, setSettings] = useState({});
    const [success, setSuccess] = useState(false);

    const handleSuccessSnackbarClose = () => {
        setSuccess(false);
    };

    const changeSlackNotificationDays = (e) => {
        let value = e.target.value;

        value = value.replace(/(?!^-)[^\d]/g, '');

        let numericValue = parseInt(value);

        if (numericValue <= 0) {
            numericValue = 0;
        }
        setSettings({ project_fill_slack_notification_days: numericValue });
    };

    return (
        <WrapperPage sidebarWidth={sidebarWidth}>
            <h1>Admin Settings</h1>
            <form>
                <TextField
                    id="outlined-number"
                    label="Project fill slack notification days"
                    variant="outlined"
                    color="secondary"
                    type="number"
                    value={settings.project_fill_slack_notification_days}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => {
                        changeSlackNotificationDays(e);
                    }}
                    required
                    sx={{ mb: 4, width: '250px', ...FormsStyles.field }}
                />
            </form>
            <GreenButton
                buttonText="Save changes"
                fontSize={14}
                lineHeight={18}
                type="submit"
                onClick={updateData}
                backgroundColor={Green600}
                backgroundColorHover={PrimaryGreen}
            />
            {success && (
                <SnackBar open={true} message="Settings saved successfully!" severity="success" handleClose={handleSuccessSnackbarClose} />
            )}
        </WrapperPage>
    );
}
export default SettingsPage;
