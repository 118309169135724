import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, IconButton } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axiosFetch from '../api/Axios';
import { ArchiveProjectIcon, SeeProjectDetailsIcon, ViewReviewHistoryIcon } from '../assets/icons';
import PromptUser from '../components/promt/PromptUser';
import { White100 } from '../consts/colors';
import { useLocalStorage } from '../utils/LocalStorageUtils';
import { ListUserStyles } from './listUsers/styles';
import SnackBar from './SnackBar';
import CustomTable from './table/Table';

const renderDetailsColumn = (project) => (
    <Link to={`/see-details-project/${project.project_id}`} style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}>
        <IconButton>
            <SeeProjectDetailsIcon alt="Details" height={16} width={16} />
            <span style={{ ...ListUserStyles.bodyText, marginLeft: '8px' }}>View Details</span>
        </IconButton>
    </Link>
);

const renderReviewsColumn = (project) => (
    <Link to={`/review-history/${project.project_id}`} style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}>
        <IconButton>
            <ViewReviewHistoryIcon alt="Reviews" height={16} width={16} />
            <span style={{ ...ListUserStyles.bodyText, marginLeft: '8px' }}>View Reviews</span>
        </IconButton>
    </Link>
);

const RenderManagerColumn = ({ user_id }) => {
    const [managerName, setManagerName] = useState('');

    useEffect(() => {
        async function fetchManagerData() {
            try {
                const managerResponse = await axiosFetch({
                    method: 'GET',
                    url: process.env.REACT_APP_API_PORT + `user/${user_id}`,
                });

                const managerFullName = managerResponse.first_name + ' ' + managerResponse.last_name;
                setManagerName(managerFullName);
            } catch (error) {
                console.error('Error fetching manager data:', error);
            }
        }
        fetchManagerData();
    }, [user_id]);

    return <p>{managerName}</p>;
};

const ActionsColumn = ({ project, handleUnarchiveProject }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton aria-label="more" id="long-button" onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                sx={{
                    '& .MuiPaper-root': {
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12)',
                    },
                }}
                anchorEl={anchorEl}
                onClose={handleClose}
                open={open}
            >
                <MenuItem key={'unarchive'} onClick={() => handleUnarchiveProject(project)}>
                    Unarchive Project
                </MenuItem>
            </Menu>
        </div>
    );
};

export default function ArchivedProjectsList({ data, setData }) {
    const [error, setError] = useState(null);
    const [paginationModel, setPaginationModel] = useLocalStorage('archivedProjectsPagination', {
        pageSize: 100,
        page: 0,
    });

    const [success, setSuccess] = useState(false);
    const [confirmUnarchive, setConfirmUnarchive] = React.useState(false);
    const [restoreProjectId, setRestoreProjectId] = React.useState(null);

    const handleSuccessSnackbarClose = () => {
        setSuccess(false);
    };

    const handleUnarchiveProject = (project) => {
        setConfirmUnarchive(true);
        setRestoreProjectId(project.project_id);
    };

    const headers = [
        { label: 'Title', key: 'title', width: '30%' },
        { label: 'Manager', key: 'review_owner', width: '25%' },
        { label: 'Details', key: 'details', width: '20%' },
        { label: 'Reviews', key: 'reviews', width: '15%' },
        { label: 'Actions', key: 'actions', width: '10%' },
    ];

    const rows = data.map((project) => ({
        title: project.name,
        review_owner: <RenderManagerColumn user_id={project.user_id} />,
        details: renderDetailsColumn(project),
        reviews: renderReviewsColumn(project),
        actions: <ActionsColumn project={project} handleUnarchiveProject={handleUnarchiveProject} />,
    }));

    const handleUnarchiveProjectApi = async (projectId) => {
        const updatedProject = {
            is_archived: false,
        };

        const options = {
            url: process.env.REACT_APP_API_PORT + 'project/' + projectId,
            method: 'put',
            data: updatedProject,
        };

        try {
            const responseData = await axiosFetch(options);
            if (responseData) {
                setSuccess(true);
                setData((prevData) => prevData.filter((p) => p.project_id !== projectId));
            } else setSuccess(false);
        } catch (error) {
            setError({
                message: error.message,
                severity: 'error',
            });
        }
    };

    return (
        <>
            <Box width="100%" marginTop="20px">
                <CustomTable
                    paginationModel={paginationModel}
                    onPaginationModelChanged={setPaginationModel}
                    headers={headers}
                    rows={rows}
                    isMarginTopRequired={true}
                    goToReviewPageNeeded={false}
                />
                {error?.message && (
                    <SnackBar open={true} message={error.message} severity={error.severity} handleClose={() => setError(null)} />
                )}
            </Box>

            {confirmUnarchive && (
                <PromptUser
                    title="Confirm Unarchiving the Project"
                    actionName="Unarchive project"
                    action={handleUnarchiveProjectApi}
                    actionId={restoreProjectId}
                    setPromptVisible={setConfirmUnarchive}
                    icon={<ArchiveProjectIcon width={'20px'} height={'20px'} color={White100} />}
                />
            )}

            {success && (
                <SnackBar open={true} message="Project updated successfully!" severity="success" handleClose={handleSuccessSnackbarClose} />
            )}
        </>
    );
}
