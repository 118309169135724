import { createTheme } from '@mui/material';
import { Blue800 } from '../../consts/colors';

export const autocompleteFilterStyles = createTheme({
    components: {
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    minWidth: '250px',
                    borderRadius: '8px',
                    fontFamily: 'Poppins, sans-serif',
                    fontSize: '14px',
                    fontWeight: 700,
                    lineHeight: '18px',
                    letterSpacing: '-0.005em',
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '8px',
                        backgroundColor: 'inherit',
                        border: `2px solid ${Blue800}`,
                        '&:hover': {
                            backgroundColor: 'inherit',
                            borderColor: Blue800,
                        },
                        '&.Mui-focused': {
                            borderColor: Blue800,
                            boxShadow: '0 0 4px rgba(0, 0, 0, 0.2)',
                        },
                        '& input': {
                            padding: '10px 12px',
                        },
                    },
                },
                inputRoot: {
                    paddingRight: '10px',
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: 'inherit',
                },
                notchedOutline: {
                    border: 'none',
                },
            },
        },
    },
});
