export const SideBarWidth = 250;
export const SideBarWidthCollapsed = 60;
export const ReviewTimePeriod = 14; //14 days
export const MaxCharactersDescriptionProjectCard = 20;
export const MaxCharactersDescriptionProjectDetails = 65;

export const USER_ROLES = {
    ADMIN: "Admin",
    MANAGER: "Manager",
};

export const EDIT_PERMISSION = 'edit';
export const VIEW_PERMISSION = 'view';
export const CREATE_PERMISSION = 'create';
export const REVIEW_PERMISSION = 'review';

export const PERMISSIONS = {
    ADMIN: [EDIT_PERMISSION, CREATE_PERMISSION, VIEW_PERMISSION, REVIEW_PERMISSION],
    MANAGER: [REVIEW_PERMISSION, VIEW_PERMISSION],
};
