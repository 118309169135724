import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { Box } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import axiosFetch from '../../api/Axios';
import { fetchProjectsData } from '../../components/listProjectCards/getProjects';
import WrapperPage from '../../components/WrapperPage';
import { getItem, setItem, useLocalStorage } from '../../utils/LocalStorageUtils';
import ListActionItems from '../../components/listActionItems/ListActionItems';
import { emptyFilters } from './constants';
import SearchBar from '../../components/SearchBar';
import AutocompleteFilter from '../../components/actionItems/autocompleteFilter';

export default function ActionItems({ sidebarWidth }) {
    const [actionItems, setActionItems] = useState([]);
    const [filters, setFilters] = useState(emptyFilters);
    const [projects, setProjects] = useState([]);
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [paginationModel, setPaginationModel] = useLocalStorage('actionItemsPaginationV2', {
        page: 0,
        pageSize: 100,
    });
    const [sortModel, setSortModel] = useState();
    const [totalActionItems, setTotalActionItems] = useState(0);
    const [searchValue, setSearchValue] = useState('');

    const searchRef = useRef();

    useEffect(() => {
        fetchActionItems();
        fetchUsers();
        fetchProjects();
    }, [paginationModel, sortModel, filters]);

    const fetchActionItems = async () => {
        setIsLoading(true);
        let queryParams = { ...paginationModel };
        if (sortModel) {
            queryParams = { ...queryParams, ...sortModel };
        }
        Object.keys(filters).forEach((key) => {
            if (filters[key]) {
                queryParams[key] = filters[key];
            }
        });
        try {
            const response = await axiosFetch({
                method: 'GET',
                params: queryParams,
                url: process.env.REACT_APP_API_PORT + 'action_items',
            });
            if (response && response.actionItems) {
                console.log(response);
                setActionItems(response.actionItems);
                setTotalActionItems(response.total);
            }
        } catch (error) {
            console.error('Error fetching action items:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchUsers = async () => {
        try {
            const usersList = await axiosFetch({
                method: 'GET',
                url: process.env.REACT_APP_API_PORT + 'users?limit=all',
            });
            setUsers(usersList);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const fetchProjects = async () => {
        try {
            const projectsList = await fetchProjectsData();
            setProjects(projectsList);
        } catch (error) {
            console.error('Error fetching projects:', error);
        }
    };

    const handleSearchChange = (value) => {
        setSearchValue(value);
        if (searchRef.current) {
            clearTimeout(searchRef.current);
        }
        searchRef.current = setTimeout(() => {
            const updatedFilters = { ...filters, searchValue: value };
            setFilters(updatedFilters);
            setItem('actionItemsFilter', JSON.stringify(updatedFilters));
        }, 500);
    };

    const projectsOptions = projects.map((p) => ({ label: p.name, id: p.project_id }));
    const usersOptions = users.map((u) => ({ label: `${u.first_name} ${u.last_name}`, id: u.user_id }));

    return (
        <WrapperPage title="Action Items" icon={<EventAvailableIcon style={{ fontSize: 32 }} />} sidebarWidth={sidebarWidth}>
            <Box display="flex" flexDirection="row" gap={4} marginTop={4}>
                <SearchBar onSearchChange={handleSearchChange} placeholder="Search action items" />
                <AutocompleteFilter
                    options={projectsOptions}
                    value={projectsOptions.find((p) => p.id === filters.project) ?? null}
                    label="Project"
                    onChange={(event, option) => setFilters((prev) => ({ ...prev, project: option?.id || '' }))}
                />
                <AutocompleteFilter
                    options={usersOptions}
                    value={usersOptions.find((u) => u.id === filters.owner) ?? null}
                    label="Owner"
                    onChange={(event, option) => setFilters((prev) => ({ ...prev, owner: option?.id || '' }))}
                />
            </Box>

            <ListActionItems
                data={actionItems}
                setData={setActionItems}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
                isLoading={isLoading}
                totalItems={totalActionItems}
            />
        </WrapperPage>
    );
}
