import {
  getNeedsManagemtAttentionProjects,
  fetchProjectsData,
  getRiskLevel0,
  getRiskLevel1,
  getRiskLevel2,
} from "../../components/listProjectCards/getProjects";

export async function getProjectsAttentionCounts() {
  try {
    const needsAttention = await getNeedsManagemtAttentionProjects();
    const allProjects = await fetchProjectsData();
    return [
      {
        status: 'Yes',
        count : needsAttention.length,
      },
      {
        status: 'No',
        count:  allProjects.length - needsAttention.length,
      },
    ];
  } catch (error) {
    console.log("Error: ", error);
    throw error;
  }
}

export async function getProjectsRiskLevelsCounts() {
  try {
    const riskLevel0 = await getRiskLevel0();
    const riskLevel1 = await getRiskLevel1();
    const riskLevel2 = await getRiskLevel2();
    return [
      {
        status: 'RiskLevel0',
        count : riskLevel0.length,
      },
      {
        status: 'RiskLevel1',
        count:  riskLevel1.length,
      },
      {
        status: 'RiskLevel2',
        count: riskLevel2.length,
      },
    ];
  } catch (error) {
    console.log("Error: ", error);
    throw error;
  }
}