import { Blue400, Blue800, White100 } from "../../consts/colors";
import { Switch, styled } from "@mui/material";

const styles = {
  header: {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "18px",
    letterSpacing: "-0.005em",
    textAlign: "left",
    color: "Black100",
  },
  headline: {
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "29px",
    letterSpacing: "-0.015em",
    textAlign: "left",
    color: Blue400,
  },
  box: {
    width: "319px",
    marginTop: "15px",
    padding: "48px",
    borderRadius: "16px",
    gap: "24px",
    color: "white",
    backgroundColor: White100,
    position: "absolute",
  },
  menuItem: {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "18px",
    letterSpacing: "-0.005em",
    textAlign: "left",
    cursor: "pointer",
    marginTop: "20px",
  },
  switch: {
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: Blue400,
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: Blue400,
    },
  },
};
export default styles;
