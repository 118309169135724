import { Box } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import CustomTable from '../table/Table';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { ProjectDetailsStyles } from './styles';

export default function ListDetailsTable({ data, paginationModel, setPaginationModel, isLoading, totalItems }) {
    const theme = useTheme();
    const classes = ProjectDetailsStyles;
    const navigate = useNavigate();

    const headers = [
        { label: 'Question', key: 'question', width: '40%' },
        { label: 'Answer', key: 'answer', width: '40%' },
        { label: 'Notes', key: 'notes', width: '20%' },
    ];

    const rows = data.map((item, index) => ({
        question: item.question,
        answer: item.response,
        notes: item.quick_note ? item.quick_note : 'None',
        response_score: item.response_score,
    }));

    const getRowStyle = (row) => {
        if (row.response_score < 3) {
            return { border: '2px solid red', backgroundColor: 'rgba(255, 0, 0, 0.2)' };
        } else if (row.response_score >= 3 && row.response_score < 4) {
            return { border: '2px solid yellow', backgroundColor: 'rgba(255, 255, 0, 0.2)' };
        } else if (row.response_score >= 4) {
            return { border: '2px solid green', backgroundColor: 'rgba(0, 255, 0, 0.2)' };
        }
        return {};
    };

    return (
        <ThemeProvider theme={theme}>
            <Box className={classes.container} sx={{ height: '100%' }}>
                <CustomTable
                    paginationModel={paginationModel}
                    onPaginationModelChanged={setPaginationModel}
                    headers={headers}
                    rows={rows}
                    isLoading={isLoading}
                    totalItems={totalItems}
                    isMarginTopRequired={true}
                    getRowStyle={getRowStyle}
                />
            </Box>
        </ThemeProvider>
    );
}
