import React, { useState, useEffect, useRef } from "react";
import WrapperPage from "../../components/WrapperPage";
import GreenButton from "../../components/GreenButton";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import ListUsers from "../../components/listUsers/ListUsers";
import { fetchDataFromServer } from "../../components/listUsers/getUsers";
import FilterIcon from "../../assets/icons/filter.svg";
import FilterButton from "../../components/filter/FilterButton";
import { UsersIcon, Plus } from "../../assets/icons";
import LoadingComponent from "../../components/LoadingComponent";
import { Green600, PrimaryGreen } from "../../consts/colors";

function UsersPage({ sidebarWidth }) {
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [debouncedSearchValue, setDebouncedSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [filteredData, setFilteredData] = useState(data);
  const [sortType, setSortType] = useState("");

  const handleFilter = (value) => {
    setSortType(value);
  };

  useEffect(() => {
    fetchDataFromServer(setLoading)
      .then((response) => {
        setData(response);
        setOriginalData(response);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleSearchChange = (value) => {
    setSearchValue(value);
  };

  const timer = useRef(null);

  useEffect(() => {
    if (searchValue?.length >= 2) {
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        setDebouncedSearchValue(searchValue);
      }, 500);
    } else {
      clearTimeout(timer.current);
      setDebouncedSearchValue("");
      setSearchResults([]);
    }
  }, [searchValue]);

  useEffect(() => {
    const filtered = data.filter((item) => {
      if (!debouncedSearchValue) {
        return true;
      } else {
        const searchableFields = ["first_name", "last_name"];
        const containsSearchValue = searchableFields.some((field) => {
          const fieldValue = item[field] || "";
          return fieldValue
            .toLowerCase()
            .includes(debouncedSearchValue.toLowerCase());
        });
        return containsSearchValue;
      }
    });

    setFilteredData(filtered);
  }, [data, debouncedSearchValue]);

  const nav = useNavigate();

  return (
    <WrapperPage
      icon={<UsersIcon height={"30px"} width={"30px"} />}
      title="Manage Users"
      sidebarWidth={sidebarWidth}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "36px",
        }}
      >
        <div
          style={{ display: "flex", alignItems: "center", marginLeft: "16px" }}
        >
          <SearchBar onSearchChange={handleSearchChange} placeholder={'Search users...'}/>
          {/* <div style={{ marginLeft: "25px" }}>
            <FilterButton
              title="Filter Users"
              icon={FilterIcon}
              sortType={handleFilter}
              reviewOption={false}
            />
          </div> */}
        </div>
        <GreenButton
          iconSrc={<Plus width={"20px"} height={"20px"} />}
          buttonText="Create New User"
          width="212px"
          height="44px"
          fontSize={14}
          lineHeight={18}
          backgroundColor={Green600}
          backgroundColorHover={PrimaryGreen}
          onClick={() => {
            nav("/new-user");
          }}
        />
      </div>
      {loading ? (
        <LoadingComponent />
      ) : (
        <ListUsers
          data={filteredData}
          setData={setData}
          sortType={sortType}
          originalData={originalData}
        ></ListUsers>
      )}
    </WrapperPage>
  );
}
export default UsersPage;
