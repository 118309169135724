import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import {RedAlert, White400, Black400} from "../../consts/colors";

export default function DeleteActionItemDialog({ actionItemId, deleteActionItem, close }) {
    const handleDelete = () => {
        deleteActionItem(actionItemId);
        close();
    }

    return <Dialog onClose={close} open={true}>
        <DialogTitle>Delete Action Item</DialogTitle>
        <DialogContent>
            Are you sure you want to delete this action item?
        </DialogContent>
        <DialogActions>
            <Button autoFocus onClick={close} sx={{ color: Black400 }}>
                Cancel
            </Button>
            <Button onClick={handleDelete}
                    sx={{
                        backgroundColor: RedAlert,
                        '&:hover': {
                            backgroundColor: RedAlert
                        },
                        color: White400
                    }} variant="contained">Delete</Button>
        </DialogActions>
    </Dialog>
}