import { Blue400, Blue800, Blue100, White100 } from "../../consts/colors"

const AddQuestionsStyles ={
    textTitle:{
        fontFamily: 'Poppins, sans-serif',
            fontWeight: 600,
            letterSpacing: '-0.005em',
            textAlign: 'left',
            textTransform: 'none',
            color:Blue800,
            marginTop:'40px',
            fontSize: '36x',
            lineHeight: '43px',
    },
    text:{
        top: '0px',
        fontFamily: 'Poppins, sans-serif',
        fontWeight: 600,
        letterSpacing: '-1.5%',
        textAlign: 'left',
        fontSize: '24x',
        lineHeight: '28.8px',
        textTransform: 'none',
        color:Blue400,
        marginLeft: '10px',
    },
    addButton: {
        marginLeft: '20px',
        display: 'flex',
    },
    addButtonText: {
        fontFamily: 'Poppins, sans-serif',
        fontWeight: 700,
        letterSpacing: '-0.5%',
        textAlign: 'left',
        fontSize: '14px',
        lineHeight: '18.2px',
        textTransform: 'none',
        color:Blue100,
        marginLeft:"5px",
    },
    responseBox: {
        display: 'flex',
        width: '99%',
    },
    field: {
        backgroundColor: White100,
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
              borderColor: Blue400,
            },
            '&.Mui-focused fieldset': {
              borderColor: Blue400, 
            },
          },
          '& label.Mui-focused': {
            color: Blue400,
          },
          '&.hover': {
            color: Blue400,
          }
        },
}

export default AddQuestionsStyles;