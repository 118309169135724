import styles from "./styles";
import { Typography, FormControl, Select, MenuItem } from "@mui/material";
import { FormsStyles } from "../../utils/FormsStyles";

export const NeedsAttentionBox = ({
  title,
  need_attention,
  onNeedManagementChange,
}) => {
  const need_mg_attention = [true, false];
  return (
    <div style={{ ...styles.customBox }}>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
      >
        <Typography style={{ ...styles.tag }}>{title}</Typography>
      </div>
      <FormControl
        fullWidth
        sx={{
          mb: 2,
          marginTop: "5px",
          ...FormsStyles.field
        }}
        required
      >
        <Select
          labelId={`response-select-label-${need_attention}`}
          id={`response-select-${need_attention}`}
          value={need_attention}
          onChange={onNeedManagementChange}
        >
          {need_mg_attention.map((code, index) => (
            <MenuItem key={index} value={code}>
              {code ? "Yes" : "No"}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
