import {
  Box, Checkbox,
  FormControlLabel,
  IconButton, Typography
} from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosFetch from "../../api/Axios";
import { RadioChecked, RadioUnchecked } from "../../assets/icons";
import EditIcon from "../../assets/icons/editIcon.svg";
import { useLocalStorage } from "../../utils/LocalStorageUtils";
import SnackBar from "../SnackBar";
import CustomTable from "../table/Table";
import { ListQuestionStyles } from "./ListQuestionsStyles";
const renderEnableColumn = (item, handleDeactivationChange) => (
  <FormControlLabel
    control={
      <Checkbox
        icon={<RadioUnchecked />}
        checkedIcon={<RadioChecked />}
        sx={{
          "&.Mui-checked": {
            color: "green",
            form: "circle",
          },
        }}
        checked={item.is_active}
        onChange={(event) =>
          handleDeactivationChange(
            item.question_id,
            event.target.checked,
            item.value_question,
            item.responses,
            item.category_id,
            item.sub_category_id,
          )
        }
      />
    }
    label={
      <Typography sx={ListQuestionStyles.active}>
        {item.is_active ? "Enabled" : "Disabled"}
      </Typography>
    }
  />
);
const renderActionsColumn = (question) => (
  <Link
    to={`/edit-question/${question.question_id}`}
    style={{ textDecoration: "none", color: "inherit", cursor: "pointer" }}
  >
    <IconButton>
      <img src={EditIcon} alt="Edit" height={16} width={16} />
      <span style={{ ...ListQuestionStyles.buttonEdit, marginLeft: "8px" }}>
        Edit{" "}
      </span>
    </IconButton>
  </Link>
);
export default function ListQuestions({
  data,
  setData,
  sortType,
  originalData,
}) {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const nav = useNavigate();

  const [paginationModel, setPaginationModel] = useLocalStorage(
    "questionsPagination",
    {
      page: 0,
      pageSize: 100,
    },
  );

  const handleDeactivationChange = async (
    questionId,
    newValue,
    questionValue,
    categoryId,
    responsesQuestions,
    sub_categoryId,
  ) => {
    try {
      await axiosFetch({
        method: "PUT",
        url: process.env.REACT_APP_API_PORT + `question/${questionId}`,
        data: {
          is_active: newValue,
          value_question: questionValue,
          category_id: categoryId,
          sub_category_id: sub_categoryId,
          responses: responsesQuestions,
        },
      });
      setSuccess(true);
      setData((prevData) =>
        prevData.map((item) =>
          item.question_id === questionId
            ? { ...item, is_active: newValue }
            : item,
        ),
      );
    } catch (error) {
      console.error("Error updating question deactivation status:", error);
      setError({
        message: error.message,
        severity: "error",
      });
    }
  };
  const headers = [
    { label: "Question ID", key: "id", width: "15%" },
    { label: "Question text", key: "text", width: "50%" },
    { label: "Status", key: "enable", width: "20%" },
    { label: "Actions", key: "action", width: "15%" },
  ];

  const rows = data.map((question) => ({
    id: question.question_id,
    text: question.value_question,
    enable: renderEnableColumn(question, handleDeactivationChange),
    action: renderActionsColumn(question),
  }));
  const handleSuccessSnackbarClose = () => {
    setSuccess(false);
  };

  return (
    <Box width="100%" marginTop="30px">
      <CustomTable
        paginationModel={paginationModel}
        onPaginationModelChanged={setPaginationModel}
        headers={headers}
        rows={rows}
        isMarginTopRequired={true}
        goToReviewPageNeeded={false}
      />
      {success && (
        <SnackBar
          open={true}
          message="Question's status changed successfully!"
          severity="success"
          handleClose={handleSuccessSnackbarClose}
        />
      )}
      {error?.message && (
        <SnackBar
          open={true}
          message={error.message}
          severity={error.severity}
          handleClose={() => setError(null)}
        />
      )}
    </Box>
  );
}
