import { MenuItem, Stack, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axiosFetch from '../../api/Axios';
import Save from '../../assets/icons/save.svg';
import CustomDatePicker from '../../components/CustomDatePicker';
import GreenButtonED from '../../components/GreenButtonED';
import SnackBar from '../../components/SnackBar';
import WrapperPage from '../../components/WrapperPage';
import { FormsStyles } from '../../utils/FormsStyles';
import { NewProjectStyles } from '../NewProject/styles';

const ViewEditPage = ({ sidebarWidth }) => {
    const { itemId } = useParams();
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [projectTitle, setProjectTitle] = useState('');
    const [projectDescription, setProjectDescription] = useState('');
    const [clientName, setClientName] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [reviewOwner, setReviewOwner] = useState('');
    const [clientPartner, setClientPartner] = useState('');
    const [doE, setDoE] = useState('');
    const [category, setCategory] = useState('1');
    const [projectData, setProjectData] = useState(null);
    const [error, setError] = useState(null);
    const [initialProjectName, setInitialProjectName] = useState([]);
    const [success, setSuccess] = useState(false);
    const [options, setOptions] = useState([]);
    const [clientPOptions, setclientPOptions] = useState([]);
    const [doEOptions, setdoeOptions] = useState([]);
    const [slackChannel, setSlackChannel] = useState('');

    useEffect(() => {
        fetchProjectsData(itemId);
    }, [itemId]);

    const fetchProjectsData = async (itemId) => {
        try {
            const response = await axiosFetch({
                method: 'GET',
                url: process.env.REACT_APP_API_PORT + 'project/' + itemId,
            });
            if (response && Object.keys(response).length > 0) {
                setProjectData(response);
                setProjectTitle(response.name || '');
                setProjectDescription(response.business_description || '');
                setClientName(response.client_name || '');
                setStartDate(dayjs(response.start_date) || null);
                setEndDate(dayjs(response.end_date) || null);
                setReviewOwner(response.user_id || '');
                setClientPartner(response.cp_id || '');
                setDoE(response.doe_id || null);
                setInitialProjectName(response.name || '');
                setSlackChannel(response.slack_channel || '');
            } else {
                console.log('No project data found.');
            }
        } catch (error) {
            console.log('Error: ', error);
        }
    };

    useEffect(() => {
        async function fetchManagerData() {
            try {
                const response = await axiosFetch({
                    method: 'GET',
                    url: process.env.REACT_APP_API_PORT + 'users?user_role=Admin&&user_role=Manager&&limit=all',
                });
                setOptions(response);
                const response1 = await axiosFetch({
                    method: 'GET',
                    url: process.env.REACT_APP_API_PORT + 'users/cp',
                });
                setclientPOptions(response1);
                const response2 = await axiosFetch({
                    method: 'GET',
                    url: process.env.REACT_APP_API_PORT + 'users/doe',
                });
                setdoeOptions(response2);
            } catch (error) {
                setError({
                    message: error.message,
                    severity: 'error',
                });
            }
        }
        fetchManagerData();
    }, []);

    const handleSaveClick = async () => {
        const dataToSend = {
            name: projectTitle,
            user_id: reviewOwner,
            cp_id: clientPartner,
            doe_id: doE,
            business_description: projectDescription,
            client_name: clientName,
            start_date: startDate.format('YYYY-MM-DD'),
            end_date: endDate === 'Invalid date' ? endDate.format('YYYY-MM-DD') : null,
            slack_channel: slackChannel,
            category: category,
        };
        const options = {
            url: process.env.REACT_APP_API_PORT + 'project/' + itemId,
            method: 'put',
            data: dataToSend,
        };

        try {
            const responseData = await axiosFetch(options);
            if (responseData) {
                setSuccess(true);
                fetchProjectsData();
            } else {
                setSuccess(false);
            }
        } catch (error) {
            setError({
                message: error.message,
                severity: 'error',
            });
        }

        setIsFormDirty(false);
    };

    const handleProjectTitleChange = (event) => {
        setProjectTitle(event.target.value);
        setIsFormDirty(true);
    };

    const handleSlackChannelChnage = (event) => {
        setSlackChannel(event.target.value);
        setIsFormDirty(true);
    };

    const handleProjectDescriptionChange = (event) => {
        setProjectDescription(event.target.value);
        setIsFormDirty(true);
    };

    const handleClientNameChange = (event) => {
        setClientName(event.target.value);
        setIsFormDirty(true);
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
        setIsFormDirty(true);
        setError(null);
    };

    const handleEndDateChange = (date) => {
        setIsFormDirty(true);
        setError(null);
        if (date) {
            if (date > startDate) {
                setEndDate(date);
                setError(null);
            } else {
                setError({
                    message: 'End date should be after the start date!',
                    severity: 'error',
                });
            }
        } else {
            setError(null);
        }
    };

    const handleReviewOwnerChange = (event) => {
        setReviewOwner(event.target.value);
        setIsFormDirty(true);
    };
    const handleCPChange = (event) => {
        const selectedUserId = parseInt(event.target.value, 10);
        setClientPartner(selectedUserId);
        setIsFormDirty(true);
    };
    const handleDoEChange = (event) => {
        const selectedUserId = parseInt(event.target.value, 10);
        setDoE(selectedUserId);
        setIsFormDirty(true);
    };

    // const handleCategoryChange = (event) => {
    //   setCategory(event.target.value);
    //   setIsFormDirty(true);
    // };

    const handleSuccessSnackbarClose = () => {
        setSuccess(false);
    };

    return (
        <WrapperPage sidebarWidth={sidebarWidth}>
            <React.Fragment>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginTop: '36px',
                    }}
                >
                    <h1 style={{ ...NewProjectStyles.textTitle, margin: 0 }}>Edit {initialProjectName} Details</h1>
                    <GreenButtonED
                        iconSrc={Save}
                        buttonText="Save Changes"
                        width="206px"
                        height="44px"
                        fontSize={14}
                        lineHeight={18}
                        type="button"
                        onClick={handleSaveClick}
                        disabled={!isFormDirty}
                        style={{ flex: '0 0 auto' }}
                    />
                </div>

                {projectData && (
                    <form>
                        <div style={{ marginBottom: '20px', marginTop: '20px' }}>
                            <span style={{ ...NewProjectStyles.numbers }}>1</span>
                            <span style={{ ...NewProjectStyles.text }}>Project Title</span>
                        </div>
                        <TextField
                            type="text"
                            variant="outlined"
                            color="secondary"
                            label="Type project title"
                            onChange={handleProjectTitleChange}
                            value={projectTitle}
                            fullWidth
                            required
                            sx={{ mb: 4, marginLeft: '20px', ...FormsStyles.field }}
                        />

                        <div style={{ marginBottom: '20px' }}>
                            <span style={{ ...NewProjectStyles.numbers }}>2</span>
                            <span style={{ ...NewProjectStyles.text }}>Project Description</span>
                        </div>
                        <TextField
                            type="text"
                            variant="outlined"
                            color="secondary"
                            label="Type project description"
                            onChange={handleProjectDescriptionChange}
                            value={projectDescription}
                            fullWidth
                            required
                            sx={{ mb: 4, marginLeft: '20px', ...FormsStyles.field }}
                        />

                        <div style={{ marginBottom: '20px' }}>
                            <span style={{ ...NewProjectStyles.numbers }}>3</span>
                            <span style={{ ...NewProjectStyles.text }}>Client Name</span>
                        </div>
                        <TextField
                            type="text"
                            variant="outlined"
                            color="secondary"
                            label="Type client name"
                            onChange={handleClientNameChange}
                            value={clientName}
                            fullWidth
                            required
                            sx={{ mb: 4, marginLeft: '20px', ...FormsStyles.field }}
                        />

                        <div style={{ marginBottom: '20px' }}>
                            <span style={{ ...NewProjectStyles.numbers }}>4</span>
                            <span style={{ ...NewProjectStyles.text }}>Start-End Date</span>
                        </div>
                        <Stack spacing={2} direction="row" sx={{ marginBottom: 4, marginLeft: '20px' }}>
                            <CustomDatePicker label="Start Date" value={startDate} onChange={handleStartDateChange} />

                            <CustomDatePicker label="End Date" value={endDate} onChange={handleEndDateChange} />
                        </Stack>

                        <div style={{ marginBottom: '20px' }}>
                            <span style={{ ...NewProjectStyles.numbers }}>5</span>
                            <span style={{ ...NewProjectStyles.text }}>Manager</span>
                        </div>
                        <FormControl
                            sx={{
                                width: '100%',
                                mb: 4,
                                marginLeft: '20px',
                                ...FormsStyles.field,
                            }}
                        >
                            <InputLabel id="manager-label">Pick Manager</InputLabel>
                            <Select
                                labelId="manager-label"
                                id="manager-select"
                                value={reviewOwner}
                                onChange={handleReviewOwnerChange}
                                label="Pick Manager"
                            >
                                {options.map((option) => (
                                    <MenuItem key={option.user_id} value={option.user_id}>
                                        {option.first_name} {option.last_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <div style={{ marginBottom: '20px' }}>
                            <span style={{ ...NewProjectStyles.numbers }}>6</span>
                            <span style={{ ...NewProjectStyles.text }}>Client Partner</span>
                        </div>
                        <FormControl fullWidth sx={{ mb: 4, marginLeft: '20px', ...FormsStyles.field }}>
                            <InputLabel id="client_partner-label">Pick Client Partner</InputLabel>
                            <Select
                                labelId="client-owne-label"
                                id="client-partner-select"
                                value={clientPartner}
                                onChange={handleCPChange}
                                label="Pick Clinet Partner"
                            >
                                {clientPOptions.map((clientPOptions) => (
                                    <MenuItem key={clientPOptions.user_id} value={clientPOptions.user_id}>
                                        {clientPOptions.first_name} {clientPOptions.last_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <div style={{ marginBottom: '20px' }}>
                            <span style={{ ...NewProjectStyles.numbers }}>7</span>
                            <span style={{ ...NewProjectStyles.text }}>Director of Engineering</span>
                        </div>
                        <FormControl fullWidth sx={{ mb: 4, marginLeft: '20px', ...FormsStyles.field }}>
                            <InputLabel id="doe-label">Pick Director of Engineering</InputLabel>
                            <Select
                                labelId="doe-label"
                                id="doe-select"
                                value={doE}
                                onChange={handleDoEChange}
                                label="Pick Director of Engineering"
                            >
                                {doEOptions.map((doEOptions) => (
                                    <MenuItem key={doEOptions.user_id} value={doEOptions.user_id}>
                                        {doEOptions.first_name} {doEOptions.last_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <div style={{ marginBottom: '20px', marginTop: '20px' }}>
                            <span style={{ ...NewProjectStyles.numbers }}>8</span>
                            <span style={{ ...NewProjectStyles.text }}>Slack Channel</span>
                        </div>
                        <TextField
                            type="text"
                            variant="outlined"
                            color="secondary"
                            label="Type slack channel"
                            onChange={handleSlackChannelChnage}
                            value={slackChannel}
                            fullWidth
                            required
                            sx={{ mb: 4, marginLeft: '20px', ...FormsStyles.field }}
                        />
                    </form>
                )}

                {error?.message && (
                    <SnackBar open={true} message={error.message} severity={error.severity} handleClose={() => setError(null)} />
                )}

                {success && (
                    <SnackBar
                        open={true}
                        message="Form submitted successfully!"
                        severity="success"
                        handleClose={handleSuccessSnackbarClose}
                    />
                )}
            </React.Fragment>
        </WrapperPage>
    );
};

export default ViewEditPage;
