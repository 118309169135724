import styles from "./styles";
import { Typography, FormControl, Select, MenuItem } from "@mui/material";
import { Green300, Yellow400, RedAlert } from "../../consts/colors";
import { FormsStyles } from "../../utils/FormsStyles";

export const getRiskColorStyle = (colorCode) => {
    switch (colorCode) {
      case 0:
        return { bulletColor: Green300, label: "Green" };
      case 1:
        return { bulletColor: Yellow400, label: "Yellow" };
      case 2:
        return { bulletColor: RedAlert, label: "Red" };
      default:
        return { bulletColor: Green300, label: "Green" };
    }
  };

  export const RiskLevelBox = ({ title, colorCode, onRiskLevelChange }) => {
    const riskLevel = [0, 1, 2];
    return(
        <div style={{ ...styles.customBox }}>
        <div
          style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
        >
          <Typography style={{ ...styles.tag }}>{title}</Typography>
        </div>
        <FormControl
          fullWidth
          sx={{
            mb: 2,
            marginTop: "5px",
            ...FormsStyles.field
          }}
        >
          <Select
            labelId={`response-select-label-${colorCode}`}
            id={`response-select-${colorCode}`}
            value={colorCode}
            onChange={onRiskLevelChange}
          >
            {riskLevel.map((code, index) => (
              <MenuItem key={index} value={code}>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      width: "18px",
                      height: "18px",
                      borderRadius: "50%",
                      backgroundColor: getRiskColorStyle(code).bulletColor,
                      marginRight: "5px",
                      marginTop: "3px",
                    }}
                  ></div>
                  {getRiskColorStyle(code).label}
                </div>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  }