import React from 'react';
import { Box } from '@mui/system';
import { Black800 } from '../../../../consts/colors';
import Logo from '../../../../assets/images/VSP_Logo.svg';
import GreenButton from '../../../../components/GreenButton';
import { Green600, PrimaryGreen } from '../../../../consts/colors';
import { GoogleIcon } from '../../../../assets/icons';

const WhiteBox = () => {
    const handleSignInClick = () => {
        window.location.href = process.env.REACT_APP_PORT + '/auth/google';
    };

    return (
        <Box
            sx={{
                width: '337px',
                height: '285px',
                padding: '5%',
                borderRadius: '16px',
                gap: '2%',
                backgroundColor: '#F9F9F9',
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <img
                src={Logo}
                alt="Logo"
                style={{
                    position: 'absolute',
                    top: '15%',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: `198px`,
                    height: `48px`,
                    borderRadius: '16px 16px 0 0',
                }}
            />
            <h1
                style={{
                    color: Black800,
                    fontFamily: 'Poppins, sans-serif',
                    fontSize: '28px',
                    fontWeight: 600,
                    lineHeight: '34px',
                    letterSpacing: '-0.02em',
                    textAlign: 'center',
                    marginTop: '100px',
                    marginBottom: 'auto',
                }}
            >
                Welcome back
            </h1>
            <h1
                style={{
                    color: Black800,
                    fontFamily: 'Poppins, sans-serif',
                    fontSize: '18px',
                    fontWeight: 400,
                    lineHeight: '23px',
                    letterSpacing: '-0.01em',
                    textAlign: 'center',
                    marginTop: '20px',
                }}
            >
                Log in using your work email
            </h1>

            <GreenButton
                iconSrc={<GoogleIcon width={'20px'} height={'20px'} />}
                buttonText="Log in with Google"
                width="300px"
                height="50px"
                fontSize={16}
                lineHeight={24}
                marginTop="50px"
                backgroundColor={Green600}
                backgroundColorHover={PrimaryGreen}
                onClick={() => {
                    handleSignInClick();
                    localStorage.setItem('loggedIn', true);
                }}
            />
        </Box>
    );
};

export default WhiteBox;
