import React from 'react';
import { Box, InputBase } from '@mui/material';
import { Blue800 } from '../consts/colors';
import SearchIcon from '../assets/icons/search.svg'

const SearchBar = ({ onSearchChange, placeholder }) => {
  const handleSearchInputChange = (event) => {
    const lowerCase = event.target.value.toLowerCase();
    onSearchChange(lowerCase); 
  };

  const inputStyles = {
    fontFamily: 'Poppins, sans-serif',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '-0.005em',
    textAlign: 'left',
    color: Blue800,
    '&::placeholder': {
      color: Blue800,
      opacity: 0.5,
    },
    outline: 'none',
    border: 'none',
    flex: 1,
  };

  return (
    <Box>
      <Box
        sx={{
          width: '270px',
          height: '44px',
          border: `2px solid ${Blue800}`,
          borderRadius: '8px',
          display: 'flex',
          alignItems: 'center',
          padding: '8px',
          cursor: 'text',
        }}
      >
        <img
          src={SearchIcon}
          alt="Search Icon"
          style={{
            width: '20px',
            height: '20px',
            marginRight: '8px',
          }}
        />
        <InputBase
          onChange={handleSearchInputChange}
          placeholder={placeholder}
          sx={inputStyles}
        />
      </Box>
    </Box>
  );
};

export default SearchBar;
