import { containerStyles } from './styles';
import * as React from 'react';

import WhiteBox from './utils/White_box/White_box';
const Login = () => {
    return (
        <div style={containerStyles}>
          <WhiteBox/>
        </div>
);
  };
export default Login;
