import { createBrowserHistory } from "history";
import { useEffect } from "react";

export const history = createBrowserHistory();

export function useNavBlocker(dirtyRef) {
  const currentLocation = history.location.pathname;
  useEffect(function () {
    let _tx;
    const unblock = history.block(function (tx) {
      _tx = tx;
      if (currentLocation !== history.location.pathname) {
        unblock();
        tx.retry();
        _tx = null;
        return;
      }
      if (!dirtyRef.current) {
        unblock();
        tx.retry();
        _tx = null;
        return;
      }

      if (
        window.confirm(
          "There is unsaved data in this form. Are you sure you want to leave?",
        )
      ) {
        unblock();
        tx.retry();
        _tx = null;
      }
    });

    return function () {
      if (_tx != null) {
        unblock();
        _tx.retry();
      }
    };
  }, []);
}
