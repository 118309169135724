import { Box, Modal, Typography } from '@mui/material';
import * as React from 'react';
import { Blue800, RedAlert, White100, White400 } from '../../consts/colors';
import GreenButton from '../GreenButton';
import { PromptUserStyles } from './PrompUserStyles';

const PromptUser = ({ title, actionName, icon, action, actionId, setPromptVisible }) => {
    const handleClose = () => setPromptVisible(false);

    const handleAction = () => {
        handleClose();
        action(actionId);
    };

    const renderContent = () => {
        switch (actionName) {
            case 'Archive project':
                return (
                    <>
                        <Typography id="modal-modal-description" sx={PromptUserStyles.description}>
                            Are you sure you want to archive the project?
                            <br />
                            <br />
                            If you're certain about archiving this project, click 'Archive project' button below. Otherwise, click 'Cancel'
                            to return.
                        </Typography>
                        <Box sx={{ display: 'flex' }}>
                            <GreenButton
                                width={'257px'}
                                height={'48px'}
                                buttonText="Cancel"
                                borderColor={Blue800}
                                onClick={handleClose}
                                backgroundColor={White100}
                                backgroundColorHover={White400}
                                fontSize={14}
                                lineHeight={18.2}
                                textColor={Blue800}
                            />
                            <GreenButton
                                width={'257px'}
                                height={'48px'}
                                buttonText={actionName}
                                onClick={handleAction}
                                backgroundColor={RedAlert}
                                fontSize={14}
                                lineHeight={18.2}
                                textColor={White100}
                                iconSrc={icon}
                                backgroundColorHover={RedAlert}
                                marginLeft={'60px'}
                            />
                        </Box>
                    </>
                );
            case 'Unarchive project':
                return (
                    <>
                        <Typography id="modal-modal-description" sx={PromptUserStyles.description}>
                            Are you sure you want to unarchive the project?
                            <br />
                            <br />
                            This project will be retured to the active projects list. If you're certain about unarchiving this project,
                            click 'Unarchive project' button below. Otherwise, click 'Cancel'
                        </Typography>
                        <Box sx={{ display: 'flex' }}>
                            <GreenButton
                                width={'257px'}
                                height={'48px'}
                                buttonText="Cancel"
                                borderColor={Blue800}
                                onClick={handleClose}
                                backgroundColor={White100}
                                backgroundColorHover={White400}
                                fontSize={14}
                                lineHeight={18.2}
                                textColor={Blue800}
                            />
                            <GreenButton
                                width={'257px'}
                                height={'48px'}
                                buttonText={actionName}
                                onClick={handleAction}
                                backgroundColor={RedAlert}
                                fontSize={14}
                                lineHeight={18.2}
                                textColor={White100}
                                iconSrc={icon}
                                backgroundColorHover={RedAlert}
                                marginLeft={'60px'}
                            />
                        </Box>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <Modal open={true} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={PromptUserStyles.boxModal}>
                <Typography id="modal-modal-title" variant="h6" component="h2" sx={PromptUserStyles.title}>
                    {title}
                </Typography>
                {renderContent()}
            </Box>
        </Modal>
    );
};

export default PromptUser;
